import React from "react";
import { Route, Routes } from "react-router-dom";

import flashcardsIcon from "./assets/flashcards.svg";
import sentencesIcon from "./assets/sentences.svg";
import translationsIcon from "./assets/translations.svg";

import { Menu } from "./components/Menu";

import Flashcards from "./pages/Flashcards";
import Sentences from "./pages/SentencesTranslation";

import { PRACTICE_GROUP_LINKS, PRACTICE_GROUP_ROUTES } from "./config";
import WordsTranslation from "./pages/WordsTranslation";
import SentencesTranslation from "./pages/SentencesTranslation";

export default function Practice() {
  const PRACTICE_PAGE_MENU = [
    {
      title: "Flash Cards",
      link: PRACTICE_GROUP_LINKS.LINK_TO_FLASHCARDS,
      icon: flashcardsIcon,
    },
    {
      title: "Word Translations",
      link: PRACTICE_GROUP_LINKS.LINK_TO_WORDS_TRANSLATION,
      icon: translationsIcon,
    },
    {
      title: "Sentences",
      link: PRACTICE_GROUP_LINKS.LINK_TO_SENTENCES_TRANSLATION,
      icon: sentencesIcon,
    },
  ];
  return (
    <Routes>
      <Route index element={<Menu schema={PRACTICE_PAGE_MENU} />} />
      <Route
        path={PRACTICE_GROUP_ROUTES.LINK_TO_FLASHCARDS}
        element={<Flashcards />}
      />
      <Route
        path={PRACTICE_GROUP_ROUTES.LINK_TO_WORDS_TRANSLATION}
        element={<WordsTranslation />}
      />
      <Route
        path={PRACTICE_GROUP_ROUTES.LINK_TO_SENTENCES_TRANSLATION}
        element={<SentencesTranslation />}
      />
    </Routes>
  );
}
