import React from "react";
import Logo from "./Logo";

export default function Header() {
  return (
    <div className="w-full  bg-white shadow-header flex-[0_1_70px] justify-center	relative flex items-center lg:justify-start">
      <div className="lg:ml-[140px]">
        <Logo />
      </div>
    </div>
  );
}
