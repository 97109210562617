const LINK_TO_PROFILE_INFO_ROOT = `/app/profile/info`;
const LINK_TO_PROFILE_INFO_CHANGE_PASSWORD = `${LINK_TO_PROFILE_INFO_ROOT}/change-password`;
const LINK_TO_PROFILE_INFO_CHANGE_EMAIL = `${LINK_TO_PROFILE_INFO_ROOT}/change-email`;

export const PROFILE_INFO_LINKS = {
  BASE: LINK_TO_PROFILE_INFO_ROOT,
  LINK_TO_PROFILE_INFO_CHANGE_PASSWORD,
  LINK_TO_PROFILE_INFO_CHANGE_EMAIL,
};

export const PROFILE_INFO_ROUTES = {
  BASE: "/*",
  LINK_TO_PROFILE_INFO_CHANGE_PASSWORD: "/change-password",
  LINK_TO_PROFILE_INFO_CHANGE_EMAIL: "/change-email",
};
