import STATUS_CODE from "./statusCodes";
import {
  EDIT_EMAIL_USER_EXIST_ERROR_MESSAGE,
  EDIT_PROFILE_WRONG_PASSWORD_ERROR_MESSAGE,
  FORGOT_PASSWORD_EMAIL_NOT_FOUND_ERROR_MESSAGE,
  FORGOT_PASSWORD_LIMIT_ERROR_MESSAGE,
} from "./messages";

export const FORGOT_PASSWORD_MESSAGES = {
  [STATUS_CODE.LIMIT]: FORGOT_PASSWORD_LIMIT_ERROR_MESSAGE,
  [STATUS_CODE.NOT_FOUND]: FORGOT_PASSWORD_EMAIL_NOT_FOUND_ERROR_MESSAGE,
};

export const EDIT_PROFILE_MESSAGES = {
  EMAIL: {
    [STATUS_CODE.ALREADY_EXIST]: EDIT_EMAIL_USER_EXIST_ERROR_MESSAGE,
  },
  PASSWORD: {
    [STATUS_CODE.UNVERIFIED]: EDIT_PROFILE_WRONG_PASSWORD_ERROR_MESSAGE,
  },
};
