import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import AuthCard from "../../components/AuthCard";
import PasswordCard from "../../components/PasswordCard";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";

import AuthService from "../../../../services/AuthService";

import catImg from "../../assets/register/cat.svg";
import catImgMob from "../../assets/register/cat-mob.svg";

import { AUTH_GROUP_LINKS } from "../../config";

const Register = () => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  const [, { registerPromise }] = useLoading();

  const navigate = useNavigate();
  const {
    params: { code },
  } = useLocationQuery();

  const registerUser = useCallback(
    (values) => {
      const { password } = values;

      registerPromise(
        authService
          .resetPassword({ password, token: code })
          .then(() => navigate(AUTH_GROUP_LINKS.LINK_LOGIN))
      );
    },
    [registerPromise, authService, navigate]
  );

  return (
    <AuthCard
      title="Set password"
      borderImg={catImg}
      borderImgMob={catImgMob}
      borderImgClassName="h-[130px] lg:-translate-x-1/2  lg:absolute lg:h-auto lg:-top-[90px] lg:left-1/2 "
    >
      <PasswordCard isRegisterPage onSubmit={registerUser} />
    </AuthCard>
  );
};

export default Register;
