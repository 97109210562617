import React from "react";
import PropTypes from "prop-types";

const NoLessonsPlaceholder = ({text = "No Bookmarked Lessons"}) => {

  return (
    <div className="h-full w-full flex items-center justify-center">
      <p className="text-lg text-dark-gray font-light">
        {text}
      </p>
    </div>
  )
}

NoLessonsPlaceholder.propTypes = {
  text: PropTypes.string,
}

export default NoLessonsPlaceholder;