export const STRINGS = {
  SOCIAL_lOGIN: {
    loginByGoogleBtn: "Log in with Google",
    loginByFacebookBtn: "Log in with Facebook",
  },
  SOCIAL_REGISTER: {
    registerByGoogleBtn: "Register with Google",
    registerByFacebookBtn: "Register with Facebook",
  },
};
