export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 50;
export const MAX_COUNT_TO_RESENT = 5;

export const MIN_NAME_LENGTH = 1;
export const MAX_NAME_LENGTH = 20;

export const MAX_EMAIL_LENGTH = 129;

export const MIN_CARDHOLDER_LENGTH = 1;
export const MAX_CARDHOLDER_LENGTH = 50;

export const MIN_CANCEL_AUTO_RENEWAL_OTHER_LENGTH = 1;
export const MAX_CANCEL_AUTO_RENEWAL_OTHER_LENGTH = 200;