import * as React from "react";
import Header from "./components/Header";

const PageLayout = ({ children }) => {
  return (
    <div className="h-full flex flex-col ">
      <Header />
      {children}
    </div>
  );
};

export default PageLayout;
