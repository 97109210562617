const LINK_TO_GRAMMAR_ROOT = `/app/grammar`;
const LINK_TO_ARTICLE_ENDINGS_PAGE = `${LINK_TO_GRAMMAR_ROOT}/article-endings`;
const LINK_TO_PRONOUNS_PAGE = `${LINK_TO_GRAMMAR_ROOT}/pronouns`;
const LINK_TO_ADJECTIVE_ENDINGS_PAGE = `${LINK_TO_GRAMMAR_ROOT}/adjective-endings`;
const LINK_TO_VERB_CONJUGATIONS_PAGE = `${LINK_TO_GRAMMAR_ROOT}/verb-conjugation`;
const LINK_TO_PREPOSITIONS_PAGE = `${LINK_TO_GRAMMAR_ROOT}/prepositions`;

export const GRAMMAR_GROUP_LINKS = {
  BASE: LINK_TO_GRAMMAR_ROOT,
  LINK_TO_ARTICLE_ENDINGS_PAGE,
  LINK_TO_PRONOUNS_PAGE,
  LINK_TO_ADJECTIVE_ENDINGS_PAGE,
  LINK_TO_VERB_CONJUGATIONS_PAGE,
  LINK_TO_PREPOSITIONS_PAGE,
};

export const GRAMMAR_GROUP_ROUTES = {
  BASE: `/grammar/*`,
  GRAMMAR: "/",
  LINK_TO_ARTICLE_ENDINGS_PAGE: "/article-endings",
  LINK_TO_PRONOUNS_PAGE: "/pronouns",
  LINK_TO_ADJECTIVE_ENDINGS_PAGE: "/adjective-endings",
  LINK_TO_VERB_CONJUGATIONS_PAGE: "/verb-conjugation",
  LINK_TO_PREPOSITIONS_PAGE: "/prepositions",
};