import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { twMerge } from "tailwind-merge";

export default function AuthCard({
  children,
  title,
  borderImg,
  borderImgMob,
  borderImgClassName,
  className,
  isCenterMob,
}) {
  return (
    <div
      className={twMerge(
        `w-full flex flex-col overflow-hidden  pt-8 px-5 relative lg:overflow-visible lg:h-auto  lg:w-auto lg:px-20 lg:py-8 lg:bg-white lg:rounded-2xl  lg:shadow-card lg:mt-32 ${
          isCenterMob && "mt-[100px]"
        } ${className && className}`
      )}
    >
      <div className="flex justify-center">
        <h4>{title}</h4>
      </div>
      <div className=" w-full lg:w-[328px]">{children}</div>
      <img
        src={borderImg}
        className={cn(borderImgClassName, "hidden lg:block")}
      />
      <div className="flex-auto flex items-end justify-center lg:hidden">
        <img
          src={borderImgMob}
          className={twMerge("-mb-3", borderImgClassName)}
        />
      </div>
    </div>
  );
}

AuthCard.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  borderImg: PropTypes.any,
  borderImgMob: PropTypes.any,
  isCenterMob: PropTypes.bool,
  borderImgClassName: PropTypes.string,
  className: PropTypes.string,
};
