import * as React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { MdOutlineClose } from "react-icons/md";

import { changeSidebarType } from "../../../../store/layout/actions";
import { getLeftSideBarType } from "../../../../store/layout/reducer";

import SidebarContent from "./SidebarContent";
import Logo from "../../Logo";
import SidebarFooter from "./SidebarFooter";

import { SIDEBAR_TYPES } from "../../../constants/appearance";

const Sidebar = ({ isMobile, schema }) => {
  const dispatch = useDispatch();
  const leftSideBarType = useSelector(getLeftSideBarType);

  const isDefaultSidebar = (type) => type === SIDEBAR_TYPES.DEFAULT;

  const tToggle = () => {
    dispatch(
      changeSidebarType(
        isDefaultSidebar(leftSideBarType)
          ? SIDEBAR_TYPES.CONDENSED
          : SIDEBAR_TYPES.DEFAULT,
        true
      )
    );
  };

  return (
    <div className="sidebar-menu overflow-y-auto">
      <Logo
        color="#fff"
        className="sidebar-logo-expanded"
        height={44}
        width={170}
      />
      <Logo isExpanded={false} className="sidebar-logo-condensed" />
      <SidebarContent schema={schema.main} isMobile={isMobile} />
      <SidebarFooter schema={schema.footer} isMobile={isMobile} />
      <button onClick={tToggle} className="sidebar-toggle-btn ">
        {isDefaultSidebar(leftSideBarType) ? (
          <BsChevronLeft size={10} />
        ) : (
          <BsChevronRight size={10} />
        )}
      </button>

      <button className="sidebar-close-btn text-purple" onClick={tToggle}>
        <MdOutlineClose className="fill-[currentColor]" />
      </button>
    </div>
  );
};

Sidebar.propTypes = {
  isMobile: PropTypes.bool,
  schema: PropTypes.any,
};

export default Sidebar;
