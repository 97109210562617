import React  from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Button from "../../../../base/components/Button";
import FormikInput from "../../../../base/components/FormikInput";

import { initialValues, validationSchema } from "./form";

import { MAX_EMAIL_LENGTH } from "../../../../validation/lengthConstants";

import { AUTH_GROUP_LINKS } from "../../config";

export default function EmailCard({ description, onSubmit, buttonTitle }) {
  const navigate = useNavigate();

  return (
    <>
      <p className="text-sm mt-2 lg:-mx-12 text-center">{description}</p>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setErrors }) => onSubmit(values, setErrors)}
      >
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormikInput
              id="email"
              name="email"
              containerClassName="mb-9 mt-5 field-auth"
              placeholder="Email address"
              type="email"
              maxLength={MAX_EMAIL_LENGTH}
              autoFocus
            />

            <Button
              type="submit"
              className="btn-purple w-full"
              disabled={!!Object.keys(errors).length}
            >
              {buttonTitle}
            </Button>
            <Button
              type="button"
              className="btn-back w-full mt-5"
              onClick={() => {
                navigate(AUTH_GROUP_LINKS.LINK_LOGIN);
              }}
            >
              Back to Login
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

EmailCard.propTypes = {
  buttonTitle: PropTypes.string,
  description: PropTypes.string,
  onSubmit: PropTypes.func,
};
