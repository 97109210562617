import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useService } from "../../../base/hooks/useService";
import { useLoading } from "../../../base/hooks/useLoading";

import LearnService from "../../../services/LearnService";

import Card from "./Card";
import LessonDescription from "./Card/components/LessonDescription";
import LockedButton from "./Card/components/LockedButton";
import Spinner from "../../../base/components/Spinner";

import { getNextLesson } from "../helpers/getNextLesson";

import satisfiedCatIcon from "../assets/cat-satisfied.svg";

import { SUBSCRIPTION_ACTIVE_STATUS_ID } from "../../../base/constants/subscriptions";

import { LESSON_DETAILS_LINKS } from "../../lesson/config";
import { PROFILE_GROUP_LINKS } from "../../profile/config";

import {
  getSubscription,
  getSubscriptionClear,
} from "../../../store/user/Subscriptions/actions";

const ESSENTIAL_CATEGORY_ID = 1;
const MAX_AMOUNT_OF_FREE_LESSONS = 10;

const EssentialCard = ({ subscriptionsState }) => {
  /**
   * @type {LearnService}
   */
  const learnService = useService(LearnService);
  const [isLoading, { registerPromise }] = useLoading();

  const { updated, currentSubscription } = subscriptionsState;

  const [nextEssentialLesson, setNextEssentialLesson] = useState({});
  const [nextUnavailableEssentialLesson, setNextUnavailableEssentialLesson] =
    useState({});

  const isNextEssentialLesson = !!Object.keys(nextEssentialLesson).keys;
  const isNextUnavailableEssentialLesson = !!Object.keys(
    nextUnavailableEssentialLesson
  ).length;

  const isUserSubscribed =
    !!currentSubscription?.durationType &&
    currentSubscription?.status === SUBSCRIPTION_ACTIVE_STATUS_ID;

  const fetchNextEssentialLesson = useCallback(() => {
    registerPromise(
      learnService.getLessons(
        isUserSubscribed ? 1 : MAX_AMOUNT_OF_FREE_LESSONS + 1,
        0,
        {
          categoryId: ESSENTIAL_CATEGORY_ID,
          isCompleted: isUserSubscribed ? !isUserSubscribed : undefined,
        }
      )
    ).then(({ data }) => {
      if (isUserSubscribed) {
        setNextEssentialLesson(data[0]);
        setNextUnavailableEssentialLesson({});
      } else {
        const nextLesson = getNextLesson(
          data.slice(0, MAX_AMOUNT_OF_FREE_LESSONS)
        );
        setNextEssentialLesson(nextLesson || {});
        setNextUnavailableEssentialLesson(
          nextLesson ? {} : data[MAX_AMOUNT_OF_FREE_LESSONS]
        );
      }
    });
  }, [registerPromise, learnService, isUserSubscribed]);

  useEffect(() => {
    fetchNextEssentialLesson();
  }, [fetchNextEssentialLesson]);

  if (isLoading && !isNextEssentialLesson)
    return <Spinner className={"spinner"} />;

  return (
    <Card title="Essential Learning">
      {isNextEssentialLesson && !isNextUnavailableEssentialLesson && (
        <LessonDescription
          name={nextEssentialLesson.name}
          description={nextEssentialLesson.description}
          title={nextEssentialLesson.title}
          link={`${LESSON_DETAILS_LINKS.BASE}/${nextEssentialLesson.id}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_VOCABULARY}`}
        />
      )}
      {isUserSubscribed && !isNextEssentialLesson && (
        <p className="flex text-sm font-light mt-2 text-center flex-auto  justify-center items-center">
          You completed all lessons
        </p>
      )}
      {!isUserSubscribed && isNextUnavailableEssentialLesson && (
        <>
          <LessonDescription
            name={nextUnavailableEssentialLesson.name}
            description={nextUnavailableEssentialLesson.description}
            title={nextUnavailableEssentialLesson.title}
            isAvailable={false}
            link={`${LESSON_DETAILS_LINKS.BASE}/${nextUnavailableEssentialLesson.id}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_VOCABULARY}`}
          />
          <LockedButton
            text="Subscribe to proceed"
            link={PROFILE_GROUP_LINKS.LINK_TO_ACCOUNT_INFORMATION}
          />
        </>
      )}

      <img
        src={satisfiedCatIcon}
        className="hidden lg:block lg:-translate-x-[40%]  lg:absolute lg:h-auto lg:-top-[130px] lg:left-1/2 "
      />
    </Card>
  );
};

const mapStateToProps = ({ Subscriptions }) => ({
  subscriptionsState: Subscriptions,
});

export default connect(mapStateToProps, {
  getSubscription,
  getSubscriptionClear,
})(EssentialCard);
