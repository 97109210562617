export const THEME_TRAVEL_TITLE = `Travel \n& \nYour Location`;
export const THEME_PROFESSION_TITLE = `Profession, Industry \n& \nEducation`;
export const THEME_HOBBIES_TITLE = `Hobbies, Sports \n& \nInterests`;

export const THEME_TRAVEL_ID = 1;
export const THEME_PROFESSION_ID = 2;
export const THEME_HOBBIES_ID = 3;

export const QUESTIONNAIRE_THEMES = {
  [THEME_TRAVEL_ID]: THEME_TRAVEL_TITLE,
  [THEME_PROFESSION_ID]: THEME_PROFESSION_TITLE,
  [THEME_HOBBIES_ID]: THEME_HOBBIES_TITLE,
};

export const QUESTIONNAIRE_CARDS_SORT = {
  [THEME_TRAVEL_ID]: 3,
  [THEME_PROFESSION_ID]: 1,
  [THEME_HOBBIES_ID]: 2,
};

export const CUSTOM_LESSON_LINK_HOBBIES = "hobbies-sports-interests";
export const CUSTOM_LESSON_ID_HOBBIES = 3;
export const CUSTOM_LESSON_TITLE_HOBBIES = `Hobbies, Sports & Interests`;

export const CUSTOM_LESSON_LINK_PROFESSION = "profession-industry-education";
export const CUSTOM_LESSON_ID_PROFESSION = 2;
export const CUSTOM_LESSON_TITLE_PROFESSION =
  "Profession, Industry & Education";

export const CUSTOM_LESSON_LINK_TRAVEL = "travel-your-location";
export const CUSTOM_LESSON_ID_TRAVEL = 4;
export const CUSTOM_LESSON_TITLE_TRAVEL = `Travel & Your Location`;

export const CUSTOM_LESSONS_LINKS_ID = {
  [CUSTOM_LESSON_LINK_HOBBIES]: CUSTOM_LESSON_ID_HOBBIES,
  [CUSTOM_LESSON_LINK_PROFESSION]: CUSTOM_LESSON_ID_PROFESSION,
  [CUSTOM_LESSON_LINK_TRAVEL]: CUSTOM_LESSON_ID_TRAVEL,
};

export const CUSTOM_LESSONS_LINKS_TITLE = {
  [CUSTOM_LESSON_LINK_HOBBIES]: CUSTOM_LESSON_TITLE_HOBBIES,
  [CUSTOM_LESSON_LINK_PROFESSION]: CUSTOM_LESSON_TITLE_PROFESSION,
  [CUSTOM_LESSON_LINK_TRAVEL]: CUSTOM_LESSON_TITLE_TRAVEL,
};

export const QUIZZES_FOR_THEMES = {
  [CUSTOM_LESSON_LINK_HOBBIES]: THEME_HOBBIES_ID,
  [CUSTOM_LESSON_LINK_PROFESSION]: THEME_PROFESSION_ID,
  [CUSTOM_LESSON_LINK_TRAVEL]: THEME_TRAVEL_ID,
};

export const QUIZ_ID_1 = 1;
export const QUIZ_ID_2 = 2;
export const QUIZ_ID_3 = 3;

export const CUSTOM_LEARNING_MENU = [
  {
    title: "Profession, Industry & Education",
    link: "profession-industry-education",
    quizId: QUIZ_ID_2,
  },
  {
    title: "Hobbies, Sports & Interests",
    link: "hobbies-sports-interests",
    quizId: QUIZ_ID_3,
  },
  {
    title: "Travel & Your Location",
    link: "travel-your-location",
    quizId: QUIZ_ID_1,
  },
];

export const QUIZZES_BY_LESSON_CATEGORIES_MAP = {
  [CUSTOM_LESSON_ID_PROFESSION]: QUIZ_ID_2,
  [CUSTOM_LESSON_ID_HOBBIES]: QUIZ_ID_3,
  [CUSTOM_LESSON_ID_TRAVEL]: QUIZ_ID_1,
};
export const CATEGORIES_BY_QUIZZES_MAP = {
  [QUIZ_ID_2]: CUSTOM_LESSON_ID_PROFESSION,
  [QUIZ_ID_3]: CUSTOM_LESSON_ID_HOBBIES,
  [QUIZ_ID_1]: CUSTOM_LESSON_ID_TRAVEL,
};
