import Http from "./Http";

class ProfileService extends Http {
  static $displayName = "ProfileService";

  async changePassword(values) {
    return await this.put("/users/password", values);
  }
  async changeEmail(values) {
    return await this.put("/users/email", values);
  }
  async editProfile(values) {
    return await this.put("/users/profile", values);
  }

  async getProfileInfo() {
    return await this.get("/users/me");
  }
}

export default ProfileService;
