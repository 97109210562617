import * as React from "react";

const ProfileHeader = () => {
  return (
    <section className="flex flex-col w-full lg:pb-[6px] lg:border-b lg:border-b-1 lg:border-dark-gray ">
      <h1>Profile & Settings</h1>
      <p>Manage your preferences here</p>
    </section>
  );
};

export default ProfileHeader;
