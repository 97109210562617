import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Button from "../../Button";

export default function ActionsPractice({ onNextClick, onBackClick }) {
  return (
    <div className="grid self-end lg:grid-flow-col gap-y-[10px] gap-x-2 w-full lg:w-auto ">
      <Button
        type="button"
        onClick={onBackClick}
        className="btn-back w-full lg:w-[190px] h-[40px] text-base"
      >
        No
      </Button>

      <Button
        onClick={onNextClick}
        className="btn-purple w-full lg:w-[190px] h-[40px] text-base "
      >
        Yes
      </Button>
    </div>
  );
}

ActionsPractice.propTypes = {
  currentCardIndex: PropTypes.number,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
  isCompleted: PropTypes.bool,
};
