import React from "react";
import {Link, useLocation} from "react-router-dom";
import {twMerge} from "tailwind-merge";

const GrammarSidebar = ({ schema }) => {
  const location = useLocation();
  const currentLocation = location.pathname;

  const isActive = (link) => currentLocation.includes(link);

  return (
    <div className="flex flex-col pt-[5px] pb-[10px] border-b border-b-1 border-light-grey lg:border-b-0 lg:py-0 lg:min-w-[230px] lg:border-r lg:border-r-2  lg:h-full">
      {schema.map((item) => (
        <Link to={item.link} key={item.link}>
          <li
            className={twMerge(
              ` list-none mb-[10px] text-base font-light text-teal-dark ${
                isActive(item.link) && "font-bold"
              }`
            )}
          >
            <span>{item.title}</span>
          </li>
        </Link>
      ))}
    </div>
  );
};

export default GrammarSidebar;