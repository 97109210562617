import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import Questionnaire from "../../base/components/Questionnaire";
import catIcon from "../../base/components/FlashcardList/assets/cat-completed-practice.svg";
import { LEARN_GROUP_LINKS } from "../learn/config";
import { CUSTOM_QUESTIONNAIRE_BASE_PARAMS } from "./config";

export default function CustomQuestionnairePage() {
  const navigate = useNavigate();
  const [ searchParams, setSearchParams ] = useSearchParams();

  const redirect = searchParams.get(CUSTOM_QUESTIONNAIRE_BASE_PARAMS.REDIRECT);
  const change = searchParams.get(CUSTOM_QUESTIONNAIRE_BASE_PARAMS.CHANGE);

  return (
    <div className="h-full">
      <Questionnaire
        description={
          "The questionnaire allow us to create customized lessons relevant to your academic and professional interests"
        }
        isInitialQuiz={false}
        completedCatIcon={catIcon}
        onExit={() => navigate(LEARN_GROUP_LINKS.LINK_CUSTOM_LEARNING)}
        redirect={redirect}
        change={ change ? true : change }
      />
    </div>
  );
}
