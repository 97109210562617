import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import { FooterTranslation } from "../FooterTranslation";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";

import { ANSWER_STATUSES, ANSWERS } from "../../../../base/constants/answers";

import SentencesService from "../../../../services/SentecesService";
import { ExerciseHeader } from "../../../../base/components/ExerciseHeader";
import { PRACTICE_GROUP_LINKS } from "../../config";
import { shuffleArray } from "../../helpers/shuffleArray";

export const SentenceTranslation = ({ sentence, onNextClick }) => {
  /**
   * @type {SentencesService}
   */
  const sentencesService = useService(SentencesService);
  const [isLoading, { registerPromise }] = useLoading();

  const [answerStatus, setAnswerStatus] = useState(ANSWER_STATUSES.DEFAULT);
  const [resultSentence, setResultSentence] = useState([]);

  const initialWords = sentence.body.trim().split(" ");
  const words = sentence.body.trim().split(" ");
  const wrongWords = sentence.wrongWords.map(({ word }) => word);

  const shuffledWords = useMemo(() => {
    return shuffleArray(words.concat(wrongWords));
  }, [sentence, answerStatus]);

  const toggleWord = useCallback(
    (word, initialWordIndex, isResultWord = false) => {
      if (isResultWord) {
        const filteredSentence = resultSentence.filter(
          (resultWord) => resultWord !== word
        );
        setResultSentence(filteredSentence);
      } else {
        setResultSentence((prevState) => [...prevState, word]);
      }
    },
    [sentence, resultSentence]
  );

  const getWordCards = useCallback(
    (sentence, isResult = false) => {
      if (!sentence) {
        return;
      }
      return sentence.map((word, index) => (
        <div
          key={`${word}_${index}`}
          className={twMerge(
            `sentence-word ${
              !isResult && resultSentence.includes(word) && "hidden"
            } `
          )}
          onClick={() => {
            if (answerStatus === ANSWER_STATUSES.DEFAULT) {
              toggleWord(word, index, isResult);
            }
          }}
        >
          {word}
        </div>
      ));
    },
    [sentence, resultSentence, answerStatus]
  );

  const sendAnswer = useCallback(
    (answer) => {
      registerPromise(sentencesService.setRemember(sentence.id, answer));
    },
    [registerPromise, sentencesService, sentence]
  );

  const onHintClick = useCallback(() => {
    if (resultSentence.length !== initialWords.length) {
      setResultSentence((prevState) => [
        ...prevState,
        initialWords[resultSentence.length],
      ]);
    }
  }, [sentence, resultSentence]);

  const onSubmit = useCallback(() => {
    if (words.join(" ") === resultSentence.join(" ")) {
      setAnswerStatus(ANSWER_STATUSES.SUCCESS);
      sendAnswer({ answer: ANSWERS.YES });
    } else {
      setAnswerStatus(ANSWER_STATUSES.FAILURE);
      sendAnswer({ answer: ANSWERS.NO });
    }
  }, [sentence, resultSentence, answerStatus]);

  return (
    <>
      <ExerciseHeader
        title="Construct this sentence in German"
        closeLink={PRACTICE_GROUP_LINKS.BASE}
        onHintClick={onHintClick}
      />
      <div className="flex-[1_1_auto] flex flex-col lg:justify-between items-center mt-[67px] lg:m-0 lg:pt-2">
        <p className=" text-base lg:text-[21px] lg:leading-[38px] text-center">
          {sentence.translation}
        </p>
        <div className="flex-auto mt-[88px] lg:mt-0 lg:flex-initial lg w-full flex flex-col lg:justify-center items-center">
          <div className="w-full lg:w-[610px] lg:max-w-[610px]  ">
            <div className="flex flex-wrap border-b border-gray min-h-[38px] lg:min-h-0">
              {getWordCards(resultSentence, true)}
            </div>
          </div>
          <div className="w-full lg:min-h-[70px] px-3 lg:px-0 lg:w-[550px] flex flex-wrap items-center justify-center mt-5 mb-5 lg:mb-0 lg:mt-[55px] lg:mb-[68px] ">
            {getWordCards(shuffledWords)}
          </div>
          <FooterTranslation
            answerStatus={answerStatus}
            isSubmitDisabled={isLoading || resultSentence.length === 0}
            onReset={() => {
              setResultSentence([]);
              setAnswerStatus(ANSWER_STATUSES.DEFAULT);
            }}
            onSubmit={onSubmit}
            onSolve={() => {
              setResultSentence(words);
              setAnswerStatus(ANSWER_STATUSES.SOLVED);
            }}
            onNext={() => {
              setAnswerStatus(ANSWER_STATUSES.DEFAULT);
              onNextClick();
            }}
          />
        </div>
      </div>
    </>
  );
};

SentenceTranslation.propTypes = {
  sentence: PropTypes.shape({
    body: PropTypes.string,
    id: PropTypes.string,
    hint: PropTypes.string,
    translation: PropTypes.string,
    stage: PropTypes.number,
  }),
  onNextClick: PropTypes.func,
};
