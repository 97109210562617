import React from "react";

import SummaryVersion1 from '../../../../assets/images/grammar/adjective-endings/summary-version-1.png';
import SummaryVersion2 from '../../../../assets/images/grammar/adjective-endings/summary-version-2.png';
import DefiniteArticleAndDerWords from '../../../../assets/images/grammar/adjective-endings/definite-article-and-der-words.png';
import IndefiniteArticleAndEinWords from '../../../../assets/images/grammar/adjective-endings/indefinite-article-and-ein-words.png';
import NoArticle from '../../../../assets/images/grammar/adjective-endings/no-article.png';
import NominativeCase from '../../../../assets/images/grammar/adjective-endings/nominative-case.png';
import AccusativeCase from '../../../../assets/images/grammar/adjective-endings/accusative-case.png';
import DativeCase from '../../../../assets/images/grammar/adjective-endings/dative-case.png';
import GenitiveCase from '../../../../assets/images/grammar/adjective-endings/genitive-case.png';
import Sheet from "../../components/Sheet";


const SHEETS = [
  {
    title: "Summary Version 1",
    img: SummaryVersion1
  },
  {
    title: "Summary Version 2",
    img: SummaryVersion2
  },
  {
    title: "Definite Article and \"der\" Words",
    img: DefiniteArticleAndDerWords
  },
  {
    title: "Indefinite Article and \"ein\" Words",
    img: IndefiniteArticleAndEinWords
  },
  {
    title: "No Article",
    img: NoArticle
  },
  {
    title: "Nominative Case",
    img: NominativeCase
  },
  {
    title: "Accusative Case",
    img: AccusativeCase
  },
  {
    title: "Dative Case",
    img: DativeCase
  },
  {
    title: "Genitive Case",
    img: GenitiveCase
  },
]

const AdjectiveEndings = () => {

  return (
    <div className=" flex flex-col" >
      {SHEETS.map((item, index) => {

        const { img, title } = item || {};

        return (
          <Sheet
            key={index}
            image={img}
            title={title}
          />
        )
      })}
    </div>
  )
}

export default AdjectiveEndings;