import React from "react";
import PropTypes from "prop-types";

const Sheet = ({
  title = "",
  image,
  wrapperClassName
}) => {

  return (
    <div className={`${wrapperClassName}`}>
      {title && (
        <h2 className=" text-sm md:text-md lg:text-lg underline font-bold text-[#48484a]">
          {title}
        </h2>
      )}
      <img
        className="mt-5 mb-10"
        src={image}
        alt={image}
      />
    </div>
  )
}

Sheet.propTypes = {
  title: PropTypes.string,
  image: PropTypes.any,
  wrapperClassName: PropTypes.string,
}

export default Sheet;