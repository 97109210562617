import * as React from "react";

const Logo = ({ color = "#3b478c", width = "144", height = "30" }) => {
  return (
    <svg
      data-name="Group 653"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 122.601 25"
      style={{
        color,
      }}
    >
      <defs>
        <clipPath id="z4wwteq4wa">
          <path
            data-name="Rectangle 313"
            style={{ fill: "currentColor" }}
            d="M0 0h122.601v25H0z"
          />
        </clipPath>
      </defs>
      <g data-name="Group 632" style={{ clipPath: "url(#z4wwteq4wa)" }}>
        <path
          data-name="Path 675"
          d="M312.064 40.159a4.43 4.43 0 0 1 3.382-.929 9.532 9.532 0 0 0 4.487-.395A7.313 7.313 0 0 0 325 32.613a7.339 7.339 0 0 0-3.191-7.445.988.988 0 0 1-.166-.211.9.9 0 0 1 .247-.143q1.478-.32 2.959-.619l.037-.009v-.66h-7.385c-.384 0-.768.008-1.152.029a7.668 7.668 0 0 0-7.486 7.149 7.41 7.41 0 0 0 3.354 7.163.878.878 0 0 1 .166.211 1.391 1.391 0 0 1-.213.148 14.06 14.06 0 0 0-1.661.776 3.08 3.08 0 0 0-1.021 4.217 4.369 4.369 0 0 0 2.2 1.687 15.061 15.061 0 0 0 5.137.9 23.2 23.2 0 0 0 4.9-.369c.887-.156 1.759-.395 2.638-.6.13-.03.243-.069.187-.248-.2-.64-.395-1.282-.6-1.946-.534.156-1.025.322-1.527.443a21.289 21.289 0 0 1-7.891.471 6.082 6.082 0 0 1-2.229-.643 1.593 1.593 0 0 1-.248-2.76m-.85-8.693a5.745 5.745 0 1 1 11.495.039 5.745 5.745 0 1 1-11.489-.036"
          transform="translate(-273.41 -20.822)"
          style={{ fill: "currentColor" }}
        />
        <path
          data-name="Path 676"
          d="M756.6 131.93v.08a2.06 2.06 0 0 0 .1-.08z"
          transform="translate(-669.824 -116.798)"
          style={{ fill: "currentColor" }}
        />
        <path
          data-name="Path 677"
          d="M652.144 23.34v.006c-.063.075-1.023 2.3-1.439 3.245a.992.992 0 0 1-.151.192.9.9 0 0 1-.175-.166 7.238 7.238 0 0 0-3.868-3.27 7.932 7.932 0 0 0-2.566-.417 8.139 8.139 0 0 0-2.725.417 7.154 7.154 0 0 0-4.017 3.569 8.493 8.493 0 0 0-.754 5.853 7.345 7.345 0 0 0 9.465 5.762 7.094 7.094 0 0 0 4.024-2.911.5.5 0 0 1 .111-.109v-.08h.1c-.032.028-.067.054-.1.08v2.966h2.746V23.34zm-7.553 13.341a5.643 5.643 0 0 1-5.745-5.788 5.729 5.729 0 1 1 11.458-.069 5.657 5.657 0 0 1-5.713 5.856"
          transform="translate(-563.265 -20.298)"
          style={{ fill: "currentColor" }}
        />
        <path
          data-name="Path 678"
          d="M943.311 29.9a7.382 7.382 0 0 0-2.663-5.219 8.315 8.315 0 0 0-6.693-1.8 7.7 7.7 0 0 0-5.1 2.745 7.943 7.943 0 0 0-1.748 6.03 7.414 7.414 0 0 0 5.425 6.633 11.41 11.41 0 0 0 6.366-.017 7.569 7.569 0 0 0 3.218-1.782c.072-.068.158-.206.134-.278-.2-.6-.424-1.187-.657-1.821-.109.1-.183.169-.257.24a7.245 7.245 0 0 1-3.046 1.736 8.573 8.573 0 0 1-3.965.185 5.8 5.8 0 0 1-3.706-2.136 4.725 4.725 0 0 1-.983-2.744h13.682c0-.621.042-1.2-.007-1.767m-13.7-.052a5.286 5.286 0 0 1 5.283-4.969 5.363 5.363 0 0 1 5.955 4.969z"
          transform="translate(-820.734 -20.174)"
          style={{ fill: "currentColor" }}
        />
        <path
          data-name="Path 679"
          d="M156.025 24.18a8.793 8.793 0 0 0-6.259-1.281 7.733 7.733 0 0 0-6.834 7.85 7.477 7.477 0 0 0 3.67 6.731 8.7 8.7 0 0 0 6.635 1.015 7.665 7.665 0 0 0 6.213-7.729 7.651 7.651 0 0 0-3.426-6.586m-1.689 11.472a5.7 5.7 0 0 1-8.817-4.357c-.015-.182-.025-.364-.031-.46a5.57 5.57 0 0 1 3.439-5.408 5.705 5.705 0 0 1 7.5 2.95 5.966 5.966 0 0 1-2.094 7.275"
          transform="translate(-126.538 -20.175)"
          style={{ fill: "currentColor" }}
        />
        <path
          data-name="Path 680"
          d="M493.782 37.934v.356h-2.562v-7.983a5.4 5.4 0 0 0-1.208-3.639 4.609 4.609 0 0 0-4.773-1.578 4.546 4.546 0 0 0-3.523 3.328 6.56 6.56 0 0 0-.262 1.842c-.028 2.555-.011 5.111-.011 7.666v.367c-.1.007-.156.014-.212.014h-2.358V23.162h.732s.884 1.972 1.28 2.867a1.3 1.3 0 0 0 .158.22 1.091 1.091 0 0 0 .21-.208 6.646 6.646 0 0 1 6.969-3.214 7.3 7.3 0 0 1 1.238.334 6.516 6.516 0 0 1 4.266 5.667c.036.4.054.805.054 1.208q.009 3.948 0 7.9"
          transform="translate(-423.948 -20.115)"
          style={{ fill: "currentColor" }}
        />
        <path
          data-name="Path 681"
          d="M813.574 17.94c-.843.177-1.661.387-2.491.513a8.054 8.054 0 0 1-2.95-.07 4.338 4.338 0 0 1-3.558-3.49 7.681 7.681 0 0 1-.189-1.683c-.021-2.622-.01-5.245-.01-7.868v-.35H801v-.646L806.332 0h.6v.132c-.02.844.008 1.691 0 2.536 0 .227.065.294.294.292 1.979-.008 3.958 0 5.937-.01.272 0 .368.062.358.346-.022.573-.007 1.148-.007 1.753-.138.006-.249.015-.36.015-1.96 0-3.92.006-5.879 0-.257 0-.344.057-.343.331.011 2.354 0 4.707.015 7.061a7.037 7.037 0 0 0 .175 1.6 2.709 2.709 0 0 0 2.636 2.173 8.1 8.1 0 0 0 3.621-.517l.2 2.235"
          transform="translate(-709.131)"
          style={{ fill: "currentColor" }}
        />
        <path
          data-name="Path 682"
          d="M13.251 26.889a5.771 5.771 0 0 0-2.287-1.475 6.413 6.413 0 0 0-6.328.877 5.283 5.283 0 0 0-2.014 3.874 5.621 5.621 0 0 0 4.365 6.081 8.22 8.22 0 0 0 6.4-.958 5.339 5.339 0 0 1 1.266-.566c0 .634-.011 1.268.007 1.9a.527.527 0 0 1-.276.512 9.65 9.65 0 0 1-4.128 1.507 10.111 10.111 0 0 1-5.739-.8 7.614 7.614 0 0 1-4.474-7.917 7.7 7.7 0 0 1 6.774-7 9.135 9.135 0 0 1 5.955 1.045 9.956 9.956 0 0 1 1.259.952.313.313 0 0 1 .08.285c-.266.552-.554 1.094-.859 1.684"
          transform="translate(0 -20.198)"
          style={{ fill: "currentColor" }}
        />
        <path
          data-name="Path 683"
          d="M756.7 131.934c-.032.028-.067.054-.1.08v-.08z"
          transform="translate(-669.824 -116.802)"
          style={{ fill: "currentColor" }}
        />
      </g>
    </svg>
  );
};

export default Logo;
