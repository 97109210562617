import React from "react";
import {Button} from "reactstrap";
import SubscriptionCardBadge from "../../../../../../../../../assets/images/subscription-card-badge@x3.png";
import PropTypes from "prop-types";

const SubscriptionCard = ({title, currency, amount, period, id, badge, onSelect, isCurrent}) => {

  return (
    <div className=" h-[189px] lg:h-[238px] py-[20px] lg:py-[30px] px-[30px] lg:px-[34px] rounded-[15px] shadow-sub-card-mob lg:shadow-sub-card">
      {
        !!badge &&
        <div className="relative">
          <img
            src={SubscriptionCardBadge}
            alt=""
            width={58}
            height={74}
            className="absolute top-[-50px] right-[-54px]"
          />
        </div>
      }
      <div className="flex flex-col justify-between h-full">
        <h2 className="text-center text-font font-bold text-[22px] lg:text-2xl">{title}</h2>
        <div className="text-center font-bold text-font text-[36px] lg:text-[42px] leading-[50px]">
          {currency}{amount}<span className="text-md font-[300]">/{period}</span>
        </div>
        <Button
          onClick={() => onSelect(id)}
          disabled={isCurrent}
          className={`btn-purple h-10 w-full text-base rounded-[10px] ${isCurrent ? "opacity-1 bg-[#d4d4d4] text-font" : ""}`}
        >
          {isCurrent ? "Current" : "Select"}
        </Button>
      </div>
    </div>
  )
}

SubscriptionCard.propTypes = {
  title: PropTypes.string,
  currency: PropTypes.string,
  amount: PropTypes.string,
  period: PropTypes.string,
  id: PropTypes.number,
  badge: PropTypes.number,
  onSelect: PropTypes.func,
  isCurrent: PropTypes.bool,
}

export default SubscriptionCard;