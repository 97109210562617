import React, { useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { Toaster } from "react-hot-toast";
import { BsPencil } from "react-icons/bs";

import { useService } from "../../../../../../base/hooks/useService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useToaster } from "../../../../../../base/hooks/useToaster";

import Button from "../../../../../../base/components/Button";
import FormikInput from "../../../../../../base/components/FormikInput";

import ProfileService from "../../../../../../services/ProfileService";

import { MAX_NAME_LENGTH } from "../../../../../../validation/lengthConstants";
import { PROFILE_INFO_LINKS } from "../../config";
import { validationSchema } from "./form";
import { EDIT_PROFILE_SUCCESS_MESSAGE } from "../../../../../../base/constants/messages";
import { useCurrentUser } from "../../../../../../base/contexts/CurrentUser";
import Spinner from "../../../../../../base/components/Spinner";
const PASSWORD = "password";
const EditProfile = () => {
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  const [isLoading, { registerPromise }] = useLoading();
  const { displayToaster } = useToaster();
  const { currentUser, fetchCurrentUser, setCurrentUser } = useCurrentUser();

  const editProfile = useCallback(
    (values) => {
      const { firstName, lastName } = values;
      registerPromise(profileService.editProfile({ firstName, lastName })).then(
        () => {
          displayToaster(EDIT_PROFILE_SUCCESS_MESSAGE);
          setCurrentUser((prev) => ({ ...prev, firstName, lastName }));
        }
      );
    },
    [registerPromise, profileService]
  );

  useEffect(() => fetchCurrentUser(), []);

  if (isLoading) return <Spinner className={"spinner"} />;

  return (
    <section className="mt-2">
      <h2 className="text-[21px] font-bold mb-4">My profile</h2>
      {!isLoading && currentUser && (
        <Formik
          initialValues={{
            email: currentUser?.email,
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            password: PASSWORD,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            editProfile(values);
          }}
        >
          {({ errors, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid gap-y-[10px] lg:grid-cols-[248px_248px] gap-x-4 lg:gap-y-5">
                <div>
                  <FormikInput
                    id="firstName"
                    name="firstName"
                    containerClassName="field-profile"
                    label="First Name"
                    type="text"
                    maxLength={MAX_NAME_LENGTH}
                  />
                </div>
                <div>
                  <FormikInput
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    type="text"
                    containerClassName="field-profile"
                    maxLength={MAX_NAME_LENGTH}
                  />
                </div>
                <div>
                  <FormikInput
                    id="email"
                    name="email"
                    type="email"
                    label="Email Address"
                    disabled
                    containerClassName="field-profile"
                  />
                  <Link
                    to={PROFILE_INFO_LINKS.LINK_TO_PROFILE_INFO_CHANGE_EMAIL}
                  >
                    <a className="link">
                      <BsPencil size={10} />
                      <span className="ml-2">Change email</span>
                    </a>
                  </Link>
                </div>
                <div>
                  <FormikInput
                    id="password"
                    name="password"
                    type="password"
                    label="Password"
                    disabled
                    containerClassName="field-profile"
                  />
                  <Link
                    to={PROFILE_INFO_LINKS.LINK_TO_PROFILE_INFO_CHANGE_PASSWORD}
                  >
                    <a className="link">
                      <BsPencil size={10} />
                      <span className="ml-2">Change password</span>
                    </a>
                  </Link>
                </div>

                <Button
                  type="submit"
                  className="btn-purple w-full h-[40px] text-base rounded-[10px] mt-[20px] lg:mt-0"
                  disabled={isLoading || !!Object.keys(errors).length}
                >
                  Save Changes
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </section>
  );
};

export default EditProfile;
