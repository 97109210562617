import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation } from "react-router-dom";

import { useLocationQuery } from "../../hooks/useQueryString";
import { exampleColumns, exampleData } from "./tableStructureEXAMPLE";
import { useTable, usePagination, useSortBy } from "react-table";
import joinClassNames from "../../helpers/joinClassNames";
import Spinner from "../Spinner";
import Icon from "../Icon";
import { SORT_ICONS } from "../../constants/sorting";

function calculateCurrentPage(offset, limit) {
  return parseInt((parseInt(offset) + parseInt(limit)) / parseInt(limit));
}

const EXAMPLEHeaderComponent = () => {
  return <section className="d-flex flex-row">Table</section>;
};

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export default function Table({
  columns = exampleColumns,
  data = exampleData,
  HeaderComponent = EXAMPLEHeaderComponent,
  handleTableChange = () => {},
  totalCount = 0,
  limit = 15,
  offset = 15,
  isRowClick = true,
  loading = false,
  rowClickPath,
  searchField = "",
  withLocation = true,
  sortProviders,
  className,
  onHeaderClick,
  onSearchChange,
  ...props
}) {
  const location = useLocation();
  const navigate = useNavigate();

  const { params: query } = useLocationQuery();

  const rowEvents = {
    onClick: (event, row) => {
      const path =
        (withLocation ? location.pathname : "") +
        (rowClickPath ? rowClickPath + `/${row.id}` : `/${row.id}`);
      navigate(path);
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { pageIndex, pageSize },
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
    },
    useSortBy
  );

  return (
    <div className="h-full w-full">
      <HeaderComponent onSearchChange={onSearchChange} {...props} />
      <table {...getTableProps()} className={className}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  style={{ width: column.maxWidth, maxWidth: column.maxWidth }}
                  className={joinClassNames(
                    column.getHeaderProps().className,
                    column.sort && "cursor-pointer"
                  )}
                  onClick={() => {
                    onHeaderClick(column);
                  }}
                >
                  <div className="flex justify-between items-center">
                    {column.render("Header")}
                    {column.sort && (
                      <Icon icon={SORT_ICONS[column.sortDirection]} />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {loading ? (
          <Spinner
            className={
              "spinner absolute left-0 top-0 w-full h-screen flex items-center justify-center"
            }
          />
        ) :
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  return (
                    <td {...cell.getCellProps()} key={index}>
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        }
      </table>
    </div>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  dataStructure: PropTypes.any,
  HeaderComponent: PropTypes.any,
  handleTableChange: PropTypes.func,
  totalCount: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  isRowClick: PropTypes.bool,
  loading: PropTypes.bool,
  rowClickPath: PropTypes.string,
  searchField: PropTypes.string,
  withLocation: PropTypes.bool,
  className: PropTypes.string,
  onHeaderClick: PropTypes.func,
  onSearchChange: PropTypes.func,
};
