import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import { GoogleLoginButton } from "./components/GoogleLoginButton";

import googleIcon from "./assets/google-icon.svg";

import { STRINGS } from "./constants/Strings";

const { SOCIAL_lOGIN, SOCIAL_REGISTER } = STRINGS;

const SocialLogin = ({ onSuccess, onFail, isRegister, disabled }) => {
  const [isLoadingGoogleLogin, setIsLoadingGoogleLogin] = useState(true);

  const googleBtnTitle = isRegister
    ? SOCIAL_lOGIN.loginByGoogleBtn
    : SOCIAL_REGISTER.registerByGoogleBtn;

  const onSuccessLogin = useCallback((token) => {
    onSuccess(token);
  }, []);

  const onFailLogin = useCallback((error) => onFail(error), []);

  return (
    <>
      <GoogleLoginButton
        onPending={setIsLoadingGoogleLogin}
        onSuccess={onSuccessLogin}
        onFail={onFailLogin}
        isDisabled={disabled}
      >
        <img src={googleIcon} className="mr-6" />
        {googleBtnTitle}
      </GoogleLoginButton>
    </>
  );
};

SocialLogin.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  isRegister: PropTypes.bool,
};

export default SocialLogin;
