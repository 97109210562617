import React, { useCallback } from "react";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { useService } from "../../../../../../base/hooks/useService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useToaster } from "../../../../../../base/hooks/useToaster";

import FormikInput from "../../../../../../base/components/FormikInput";
import Button from "../../../../../../base/components/Button";

import ProfileService from "../../../../../../services/ProfileService";

import { PROFILE_GROUP_LINKS } from "../../../../config";
import { EDIT_PASSWORD_SUCCESS_MESSAGE } from "../../../../../../base/constants/messages";
import { initialValues, validationSchema } from "./form";
import { EDIT_PROFILE_MESSAGES } from "../../../../../../base/constants/statusMessages";
import { MAX_PASSWORD_LENGTH } from "../../../../../../validation/lengthConstants";

const ChangePassword = () => {
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  const [isLoading, { registerPromise }] = useLoading();
  const navigate = useNavigate();
  const { displayToaster } = useToaster();

  const changePassword = useCallback(
    (values, setErrors, resetForm) => {
      const { currentPassword, newPassword } = values;
      registerPromise(
        profileService.changePassword({
          password: currentPassword,
          newPassword,
        })
      )
        .then(() => {
          displayToaster(EDIT_PASSWORD_SUCCESS_MESSAGE);
          resetForm();
        })
        .catch((error) =>
          setErrors({
            currentPassword: EDIT_PROFILE_MESSAGES.PASSWORD[error.statusCode],
          })
        );
    },
    [registerPromise, profileService]
  );

  return (
    <section className="mt-2">
      <h2 className="text-[21px] font-bold mb-4">Change your Password</h2>
      <Formik
        onSubmit={(values, { setErrors, resetForm }) => {
          changePassword(values, setErrors, resetForm);
        }}
        validationSchema={validationSchema}
        initialValues={initialValues}
      >
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="grid gap-y-[10px] lg:grid-cols-[248px] lg:gap-y-[22px]">
              <FormikInput
                autoFocus
                id="currentPassword"
                name="currentPassword"
                type="password"
                label="Current Password"
                containerClassName="field-profile"
                maxLength={MAX_PASSWORD_LENGTH}
              />
              <FormikInput
                id="newPassword"
                name="newPassword"
                type="password"
                label="New Password"
                containerClassName="field-profile"
                maxLength={MAX_PASSWORD_LENGTH}
              />
              <FormikInput
                id="confirmNewPassword"
                name="confirmNewPassword"
                type="password"
                label="Confirm New Password"
                containerClassName="field-profile"
                maxLength={MAX_PASSWORD_LENGTH}
              />
              <div className="grid gap-y-2 mt-[10px] lg:mt-1">
                <Button
                  type="submit"
                  className="btn-purple w-full rounded-[10px]  h-[40px] text-base"
                  disabled={isLoading || !!Object.keys(errors).length}
                >
                  Save Changes
                </Button>
                <Button
                  className="btn-back w-full rounded-[10px] h-[40px] text-base"
                  onClick={() =>
                    navigate(PROFILE_GROUP_LINKS.LINK_TO_PROFILE_INFO_PAGE)
                  }
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <Toaster />
    </section>
  );
};

export default ChangePassword;
