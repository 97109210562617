import React, {useCallback, useState} from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoBookmark, IoBookmarkOutline } from "react-icons/io5";

import Button from "../../../../base/components/Button";

import { useNavigate } from "react-router-dom";
import {
  LESSON_DETAILS_LINKS,
  LESSON_DETAILS_PARAMS
} from "../../../lesson/config";
import AnimateHeight from "react-animate-height";
import { PROFILE_GROUP_LINKS } from "../../../profile/config";

const noOperation = () => {};

export const LessonCardActions = ({
  id,
  isDarkStyle,
  description,
  isBookmarked,
  closeDetailRedirect,
  defaultDetailTab = LESSON_DETAILS_LINKS.LINK_TO_LESSON_VOCABULARY,
  onClickBookmark = noOperation,
  isStartAllowed = true,
}) => {

  const navigate = useNavigate();

  const [isDescriptionShown, setIsDescriptionShown] = useState(false);

  const IconBookmark = isBookmarked ? IoBookmark : IoBookmarkOutline;
  const IconViewDescription = isDescriptionShown
    ? MdKeyboardArrowUp
    : MdKeyboardArrowDown;

  const onClickStartLesson = useCallback(() =>
    navigate(
      isStartAllowed ?
        (`${LESSON_DETAILS_LINKS.BASE}/${id}${defaultDetailTab}` +
          (closeDetailRedirect ? `?${LESSON_DETAILS_PARAMS.REDIRECT}=${closeDetailRedirect}` : ""))
        :
        PROFILE_GROUP_LINKS.LINK_TO_ACCOUNT_INFORMATION
    ),[navigate, isStartAllowed]);


  return (
    <>
      <div className="w-full lg:w-auto mt-3 lg:mt-0 flex flex-col ">
        <div className="grid grid-cols-[1fr_40px] gap-x-2 lg:flex h-[40px]">
            <Button
              className={`btn-purple text-base lg:w-[190px] h-full rounded-[10px]`}
              onClick={onClickStartLesson}
            >
              Start Lesson
            </Button>
          <div
            className={twMerge(
              `cursor-pointer hover:shadow-base-lg bg-white h-full w-[40px] flex items-center justify-center rounded-[10px] lg:ml-4 text-purple ${
                !isDarkStyle && "shadow-lesson"
              }`
            )}
            onClick={() => onClickBookmark(id, isBookmarked)}
          >
            <IconBookmark size={24} className="fill-[#ffb33b]" />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col ">
        <p
          className="hidden lg:flex self-end -mt-6  items-center cursor-pointer "
          onClick={() => setIsDescriptionShown((prev) => !prev)}
        >
          <span>Description</span>
          <IconViewDescription size={15} className="fill-[currentColor]" />
        </p>
        <AnimateHeight
          duration={300}
          className={twMerge(
            ` w-full`
          )}
          height={isDescriptionShown ? "auto": 0} // see props documentation below
        >
          <div
            className={twMerge(
              ` w-full bg-white rounded-[10px] p-[10px] mt-[10px] ${
                !isDarkStyle && "shadow-lesson"
              }`
            )}
          >
            <p className=" text-font"> {description}</p>
          </div>
        </AnimateHeight>
      </div>

    </>
  );
};

LessonCardActions.propTypes = {
  description: PropTypes.string,
  isBookmarked: PropTypes.bool,
  isDarkStyle: PropTypes.bool,
  id: PropTypes.number,
  closeDetailRedirect: PropTypes.string,
  defaultDetailTab: PropTypes.string,
  onClickBookmark: PropTypes.func,
  isStartAllowed: PropTypes.bool,
};
