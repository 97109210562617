import STATUS_CODE from "../../base/constants/statusCodes";
import { initService, useService } from "../../base/hooks/useService";
import { AUTH, UNAUTORIZED_STRING } from "../../base/constants/headers";
import { sendRefreshToken } from "../refreshSession";
import axios from "axios";
import { mapResponse } from "./mapResponse";
import SessionStorage from "../SessionStorage";
import ToasterService from "../ToastService";
import {AUTH_GROUP_LINKS} from "../../groups/auth/config";

function redirectToLogout() {
  let location = window.location;
  new SessionStorage().clear();
  location.href = location.origin + AUTH_GROUP_LINKS.LINK_LOGIN;
}

export const refreshSessionInterceptor = (error) => {
  let dataObject = {},
    statusCode;
  const { response } = error;
  const { UNAUTHORIZED } = STATUS_CODE;
  /**
   * @type {SessionStorage}
   */
  const storage = initService(SessionStorage);
  const { refreshToken } = storage.getSession();
  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);

  if (response) {
    dataObject = response.data;
  }

  statusCode =
    dataObject.code || dataObject.statusCode || (response && response.status);
  const isUnauth =
    statusCode === UNAUTHORIZED || statusCode === UNAUTORIZED_STRING;

  if (isUnauth && refreshToken) {
    let isRefreshed = false;
    return sendRefreshToken({ refreshToken })
      .then(({ data }) => {
        const session = data.data;
        error.config.headers[AUTH] = `Bearer ${session?.accessToken}`;
        isRefreshed = true;
        // retry request
        return axios.request(error.config).then(mapResponse);
      })
      .catch((err) => {
        if (!isRefreshed) {
          redirectToLogout();
          console.error(response);
        }
        toastr.error(err.message);
      });
  }
  if (isUnauth && !refreshToken) {
    redirectToLogout();
  }
  /* eslint-disable-next-line*/
  return Promise.reject(dataObject);
};
