import React from "react";
import { twMerge } from "tailwind-merge";

export default function Card({ containerClassname, title, children }) {
  return (
    <div
      className={twMerge(
        `relative bg-80% h-[145px] lg:h-auto lg:min-h-[244px]  bg-lesson-category lg:p-[20px] bg-purple bg-blend-multiply bg-right bg-no-repeat  rounded-[17px]  lg:bg-auto hover:opacity-90  ${containerClassname} `
      )}
    >
      <div className="lg:bg-white rounded-[12px] p-[10px] lg:p-3 flex flex-col h-full ">
        <h3 className="text-white lg:text-light-black text-base font-bold">
          {title}
        </h3>
        {children}
      </div>
    </div>
  );
}
