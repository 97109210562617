import React, { useCallback, useEffect, useState } from "react";

import { BOOKMARKS_GROUP_LINKS, BOOKMARKS_GROUP_ROUTES } from "./config";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Tabs } from "../../base/components/Tabs";
import EssentialLessons from "./pages/EssentialLessons";
import CustomLessons from "./pages/CustomLessons";

const CUSTOM_LESSONS = "Custom Lessons";
const ESSENTIAL_LESSONS = "Essential Lessons";

const BOOKMARKS_TABS = [
  {
    title: ESSENTIAL_LESSONS,
    link: BOOKMARKS_GROUP_LINKS.LINK_ESSENTIAL_LESSONS,
  },
  { title: CUSTOM_LESSONS, link: BOOKMARKS_GROUP_LINKS.LINK_CUSTOM_LESSONS },
];

const DEFAULT_TAB = BOOKMARKS_TABS[0];

export default function Bookmarks() {

  const navigate = useNavigate();
  const currentLocation = location.pathname;

  const [activeTab, setTab] = useState("");

  const toggleTab = useCallback((tab) => {
    setTab(tab.title);
    navigate(tab.link);
  }, [navigate, setTab]);

  useEffect(() => {
    const initialTab = BOOKMARKS_TABS.find((tab) =>
      currentLocation.includes(tab.link)
    )

    if (!initialTab) {
      toggleTab(DEFAULT_TAB)
    } else {
      setTab(initialTab?.title)
    }
  }, [currentLocation])

  return (
    <>
      <Tabs
        tabsItems={BOOKMARKS_TABS}
        activeTab={activeTab}
        toggleTab={toggleTab}
      />

      <Routes>
        <Route
          path={BOOKMARKS_GROUP_ROUTES.LINK_ESSENTIAL_LESSONS}
          element={<EssentialLessons />}
        />
        <Route
          path={BOOKMARKS_GROUP_ROUTES.LINK_CUSTOM_LESSONS}
          element={<CustomLessons />}
        />
      </Routes>
    </>
  );
}
