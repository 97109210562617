import React from "react";

import PropTypes from "prop-types";
import Button from "../Button";
import Cat from "../../../assets/images/happy-cat.png";
import CommonModal from "./CommonModal";
import SadCat from "../../../assets/images/sad-cat.svg";

const CatImg = ({...props}) =>
  <img
    src={Cat}
    alt=""
    width="197"
    height="150"
    style={{
      height: 139,
      width: 197,
      objectFit: "none",
      objectPosition: "top"
    }}
    {...props}
  />

const InfoModal = ({children, isOpen, closeModal, initialState}) => {

  const {
    title = "",
    buttonName = "Close",
    happyCat = true,
    sadCat = false,
    buttonClassNames = "btn-purple h-[60px] md:btn-back md:h-10 m-auto w-full sm:w-[250px] text-lg md:text-base rounded-[10px] mt-0",
    modalClassName = "",
    titleClassName = ""
  } = initialState || {};

  return (
    <CommonModal
      show={isOpen}
      initialState={{
        modalClassName
      }}
    >
      {!happyCat || sadCat && <div className="relative">
        <img src={SadCat} className="hidden lg:block absolute right-[-114px] top-[-152px]" alt=""/>
      </div>}
      <h2 className={`text-[28px] font-bold text-center text-font mb-[8px] ${titleClassName}`}>{title}</h2>
      {children}
      {happyCat && !sadCat && (<CatImg className="absolute top-[-138px] left-[50%] translate-x-[-50%] ml-[15px] overflow-hidden flex md:hidden"/>)}
      <div className="flex justify-center mt-[28px]">
        {happyCat && !sadCat ? (
          <div className="w-full sm:w-auto">
            <CatImg className="ml-[45px] overflow-hidden hidden md:flex"/>
            <div className="bg-white opacity-100 w-full sm:w-auto">
              <Button
                className={buttonClassNames}
                onClick={closeModal}
              >
                {buttonName}
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <Button
              className={buttonClassNames}
              onClick={closeModal}
            >
              {buttonName}
            </Button>
          </div>
        )}
      </div>
    </CommonModal>
  )
}

InfoModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  initialState: PropTypes.object,
  size: PropTypes.string,
  children: PropTypes.any,
}

export default InfoModal;