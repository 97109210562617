import React, { useCallback, useEffect } from "react";
import { createSearchParams, Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";

import AuthCard from "../../components/AuthCard";
import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";

import SocialLogin from "../../../../features/SocialLogin";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useRequestErrorMessage } from "../../../../base/hooks/useRequestErrorMessage";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";

import AuthService from "../../../../services/AuthService";
import SessionStorage from "../../../../services/SessionStorage";

import catImg from "../../assets/register/cat.svg";
import catImgMob from "../../assets/register/cat-mob.svg";

import { AUTH_GROUP_LINKS } from "../../config";
import { QUESTIONNAIRE_GROUP_LINKS } from "../../../questionnaire/config";

import { initialValues, validationSchema } from "./form";
import {
  MAX_EMAIL_LENGTH,
  MAX_NAME_LENGTH,
  MAX_PASSWORD_LENGTH,
} from "../../../../validation/lengthConstants";
import { ERROR_CODES } from "../../../../base/constants/errorCodes";
import {
  EDIT_EMAIL_USER_EXIST_ERROR_MESSAGE,
  WRONG_REFERRAL_CODE_ERROR_MESSAGE,
} from "../../../../base/constants/messages";

const SignUp = () => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {SessionStorage}
   */
  const storageSession = useService(SessionStorage);

  const [{ onCatchError }] = useRequestErrorMessage();
  const [isLoading, { registerPromise }] = useLoading();
  const navigate = useNavigate();
  const {
    params: { email, firstName, lastName },
  } = useLocationQuery();

  const registerUser = useCallback(
    (values, setErrors) => {
      const { email, firstName, lastName, password, referralCode } = values;

      registerPromise(
        authService
          .register({
            email,
            password,
            firstName,
            lastName,
            referralCode: referralCode.toUpperCase() || undefined,
          })
          .then(() =>
            navigate({
              pathname: AUTH_GROUP_LINKS.LINK_VERIFICATION,
              search: `?${createSearchParams({
                email: values.email,
              })}`,
            })
          )
          .catch(({ errorCode }) => {
            setErrors({
              email:
                errorCode === ERROR_CODES.USER_ALREADY_EXIST &&
                EDIT_EMAIL_USER_EXIST_ERROR_MESSAGE,
              referralCode:
                errorCode === ERROR_CODES.WRONG_REFERRAL_CODE &&
                WRONG_REFERRAL_CODE_ERROR_MESSAGE,
            });
          })
      );
    },
    [registerPromise, authService, navigate]
  );

  const onSuccessGoogle = useCallback(
    (token) => {
      registerPromise(
        authService.loginWithGoogle(token).then((session) => {
          storageSession.setSession(session);
          navigate(QUESTIONNAIRE_GROUP_LINKS.BASE);
        })
      ).catch(onCatchError);
    },
    [navigate, registerPromise]
  );

  const onFailGoogle = useCallback(() => {
    // TODO error message
  }, []);

  useEffect(() => {
    if (email && firstName && lastName) {
      navigate({
        pathname: AUTH_GROUP_LINKS.LINK_REGISTER,
        search: `?${createSearchParams({ email, firstName, lastName })}`,
      });
    }
  }, []);

  return (
    <AuthCard
      title="Sign Up to Cognate"
      borderImg={catImg}
      borderImgMob={catImgMob}
      borderImgClassName="h-[130px] lg:-translate-x-1/2  lg:absolute lg:h-[100px] lg:-top-[90px] lg:left-1/2 "
      className="lg:mb-[75px]"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setErrors }) => {
          registerUser(values, setErrors);
        }}
      >
        {({ errors, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormikInput
              id="firstName"
              name="firstName"
              containerClassName="mb-9 mt-5 field-auth"
              type="text"
              maxLength={MAX_NAME_LENGTH}
              placeholder="First name"
              autoFocus
            />

            <FormikInput
              id="lastName"
              name="lastName"
              containerClassName="mb-9 mt-5 field-auth"
              type="text"
              maxLength={MAX_NAME_LENGTH}
              placeholder="Last name"
            />
            <FormikInput
              id="email"
              name="email"
              containerClassName="mb-9 mt-5 field-auth"
              placeholder="Email address"
              type="email"
              maxLength={MAX_EMAIL_LENGTH}
            />

            <FormikInput
              id="password"
              name="password"
              containerClassName="mb-9 mt-5 field-auth"
              type="password"
              maxLength={MAX_PASSWORD_LENGTH}
              placeholder="Password"
            />

            <FormikInput
              id="confirmPassword"
              name="confirmPassword"
              containerClassName="mb-9 field-auth	"
              type="password"
              maxLength={MAX_PASSWORD_LENGTH}
              placeholder="Confirm password"
            />

            <FormikInput
              id="referralCode"
              name="referralCode"
              containerClassName="mb-9 field-auth"
              type="text"
              placeholder="Promo Code (if any)"
            />

            <Button
              type="submit"
              className="btn-purple w-full"
              disabled={!!Object.keys(errors).length || isLoading}
            >
              Submit
            </Button>

            <div className="relative flex py-1 items-center">
              <div className="flex-grow border-t border-gray"></div>
              <span className="flex-shrink mx-4 text-gray text-md">Or</span>
              <div className="flex-grow border-t border-gray"></div>
            </div>
            <SocialLogin
              onSuccess={onSuccessGoogle}
              onFail={onFailGoogle}
              isRegister={false}
              disabled={isLoading}
            />
            <div className="mt-5 flex justify-center text-sm">
              <p className="text-font mr-1"> Already have an account?</p>
              <Link to={AUTH_GROUP_LINKS.LINK_LOGIN}>
                <span className="text-purple font-bold ">Sign in</span>
              </Link>
            </div>
          </form>
        )}
      </Formik>
    </AuthCard>
  );
};

export default SignUp;
