import React from "react";
import PropTypes from "prop-types";

export default function ProgressBar({ width }) {
  return (
    <div className="flex w-full items-center">
      <div className=" relative w-full  bg-white  h-2.5 rounded-[5px] border border-purple   ">
        <div
          className="bg-purple h-2.5  absolute top-[-1px] left-[-1px] rounded-[5px] border border-purple "
          style={{ width: width }}
        >
          <div className="h-4 w-4 bg-purple rounded-full  absolute right-0 translate-x-1/2 -translate-y-1/4 "></div>
        </div>
      </div>
      <p className="ml-4">{width}</p>
    </div>
  );
}

ProgressBar.propTypes = {
  width: PropTypes.string,
};
