import React from "react";

import AccusativeCase from '../../../../assets/images/grammar/article-endings/accusative-case.png'
import DativeCase from '../../../../assets/images/grammar/article-endings/dative-case.png'
import GenitiveCase from '../../../../assets/images/grammar/article-endings/genitive-case.png'
import NominativeCase from '../../../../assets/images/grammar/article-endings/nominative-case.png'
import TheDefiniteArticleAcrossAllCases from '../../../../assets/images/grammar/article-endings/the-definite-article-across-all-cases.png'
import Sheet from "../../components/Sheet";

const SHEETS = [
  {
    title: "The Definite Article Across All Cases",
    img: TheDefiniteArticleAcrossAllCases
  },
  {
    title: "Nominative Case",
    img: NominativeCase
  },
  {
    title: "Accusative Case",
    img: AccusativeCase
  },
  {
    title: "Dative Case",
    img: DativeCase
  },
  {
    title: "Genitive Case",
    img: GenitiveCase
  }
]

const ArticleEndings = () => {

  return (
    <div className=" flex flex-col" >
      {SHEETS.map((item, index) => {

        const { img, title } = item || {};

        return (
          <Sheet
            key={index}
            image={img}
            title={title}
          />
        )
      })}
    </div>
  )
}

export default ArticleEndings;