import React, { useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarLinkItem from "./SidebarLinkItem";
import { changeSidebarType } from "../../../../store/layout/actions";
import { SIDEBAR_TYPES } from "../../../constants/appearance";
import { useDispatch } from "react-redux";

export default function generateSidebarLinks(links, isMobile) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentLocation = location.pathname;
  const [, setActiveLink] = useState(currentLocation);

  const onItemClick = (link, isExternal) => {
    if (isExternal) {
      window.open(link, "_blank");
    } else {
      navigate(link);
      setActiveLink(link);
      if (isMobile) {
        dispatch(changeSidebarType(SIDEBAR_TYPES.DEFAULT, isMobile));
      }
    }
  };

  function getLinksFloor(children) {
    return children.map((child) => {
      return (
        <section key={child.label} className="text-white mt-5 ">
          {child.label && (
            <p className=" sidebar-section-title">{child.label}</p>
          )}
          {child.children.map((child) => (
            <SidebarLinkItem
              key={child.linkTo}
              link={child.linkTo}
              isExternalLink={child.isExternal}
              title={child.label}
              count={child.count}
              onClick={onItemClick}
              isActive={currentLocation.includes(child.linkTo)}
              iconItem={child.iconClassname}
            />
          ))}
        </section>
      );
    });
  }

  return (
    <nav className="w-full">
      <div id="sidebar-menu">
        <ul className="mt-5">{getLinksFloor(links)}</ul>
      </div>
    </nav>
  );
}

generateSidebarLinks.propTypes = {
  links: PropTypes.array,
};
