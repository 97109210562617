import React, { useState } from "react";
import PropTypes from "prop-types";

import { LessonCard } from "./index";
import Button from "../../../../base/components/Button";

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

import { lessonType } from "./types";

export const LessonGroup = ({ lessons, title = "Lesson", onBookmark }) => {
  const [isViewAll, setIsViewAll] = useState(false);

  const lastLesson = lessons[lessons.length - 1];

  const Icon = isViewAll ? MdKeyboardArrowUp : MdKeyboardArrowDown;

  const getNextLesson = () => lessons.find((lesson) => !lesson.isCompleted);

  const completedLessonsCount = lessons.reduce(
    (count, { isCompleted }) => (isCompleted ? count + 1 : count),
    0
  );

  return (
    <div className=" pt-[5px] relative shadow-lesson-mob  lg:shadow-none rounded-[10px] lg:pt-0 lg:rounded-0 mt-[10px] lg:mt-5">
      <h4 className="  ml-[10px] text-md lg:text-xl lg:leading-[44px]  lg:ml-0 lg:mb-[5px]">
        {title}
      </h4>
      <div className="flex w-full lg:bg-teal-dark lg:bg-lessons-group  flex-col rounded-[10px] lg:pt-7 lg:pl-7 lg:pr-9 lg:pb-[10px]">
        {isViewAll ? (
          <div className=" gap-y-2 order-2 grid grid-auto-cols lg:gap-y-5">
            {lessons.map((lesson) => (
              <div
                className="border-border border-t-[3px] lg:border-0"
                key={lesson.id}
              >
                <LessonCard lesson={lesson} onBookmark={onBookmark}/>
              </div>
            ))}
          </div>
        ) : (
          <div className="hidden lg:block">
            <LessonCard
              lesson={getNextLesson() || lastLesson}
              isNextLesson={!!getNextLesson()}
              onBookmark={onBookmark}
            />
          </div>
        )}
        <div className="order-1 w-full flex justify-between items-center lg:order-2 lg:mt-[14px]">
          <p className="ml-[10px] mb-[5px] text-xs text-font lg:text-[21px] lg:ml-0  lg:mb-0 lg:text-white lg:font-bold">
            Completed {completedLessonsCount} / {lessons.length}
          </p>
          <Button
            className="hidden lg:flex text-bg-light-grey font-bold  items-center justify-center"
            onClick={() => setIsViewAll((prev) => !prev)}
          >
            <span className="mr-1">
              {isViewAll ? "View Less" : "View All Lessons"}
            </span>
            <Icon size={15} className="fill-[currentColor]" />
          </Button>
        </div>
        <div className="absolute text-font top-4 right-4 right-0 lg:hidden">
          <Button onClick={() => setIsViewAll((prev) => !prev)}>
            <Icon size={30} className="fill-[currentColor]" />
          </Button>
        </div>
      </div>
    </div>
  );
};

LessonGroup.propTypes = {
  lessons: PropTypes.arrayOf(lessonType),
  title: PropTypes.string,
  onBookmark: PropTypes.func,
};
