import { all } from "redux-saga/effects"

import LayoutSaga from "./layout/saga"
import PaymentsSaga from "./user/Payments/saga"
import SubscriptionsSaga from "./user/Subscriptions/saga"
import TransactionsSaga from "./user/Transactions/saga"

export default function* rootSaga() {
  yield all([
    LayoutSaga(),
    PaymentsSaga(),
    SubscriptionsSaga(),
    TransactionsSaga(),
  ])
}
