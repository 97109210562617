import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

export const InfoHint = ({ isOpen }) => {
  return (
    <span
      className={twMerge(
        `hidden absolute text-xs bg-white font-light w-[255px] rounded-[15px]   -translate-x-full -translate-y-[70px] border border-teal-dark p-[10px] ${
          isOpen && "block"
        }`
      )}
    >
      This is the Spaced Repetition Category for this word. As you get the word
      correct in the flash cards, it will be presented to you at longer
      intervals, because you know the word better. The current interval is shown
      here.
    </span>
  );
};

InfoHint.propTypes = {
  isOpen: PropTypes.bool,
};
