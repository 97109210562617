import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";

import Button from "../../../../base/components/Button";
import FormikInput from "../../../../base/components/FormikInput";
import SocialLogin from "../../../../features/SocialLogin";

import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import { useRequestErrorMessage } from "../../../../base/hooks/useRequestErrorMessage";

import { AUTH_GROUP_LINKS } from "../../config";
import { APP_GROUP_LINKS } from "../../../app/config";

import AuthService from "../../../../services/AuthService";

import { initialValues, validationSchema } from "./form";
import { MAX_PASSWORD_LENGTH } from "../../../../validation/lengthConstants";

export default function PasswordCard({ onSubmit, isRegisterPage }) {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const navigate = useNavigate();
  const [{ registerPromise }] = useLoading();
  const [{ onCatchError }] = useRequestErrorMessage();

  const onSuccessGoogle = useCallback(
    (token) => {
      registerPromise(authService.registerLoginWithGoogle(token))
        .catch(onCatchError)
        .then(() => navigate(APP_GROUP_LINKS.BASE));
    },
    [navigate, registerPromise]
  );

  const onFailGoogle = useCallback(() => {
    // TODO error message
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormikInput
            autoFocus
            id="password"
            name="password"
            containerClassName="mb-9 mt-5 field-auth"
            type="password"
            maxLength={MAX_PASSWORD_LENGTH}
            placeholder={isRegisterPage ? "Password" : "New password"}
          />

          <FormikInput
            id="confirmPassword"
            name="confirmPassword"
            containerClassName="mb-9 field-auth	"
            type="password"
            maxLength={MAX_PASSWORD_LENGTH}
            placeholder={
              isRegisterPage ? "Confirm password" : "Confirm new password"
            }
          />

          <Button
            type="submit"
            className="btn-purple w-full"
            disabled={!!Object.keys(errors).length}
          >
            {isRegisterPage ? "Submit" : "Set new password"}
          </Button>

          {isRegisterPage ? (
            <>
              <div className="relative flex py-1 items-center">
                <div className="flex-grow border-t border-gray"></div>
                <span className="flex-shrink mx-4 text-gray text-md">Or</span>
                <div className="flex-grow border-t border-gray"></div>
              </div>
              <SocialLogin
                onSuccess={onSuccessGoogle}
                onFail={onFailGoogle}
                isRegister={false}
              />
            </>
          ) : (
            <Button
              type="submit"
              className="btn-back w-full mt-5"
              onClick={() => {
                navigate(AUTH_GROUP_LINKS.LINK_LOGIN);
              }}
            >
              Back to Login
            </Button>
          )}
        </form>
      )}
    </Formik>
  );
}

PasswordCard.propTypes = {
  isRegisterPage: PropTypes.bool,
  onSubmit: PropTypes.func,
};
