import * as DOMPurify from 'dompurify';

export const formatHTMLContent = (html) => {
  const doc = document.createElement('div');

  doc.innerHTML = (html ?? "");

  const isPseudoLink = link => {
    return link.getAttribute('href')?.startsWith("-");
  }

  const modifyLinkDecoration = link => {
    const explanation = link.getAttribute('href').slice(1);
    const underline = document.createElement('span');

    link.removeAttribute('href');
    link.classList.add('lesson-marked-word');

    underline.classList.add('lesson-marked-word-definition')
    underline.innerHTML = explanation

    link.appendChild(underline);
  };

  Array
    .from(doc.querySelectorAll('a'))
    .forEach(link => {
      if (!isPseudoLink(link)) return;

      modifyLinkDecoration(link);
    });

  return DOMPurify.sanitize(doc.innerHTML);
};