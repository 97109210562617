import Http from "./Http";

class WordsService extends Http {
  static $displayName = "WordsService";

  async getWords(values) {
    return await this.get(`/flashcards`, {
      params: values,
    });
  }
  async setFavorite(id, values) {
    return await this.patch(`/flashcards/${id}`, values);
  }
  async setRemember(id, values) {
    return await this.post(`/flashcards/${id}`, values);
  }
}

export default WordsService;
