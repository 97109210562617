import { useEffect } from "react";

const RESTRICT_ACTIONS = ["paste", "copy", "cut"];

export const useRestrictCopyPaste = () => {
  useEffect(() => {
    RESTRICT_ACTIONS.forEach((action) => {
      window.addEventListener(action, (e) => e.preventDefault());
    });
    return () => {
      RESTRICT_ACTIONS.forEach((action) => {
        window.removeEventListener(action, (e) => e.preventDefault());
      });
    };
  }, []);
};
