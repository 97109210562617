export const CUSTOM_QUESTIONNAIRE_GROUP_LINKS = {
  BASE: "/app/custom-questionnaire",
};

export const CUSTOM_QUESTIONNAIRE_GROUP_ROUTES = {
  BASE: "/custom-questionnaire/:id",
};

export const CUSTOM_QUESTIONNAIRE_BASE_PARAMS = {
  REDIRECT: "redirect",
  CHANGE: "change"
}