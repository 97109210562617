import Http from "./Http";

class QuestionnaireService extends Http {
  static $displayName = "QuestionnaireService";

  async getQuestions(quizId = 1) {
    return await this.get(`/quizzes/${quizId}`);
  }

  async sendAnswers(answers, quizId = 1) {
    await this.put(`/quizzes/${quizId}`, { answers });
  }

  async getQuizzes(params) {
    return await this.get(`/quizzes`, { params });
  }
}

export default QuestionnaireService;
