import React from "react";
import { twMerge } from "tailwind-merge";
import { HiCheck } from "react-icons/hi";
import PropTypes from "prop-types";

export const LessonCardInfo = ({
  isCompleted,
  isDarkStyle,
  title,
  name,
  iconLink,
  isNextLesson,
  isCompleteHighlight = false,
}) => {
  return (
    <div className="flex items-start font-bold lg:max-w-[calc(100%-255px)]">
      <div
        className={twMerge(
          `h-[54px] w-[54px] min-h-[54px] min-w-[54px] max-h-[54px] max-w-[54px] 
                    lg:h-[70px] lg:w-[70px] lg:max-h-[70px] lg:max-w-[70px] lg:min-h-[70px] lg:min-w-[70px]
                    overflow-hidden flex items-center justify-center rounded-full bg-purple 
                    ${
            isDarkStyle && "bg-white"
          }`
        )}
      >
        <img src={iconLink} className="object-contain o" />
      </div>
      <div className="flex flex-col ml-[10px]">
        <div className="flex items-center">
          <div className="self-start pt-1">
            <div
              className={twMerge(
                `w-full h-full min-h-[26px] min-w-[26px] max-h-[26px] max-w-[26px] bg-gray rounded-full flex items-center justify-center ${
                  isCompleted && "bg-teal-dark"
              } ${
                isCompleteHighlight ? "box-border border border-white" : ""
                }`
              )}
            >
            <span
              className={twMerge(
                `block flex items-center justify-center h-[10px] w-[10px] bg-white  rounded-full ${
                  isCompleted && "bg-transparent h-full w-full text-white"
                } ${isNextLesson && "border border-teal-dark border-dashed"}`
              )}
            >
              {isCompleted && (
                <HiCheck size={20} className="fill-[currentColor]" />
              )}
            </span>
            </div>
          </div>
          <p className="text-xl ml-[10px]">{title}</p>
        </div>
        <p className=" mt-2 max-w-[200px] text-ellipsis whitespace-nowrap overflow-hidden">
          {name}
        </p>
      </div>
    </div>
  );
};

LessonCardInfo.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  lessonNumber: PropTypes.number,
  iconLink: PropTypes.string,
  subtitle: PropTypes.string,
  isCompleted: PropTypes.bool,
  isNextLesson: PropTypes.bool,
  isDarkStyle: PropTypes.bool,
  isCompleteHighlight: PropTypes.bool,
};
