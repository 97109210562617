import React from "react";

const GrammarHeader = () => {

  return (
    <section className="flex flex-col w-full lg:pb-[6px]" >
      <h2 className="text-xl font-bold text-font ">
        Grammar references
      </h2>
    </section>
  )
}

export default GrammarHeader;