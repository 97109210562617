import React from "react";
import PropTypes from "prop-types";

export default function AuthCardContainer({ children }) {
  return (
    <div className="w-screen flex justify-center items-stretch	 bg-bg-light-grey flex-auto lg:items-start  lg:w-auto	">
      {children}
    </div>
  );
}

AuthCardContainer.propTypes = {
  children: PropTypes.any,
};
