import { combineReducers } from "redux"

import Layout from "./layout/reducer"
import Payments from "./user/Payments/reducer"
import Subscriptions from "./user/Subscriptions/reducer"
import Transactions from "./user/Transactions/reducer"

const rootReducer = combineReducers({
  Layout,
  Payments,
  Subscriptions,
  Transactions,
});

export default rootReducer
