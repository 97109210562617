import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Field, Formik } from "formik";
import PropTypes from "prop-types";

import { FooterTranslation } from "../FooterTranslation";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";

import WordsService from "../../../../services/WordsService";

import { ANSWER_STATUSES, ANSWERS } from "../../../../base/constants/answers";

import { initialValues, validationSchema } from "./form";
import { ExerciseHeader } from "../../../../base/components/ExerciseHeader";
import { PRACTICE_GROUP_LINKS } from "../../config";
import { shuffleArray } from "../../helpers/shuffleArray";

export const WordTranslation = ({ word, specialCharacters, onNextClick }) => {
  /**
   * @type {WordsService}
   */
  const wordsService = useService(WordsService);
  const [isLoading, { registerPromise }] = useLoading();

  const [answerStatus, setAnswerStatus] = useState(ANSWER_STATUSES.DEFAULT);
  const [isHintShown, setIsHintShown] = useState(false);

  let inputRef = useRef();

  const insertCharacter = (word, character) => {
    const cursorPosition = inputRef.current.selectionStart;
    const wordBefore = word.substring(0, cursorPosition);
    const wordAfter = word.substring(cursorPosition, word.length);
    return `${wordBefore}${character}${wordAfter}`;
  };

  const setCursorPosition = (cursorPosition) => {
    inputRef.current.focus();

    setTimeout(() => {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(
        cursorPosition + 1,
        cursorPosition + 1
      );
    }, 50);
  };

  const characters = useCallback(
    (setFieldValue, values) => {
      return specialCharacters.map((character, index) => (
        <div
          key={index}
          className="word h-[30px] lg:h-[54px] h-[30px] w-[30px] lg:w-[54px] mx-[6px] lg:mx-2.5  mb-2 lg:mb-5 "
          onClick={() => {
            const cursorPosition = inputRef.current.selectionStart;
            setFieldValue("word", insertCharacter(values.word, character));
            setCursorPosition(cursorPosition);
          }}
        >
          {character}
        </div>
      ));
    },
    [specialCharacters]
  );

  const sendAnswer = useCallback(
    (answer) => {
      registerPromise(wordsService.setRemember(word.id, answer));
    },
    [registerPromise, wordsService, word]
  );

  const onSubmit = useCallback(
    (inputtedWord) => {
      if (
        inputtedWord.trim().toLowerCase() === word.word.trim().toLowerCase()
      ) {
        setAnswerStatus(ANSWER_STATUSES.SUCCESS);
        sendAnswer({ answer: ANSWERS.YES });
      } else {
        setAnswerStatus(ANSWER_STATUSES.FAILURE);
        sendAnswer({ answer: ANSWERS.NO });
      }
    },
    [word, specialCharacters, answerStatus]
  );

  const shuffledWords = useMemo(() => {
    return shuffleArray(word.word.split("")).join("");
  }, [word]);

  return (
    <>
      <ExerciseHeader
        title="Translate the following from English to German"
        closeLink={PRACTICE_GROUP_LINKS.BASE}
        onHintClick={() => setIsHintShown(true)}
      />
      <div className="flex-[1_1_auto] flex flex-col lg:justify-between items-center mt-20 lg:m-0 lg:pt-2">
        <p className=" text-base lg:text-[21px] lg:leading-[38px] text-center">
          {word.translation}
        </p>
        {isHintShown && (
          <p className="text-teal-dark lg:text-[21px] lg:leading-[38px] text-center">
            {shuffledWords}
          </p>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          isInitialValid={false}
          onSubmit={(values) => {
            onSubmit(values.word);
          }}
        >
          {({
            errors,
            handleSubmit,
            setFieldValue,
            resetForm,
            values,
            setValues,
            isValid,
          }) => (
            <form
              onSubmit={handleSubmit}
              autoComplete="off"
              className="flex-auto mt-[38px] lg:mt-0 lg:flex-initial lg w-full flex flex-col lg:justify-center items-center"
            >
              <Field
                autoFocus
                type="text"
                name="word"
                innerRef={inputRef}
                className="words-input"
              />
              <div className="w-full lg:w-[300px] flex flex-wrap items-center justify-center mt-5 mb-5 lg:mb-0 lg:mt-[55px]">
                {characters(setFieldValue, values)}
              </div>
              <p className="hidden lg:block text-md text-center font-light mb-[45px]">
                For German characters please click these buttons
              </p>
              <FooterTranslation
                answerStatus={answerStatus}
                isSubmitDisabled={isLoading || !isValid}
                onReset={() => {
                  resetForm();
                  setAnswerStatus(ANSWER_STATUSES.DEFAULT);
                }}
                onSolve={() => {
                  setValues({ word: word.word });
                  setAnswerStatus(ANSWER_STATUSES.SOLVED);
                }}
                onNext={() => {
                  resetForm();
                  setAnswerStatus(ANSWER_STATUSES.DEFAULT);
                  onNextClick();
                }}
              />
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

WordTranslation.propTypes = {
  word: PropTypes.shape({
    word: PropTypes.string,
    id: PropTypes.string,
    hint: PropTypes.string,
    translation: PropTypes.string,
    stage: PropTypes.number,
  }),
  specialCharacters: PropTypes.arrayOf(PropTypes.string),
  onNextClick: PropTypes.func,
};
