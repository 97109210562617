import React from "react";

import PageLayout from "../../base/components/PageLayout";
import Questionnaire from "../../base/components/Questionnaire";
import catIcon from "../../base/components/Questionnaire/assets/cat.svg";
import { APP_GROUP_LINKS } from "../app/config";
import { useNavigate } from "react-router-dom";
export default function QuestionnairePage() {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <Questionnaire
        quizId={"1"}
        description={
          "Welcome to Cognate! Our language learning solution is based around getting to know you, so we can personalize the course to your needs and interests. In order to begin to customize your learning experience, please fill out the following questionnaire."
        }
        completedCatIcon={catIcon}
        onExit={() => navigate(APP_GROUP_LINKS.DASHBOARD)}
      />
    </PageLayout>
  );
}
