import {
  DELETE_CREDIT_CARD,
  DELETE_CREDIT_CARD_CLEAR,
  DELETE_CREDIT_CARD_ERROR,
  DELETE_CREDIT_CARD_SUCCESS,
  GET_CREDIT_CARD, GET_CREDIT_CARD_CLEAR,
  GET_CREDIT_CARD_ERROR,
  GET_CREDIT_CARD_ERROR_CLEAR,
  GET_CREDIT_CARD_SUCCESS,
  UPDATE_CREDIT_CARD, UPDATE_CREDIT_CARD_CLEAR,
  UPDATE_CREDIT_CARD_ERROR,
  UPDATE_CREDIT_CARD_SUCCESS
} from "./actionTypes";


const initialState = {
  paymentMethod: null,
  updated: false,
  loading: false,
  error: "",
}

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case GET_CREDIT_CARD:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case GET_CREDIT_CARD_SUCCESS:
      state = {
        ...state,
        paymentMethod: action.payload.data,
        loading: false,
      };
      break;
    case GET_CREDIT_CARD_CLEAR:
      state = {
        ...state,
        paymentMethod: null,
        loading: false,
        error: "",
      };
      break
    case GET_CREDIT_CARD_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case GET_CREDIT_CARD_ERROR_CLEAR:
      state = {
        ...state,
        loading: false,
        error: "",
      };
      break;
    case UPDATE_CREDIT_CARD:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case UPDATE_CREDIT_CARD_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case UPDATE_CREDIT_CARD_SUCCESS:
      state = {
        ...state,
        paymentMethod: action.payload.data,
        loading: false,
        updated: true,
      };
      break;
    case UPDATE_CREDIT_CARD_CLEAR:
      state = {
        ...state,
        updated: false,
      };
      break;
    case DELETE_CREDIT_CARD:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case DELETE_CREDIT_CARD_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case DELETE_CREDIT_CARD_SUCCESS:
      state = {
        ...state,
        paymentMethod: null,
        loading: false,
        updated: true,
      };
      break;
    case DELETE_CREDIT_CARD_CLEAR:
      state = {
        ...state,
        updated: false,
      };
      break;
    default:
      state = { ...state };
  }

  return state;
}