import React from "react";
import App from "./App";
import Context from "./Context";
// import * as serviceWorker from "./serviceWorker"
import { createRoot } from "react-dom/client";
import "./index.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
window.addEventListener("orientationchange", () => {
  document.documentElement.style.height = `initial`;
  setTimeout(() => {
    document.documentElement.style.height = `100%`;
    setTimeout(() => {
      window.scrollTo({ top: 0 });
    }, 500);
  }, 500);
});
const Application = (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Context>
      <App />
    </Context>
  </GoogleOAuthProvider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(Application);

// serviceWorker.unregister()
