import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {createPortal} from "react-dom";

const initialStateDefault = {
  modalWrapperClassName: "",
  modalClassName: "",
  modalBodyClassName: "",
  modalBodyWrapperClassName: "",
  modalAnimationClassName: "",
}

const CommonModal = ({
  initialState = initialStateDefault,
  root,
  show,
  children
}) => {
  const [parent, setParent] = useState(root);
  const [container, setContainer] = useState();

  const {
    modalWrapperClassName,
    modalClassName,
    modalBodyClassName,
    modalBodyWrapperClassName,
    modalAnimationClassName,
  } = initialState


  useEffect(() => {
    if (!parent) setParent(document.querySelector('#modal'));
    if (!container) setContainer(document.createElement('div'));
  }, []);

  useEffect(() => {
    if (!container || !parent || !show) {
      return;
    }

    parent.appendChild(container);

    return () => {
      if (container) {
        parent.removeChild(container);
      }
    };
  }, [container, parent, show]);

  return (
    container &&
      createPortal(
        <div
          aria-hidden={!show}
          className={`fixed top-0 right-0 left-0 z-50 overflow-y-auto h-full overflow-x-hidden md:inset-0 md:h-full items-center justify-center flex bg-gray bg-opacity-50 dark:bg-opacity-80 ${
            modalWrapperClassName
          }`}
          role="dialog"
        >
          <div className={`relative h-full w-full p-4 md:h-auto max-w-xl ${modalClassName} ${
            (show) ? (modalAnimationClassName || "animate-scale-up-center") : ""
          }`}>
            <div className={`relative rounded-lg bg-white shadow-modal-backdrop dark:bg-gray-700 top-1/2 translate-y-[-50%] md:top-0 md:translate-y-0 ${modalBodyWrapperClassName}`}>
              <div className={`p-6 ${modalBodyClassName}`}>
                {children}
              </div>
            </div>
          </div>
        </div>,
        container
      )
  )
}

CommonModal.propTypes = {
  show: PropTypes.bool,
  root: PropTypes.instanceOf(Element),
  initialState: PropTypes.shape({
    modalWrapperClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    modalBodyClassName: PropTypes.string,
    modalBodyWrapperClassName: PropTypes.string,
    modalAnimationClassName: PropTypes.string
  })
}

export default CommonModal;