import PropTypes from "prop-types";

export const lessonType = PropTypes.shape({
  id: PropTypes.number,
  lessonNumber: PropTypes.number,
  title: PropTypes.string,
  file: PropTypes.shape({
    link: PropTypes.string,
  }),
  description: PropTypes.string,
  isCompleted: PropTypes.bool,
  isFavorite: PropTypes.bool,
});
