import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import CustomInput from "../../../../../../../../base/components/Input";
import Button from "../../../../../../../../base/components/Button";
import {validateField} from "./form";
import CommonModal from "../../../../../../../../base/components/Modals/CommonModal";
import SadCat from "../../../../../../../../assets/images/sad-cat.svg";

const REASON_FIELD_NAME = "type";
const OTHER_FIELD_VALUE = 6;
const CANCELLATION_REASON_FIELD_NAME = "cancellationReason";

const FIELD_VALUES = [
  {
    label: "I do not enjoy learning with Cognate",
    value: 1,
  },{
    label: "It is not worth the money",
    value: 2,
  },{
    label: "I do not have enough time",
    value: 3,
  },{
    label: "I am experiencing technical difficulties",
    value: 4,
  },{
    label: "I have successfully learned what I wanted to learn",
    value: 5,
  },{
    label: "Other (please specify below)",
    value: OTHER_FIELD_VALUE,
  }
];

const initialValues = {
  [REASON_FIELD_NAME]: null,
  [CANCELLATION_REASON_FIELD_NAME]: "",
};

const CancelAutoRenewalModal = ({isOpen, onCancel, onSubmit, initialStates}) => {
  const {
    disableSubmitButton = false,
    disableCancelButton = false,
  } = initialStates || {};

  const [reasonValue, setReasonValue] = useState(initialValues[REASON_FIELD_NAME]);
  const [cancellationReasonValue, setCancellationReasonValue] = useState(initialValues[CANCELLATION_REASON_FIELD_NAME]);
  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (reasonValue === OTHER_FIELD_VALUE) {
      const error = await validateField(CANCELLATION_REASON_FIELD_NAME, cancellationReasonValue) || {};
      setErrors(prevState => ({...prevState, ...error}))

      if (Object.values(error).filter(Boolean)?.length) {
        return;
      }
    }

    onSubmit({
      [REASON_FIELD_NAME]: reasonValue,
      ...(reasonValue === OTHER_FIELD_VALUE && { [CANCELLATION_REASON_FIELD_NAME]: cancellationReasonValue })
    })
  }

  const handleCancel = () => {
    setReasonValue(initialValues[REASON_FIELD_NAME]);
    onCancel();
  }

  useEffect(() => {
    return () => setReasonValue(initialValues[REASON_FIELD_NAME])
  }, [])

  return (
    <CommonModal show={isOpen}>
      <div className="relative">
        <img src={SadCat} className="hidden lg:block absolute right-[-114px] top-[-152px]" alt="" />
      </div>
      <h2 className="lg:mx-[47px] text-xl lg:text-2xl font-bold text-center text-font mb-[6px] tracking-tighter md:tracking-normal">
        Cancel auto-renewal
      </h2>
      <form
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col lg:mx-[47px]">
          {
            FIELD_VALUES.map((item, index) => {

              const { label, value } = item

              return (
                <div key={index} className="flex flex-row content-center mt-[4px]">
                  <CustomInput
                    id={`${REASON_FIELD_NAME}-${index}`}
                    type="radio"
                    className="rounded-[100px] checked:bg-purple border-purple m-[5px] ml-0 default:bg-white"
                    name={REASON_FIELD_NAME}
                    value={value}
                    checked={value === reasonValue}
                    onChange={() => setReasonValue(value)}
                  />
                  <label
                    className="text-font text-[14px] self-center"
                    onClick={() => setReasonValue(value)}
                    defaultValue={cancellationReasonValue}
                  >
                    {label}
                  </label>
                </div>
              )
            })
          }
          {
            reasonValue === OTHER_FIELD_VALUE &&
              <>
                <textarea
                  className="mt-[4px] rounded-[10px] border-purple border-[1px] h-[69px] min-h-[69px] max-h-[69px]"
                  onChange={({target}) => setCancellationReasonValue(target.value)}
                  name={CANCELLATION_REASON_FIELD_NAME}
                  defaultValue={cancellationReasonValue}
                />
                {errors?.[CANCELLATION_REASON_FIELD_NAME] && <p className="error-input-msg text-[12px]">{errors[CANCELLATION_REASON_FIELD_NAME]}</p>}
              </>

          }
          <div className="flex flex-col-reverse lg:flex-row mt-[20px] lg:mx-[20px]">
            <Button
              className="btn-purple h-[40px] mr-[10px] w-full text-base rounded-[10px] mt-[10px] lg:mt-0 "
              onClick={handleCancel}
              disabled={disableCancelButton}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="btn-back h-[40px] w-full lg:ml-[10px] text-base rounded-[10px]"
              disabled={disableSubmitButton || !reasonValue}
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </CommonModal>
  )
}

CancelAutoRenewalModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  initialStates: PropTypes.object || undefined,
}

export default CancelAutoRenewalModal;