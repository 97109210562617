import React, { useCallback, useEffect, useState } from "react";

import { connect } from "react-redux";
import {
  activateSubscription,
  activateSubscriptionClear,
  cancelSubscription,
  cancelSubscriptionClear,
  getSubscription,
  getSubscriptionClear, subscriptionErrorClear,
  updateSubscription,
  updateSubscriptionClear
} from "../../../../../../../../store/user/Subscriptions/actions";
import { formatDate } from "../../../../../../../../base/helpers/formatDate";
import Button from "../../../../../../../../base/components/Button";
import {
  SUBSCRIPTION_CANCELED_STATUS_ID, SUBSCRIPTION_ERRORS,
  SUBSCRIPTION_STATUSES,
  SUBSCRIPTION_SWITCH_MESSAGES,
  SUBSCRIPTION_TYPES,
  SUBSCRIPTIONS_DISPLAY_PROPERTIES
} from "../../../../../../../../base/constants/subscriptions";
import PropTypes from "prop-types";
import ChangeSubscriptionModal from "../ChangeSubscriptionModal";
import InfoModal from "../../../../../../../../base/components/Modals/InfoModal";
import CancelAutoRenewalModal from "../CancelAutoRenewalModal";
import { useToaster } from "../../../../../../../../base/hooks/useToaster";
import Spinner from "../../../../../../../../base/components/Spinner";
import { getErrorMessage } from "../../../../../../../../base/helpers/getErrorMessage";

const SUBSCRIPTION_TYPE_FIELD = "Subscription type:";
const SUBSCRIPTION_STATUS_FIELD = "Subscription status:";
const SUBSCRIPTION_EXPIRY_DATE_FIELD = "Expiry date";

const SUBSCRIPTION_AUTO_RENEWAL_ENABLE_MESSAGE = "Auto-renewal successfully enabled."

const CurrentSubscription = ({
  subscriptionState,
  getSubscription,
  getSubscriptionClear,
  updateSubscription,
  updateSubscriptionClear,
  cancelSubscription,
  cancelSubscriptionClear,
  activateSubscription,
  activateSubscriptionClear,
  subscriptionErrorClear,
}) => {
  const [isChangeSubscription, setIsChangeSubscription] = useState(false);
  const [isOpenChangeSubscriptionModal, setIsOpenChangeSubscriptionModal] = useState(false);
  const [isOpenSuccessChangeSubModal, setIsOpenSuccessChangeSubModal] = useState(false);
  const [isOpenCancelSubModal, setIsOpenCancelSubModal] = useState(false);
  const [isOpenCancelSubSuccessModal, setIsOpenCancelSubSuccessModal] = useState(false);
  const { currentSubscription, loading, updated, canceled, activated, error } = subscriptionState;
  const { durationType, status, expiresAt } = currentSubscription || {};

  const { displayToaster, errorToaster } = useToaster();

  const handleSubmitChangeSubscription = useCallback((id) => {
    updateSubscription(id);
  },[])

  const handleCloseSuccessModal = useCallback(() => {
    setIsOpenSuccessChangeSubModal(false);
    updateSubscriptionClear();
    setIsChangeSubscription(false);
  }, [])

  const handleCloseCancelSubSuccessModal = useCallback(() => {
    cancelSubscriptionClear();
    setIsOpenCancelSubSuccessModal(false);
  },[])

  const handleCancelSubscription = useCallback((data) => {
    cancelSubscription(data)
  }, [])

  const handleChangePlanButton = () => {
    setIsChangeSubscription(true);
    setIsOpenChangeSubscriptionModal(true)
  }

  const handleEnableAutoRenewal = () => {
    activateSubscription();
  }

  useEffect(() => {
    getSubscription();
    return () => getSubscriptionClear();
  },[])

  useEffect(() => {
    if (!loading && updated && isChangeSubscription) {
      setIsOpenChangeSubscriptionModal(false);
      setIsOpenSuccessChangeSubModal(true);
    }
  }, [loading, updated])

  useEffect(() => {
    if (!loading && canceled && !activated) {
      setIsOpenCancelSubModal(false);
      setIsOpenCancelSubSuccessModal(true);
    }
    if (!loading && activated && !canceled) {
      activateSubscriptionClear();
      displayToaster(SUBSCRIPTION_AUTO_RENEWAL_ENABLE_MESSAGE);
    }
  }, [loading, canceled, activated])

  useEffect(() => {
    const errorMessage = getErrorMessage(error, SUBSCRIPTION_ERRORS)
    if (errorMessage) {
      errorToaster(errorMessage)
    }
    subscriptionErrorClear();
  }, [error])

  if (loading)
    return (
      <div className="mt-4 order-1 lg:order-3">
        <Spinner className={"spinner"} />
      </div>
    )

  return (
    <div className="mt-4 order-1 lg:order-3 bg-re">
      <h2 className="text-[21px] font-bold text-font mb-[5px]">Current Subscription</h2>
      <div className="text-font font-light text-[14px] lg:text-[16px]">
        <div className="grid grid-cols-2 mt-[10px]">
          <p>{SUBSCRIPTION_TYPE_FIELD}</p>
          <p className="ml-[10px]">{SUBSCRIPTION_TYPES?.[durationType?.toString()] || SUBSCRIPTION_TYPES.none}</p>
        </div>
        <div className="grid grid-cols-2 mt-[10px]">
          <p>{SUBSCRIPTION_STATUS_FIELD}</p>
          <p className="ml-[10px]">{SUBSCRIPTION_STATUSES?.[status?.toString()] || SUBSCRIPTION_STATUSES.none}</p>
        </div>
        {
          status &&
            <>
              {
                ( SUBSCRIPTIONS_DISPLAY_PROPERTIES?.[durationType].expiryDate &&
                  <div className="grid grid-cols-2 mt-[10px]">
                    <p>{SUBSCRIPTION_EXPIRY_DATE_FIELD}</p>
                    <p className="ml-[10px]">{formatDate(expiresAt)}</p>
                  </div>
                )
              }
              <div className="flex flex-col">
                {
                  ( SUBSCRIPTIONS_DISPLAY_PROPERTIES?.[durationType].changeSubscriptionPlanButton &&
                    <Button
                      className="link font-light font-[14px] no-underline"
                      onClick={handleChangePlanButton}
                    >
                      Change subscription plan
                    </Button>
                  )
                }
                {
                  ( SUBSCRIPTIONS_DISPLAY_PROPERTIES?.[durationType].cancelAutoRenewal &&
                    SUBSCRIPTION_CANCELED_STATUS_ID === status &&
                    <Button
                      className="link font-light font-[14px] mt-[10px] no-underline"
                      onClick={handleEnableAutoRenewal}
                    >
                      Enable auto renewal
                    </Button>
                  )
                }
                {
                  ( SUBSCRIPTIONS_DISPLAY_PROPERTIES?.[durationType].cancelAutoRenewal &&
                    SUBSCRIPTION_CANCELED_STATUS_ID !== status &&
                    <Button
                      className="link font-light font-[14px] mt-[8px] no-underline"
                      onClick={() => setIsOpenCancelSubModal(true)}
                    >
                      Cancel auto-renewal
                    </Button>
                  )
                }
              </div>
            </>
        }
      </div>
      <ChangeSubscriptionModal
        show={isOpenChangeSubscriptionModal}
        closeModal={() => setIsOpenChangeSubscriptionModal(false)}
        onSubmit={handleSubmitChangeSubscription}
      />
      <InfoModal
        isOpen={isOpenSuccessChangeSubModal}
        closeModal={handleCloseSuccessModal}
        initialState={{
          title: "Success",
          buttonName: "Close",
        }}
        size="xl"
      >
        <p className="text-font text-[16px] font-bold text-center">
          {SUBSCRIPTION_SWITCH_MESSAGES?.[durationType?.toString()] || ""}
        </p>
        <p className="mt-[10px] text-font text-[16px] font-light text-center">
          You will be charged according to your new plan starting at your next billing cycle.
        </p>
      </InfoModal>
      <CancelAutoRenewalModal
        isOpen={isOpenCancelSubModal}
        onSubmit={handleCancelSubscription}
        onCancel={() => setIsOpenCancelSubModal(false)}
        initialStates={{
          disableSubmitButton: loading,
          disableCancelButton: loading,
        }}
      />
      <InfoModal
        isOpen={isOpenCancelSubSuccessModal}
        closeModal={handleCloseCancelSubSuccessModal}
        initialState={{
          title: "Auto-renewal has been successfully canceled",
          buttonName: "Close",
          buttonClassNames: "btn-purple h-[40px] m-auto w-[250px] text-base rounded-[10px] mt-[10px] mt-[0px]",
          sadCat: true,
          modalClassName: "w-full max-w-[696px]",
          titleClassName: "tracking-tighter"
        }}
        size="3xl"
      >
        <p className="mt-[10px] text-font text-[16px] font-light text-center tracking-tight">
          Your subscription will not auto-renew once your current subscription ends.
          We are sorry to see you go but hope to see you back someday.
          To use Cognate again after your subscription ends,
          just log in and renew your subscription to pick up where you left off.
        </p>
      </InfoModal>
    </div>
  )
}

CurrentSubscription.propTypes = {
  subscriptionState: PropTypes.object,
  getSubscription: PropTypes.func,
  getSubscriptionClear: PropTypes.func,
  updateSubscription: PropTypes.func,
  updateSubscriptionClear: PropTypes.func,
  cancelSubscription: PropTypes.func,
  cancelSubscriptionClear: PropTypes.func,
  activateSubscription: PropTypes.func,
  activateSubscriptionClear: PropTypes.func,
  subscriptionErrorClear: PropTypes.func,
}

const mapStateToProps = ({ Subscriptions }) => ({
  subscriptionState: Subscriptions
})

export default connect(mapStateToProps,{
  getSubscription,
  getSubscriptionClear,
  updateSubscription,
  updateSubscriptionClear,
  cancelSubscription,
  cancelSubscriptionClear,
  activateSubscription,
  activateSubscriptionClear,
  subscriptionErrorClear,
})(CurrentSubscription);