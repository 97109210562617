export const ACS_SORT = "asc";
export const DESC_SORT = "desc";
export const DEFAULT_SORT = "default";

export const DESC_SORT_ICON = "sortDesc";
export const ASC_SORT_ICON = "sortAsc";
export const DEFAULT_SORT_ICON = "sortDefault";

export const SORT_SWITCHER = {
  [DEFAULT_SORT]: DESC_SORT,
  [DESC_SORT]: ACS_SORT,
  [ACS_SORT]: DESC_SORT,
};

export const SORT_ICONS = {
  [DEFAULT_SORT]: DEFAULT_SORT_ICON,
  [DESC_SORT]: DESC_SORT_ICON,
  [ACS_SORT]: ASC_SORT_ICON,
};
