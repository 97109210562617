import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useService } from "../../hooks/useService";
import ProfileService from "../../../services/ProfileService";
import { useLoading } from "../../hooks/useLoading";

export const CurrentUserContext = createContext();

export const CurrentUserProvider = ({ children }) => {
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  const [, { registerPromise }] = useLoading();
  const [currentUser, setCurrentUser] = useState(null);

  const fetchCurrentUser = useCallback(() => {
    registerPromise(profileService.getProfileInfo()).then(
      ({ email, firstName, lastName }) => {
        setCurrentUser({ email, firstName, lastName });
      }
    );
  }, [registerPromise, profileService]);

  return (
    <CurrentUserContext.Provider
      value={useMemo(
        () => ({
          currentUser,
          fetchCurrentUser,
          setCurrentUser,
        }),
        [currentUser, fetchCurrentUser, setCurrentUser]
      )}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};

export const useCurrentUser = () => useContext(CurrentUserContext);
