import * as React from "react";

import AuthCardContainer from "./AuthCardContainer";
import AuthHeader from "./Header";

const AuthLayout = ({ children }) => {
  return (
    <div className=" h-full flex flex-col ">
      <AuthHeader />
      <AuthCardContainer> {children}</AuthCardContainer>
    </div>
  );
};

export default AuthLayout;
