import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useService } from "../../../base/hooks/useService";
import { useLoading } from "../../../base/hooks/useLoading";

import Card from "./Card";
import LessonDescription from "./Card/components/LessonDescription";

import surprisedCat from "../assets/cat-surprised.svg";

import Spinner from "../../../base/components/Spinner";
import WordsService from "../../../services/WordsService";
import { PRACTICE_GROUP_LINKS } from "../../practice/config";

const FlashcardsCard = ({ subscriptionsState }) => {
  /**
   * @type {WordsService}
   */
  const wordsService = useService(WordsService);
  const [isLoading, { registerPromise }] = useLoading();

  const [flashcardsLength, setFlashcardsLength] = useState(0);

  const fetchFlashcardsLength = useCallback(() => {
    registerPromise(
      wordsService.getWords({
        limit: 1,
        offset: 0,
        byStage: true,
        isCompleted: true,
      })
    ).then(({ pagination }) => {
      setFlashcardsLength(pagination.totalCount);
    });
  }, [registerPromise, wordsService]);

  useEffect(() => fetchFlashcardsLength(), [fetchFlashcardsLength]);

  if (isLoading && !fetchFlashcardsLength)
    return <Spinner className={"spinner"} />;

  return (
    <Card containerClassname="lg:bg-teal-dark" title="Let's Practice!">
      {flashcardsLength ? (
        <LessonDescription
          name={`${flashcardsLength} Flash Cards`}
          title={"Vocabulary"}
          description={"Keep up with your daily vocabulary practice!"}
          btnText="Go to practice"
          link={PRACTICE_GROUP_LINKS.LINK_TO_FLASHCARDS}
        />
      ) : (
        <p className="text-white lg:text-font font-bold lg:font-normal flex-auto flex justify-center items-center">
          You have no flashcards to review
        </p>
      )}
      <img
        src={surprisedCat}
        className="hidden lg:block lg:-translate-x-1/2  lg:absolute lg:h-auto lg:-top-[138px] lg:left-1/2 "
      />
    </Card>
  );
};

const mapStateToProps = ({ Subscriptions }) => ({
  subscriptionsState: Subscriptions,
});

export default connect(mapStateToProps, {})(FlashcardsCard);
