import React from "react";
import Button from "../../../../../base/components/Button";
import { useNavigate } from "react-router-dom";

export default function LessonDescription({
  name,
  title,
  description,
  link,
  btnText = "Go to lesson",
  isAvailable = true,
  icon,
}) {
  const navigate = useNavigate();

  return (
    <>
      <h2 className=" text-white lg:text-light-black text-[21px] font-bold">
        {name}
      </h2>
      <p className=" text-white lg:text-purple text-xs font-bold">{title}</p>
      <p className="hidden lg:block text-font text-sm font-light mt-2 h-[44px] overflow-hidden text-ellipsis  ">
        {description}
      </p>
      {isAvailable && (
        <Button
          className="bg-white lg:bg-purple text-purple lg:text-white btn-purple h-10  lg:mx-4 mt-[14px] text-base"
          onClick={() => navigate(link)}
        >
          {btnText}
        </Button>
      )}
    </>
  );
}
