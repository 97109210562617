import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Modal } from "flowbite-react";

import AuthService from "../../../../services/AuthService";
import SessionStorage from "../../../../services/SessionStorage";
import QuestionnaireService from "../../../../services/QuestionnaireService";

import { useRequestErrorMessage } from "../../../../base/hooks/useRequestErrorMessage";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";

import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button/index";
import AuthCard from "../../components/AuthCard";
import SocialLogin from "../../../../features/SocialLogin";

import { initialValues, validationSchema } from "./form";
import {
  MAX_EMAIL_LENGTH,
  MAX_PASSWORD_LENGTH,
} from "../../../../validation/lengthConstants";

import { AUTH_GROUP_LINKS } from "../../config";
import { QUESTIONNAIRE_GROUP_LINKS } from "../../../questionnaire/config";
import { APP_GROUP_LINKS } from "../../../app/config";

import catImg from "../../assets/excited-cat.svg";

import { VERIFICATION_EMAIL_ERROR_MESSAGE } from "../../../../base/constants/messages";
import STATUS_CODE from "../../../../base/constants/statusCodes";

const Login = () => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {SessionStorage}
   */
  const storageSession = useService(SessionStorage);
  /**
   * @type {QuestionnaireService}
   */
  const questionnaireService = useService(QuestionnaireService);

  const navigate = useNavigate();

  const [, { onCatchError }] = useRequestErrorMessage();
  const [isLoading, { registerPromise }] = useLoading();

  const [isBlockedModalShow, setIsBlockedModal] = useState(false);

  const isUserAnsweredQuestionnaire = (questions) =>
    Boolean(questions?.find((question) => question.userAnswers));

  const { UNVERIFIED } = STATUS_CODE;

  const loginUser = useCallback(
    (values, setErrors) => {
      registerPromise(authService.loginUser(values))
        .then((session) => {
          return onSuccessLogin(session);
        })
        .catch((error) => {
          if (error.statusCode === UNVERIFIED) {
            setErrors({
              email: error.message,
            });
          }
        });
    },
    [
      registerPromise,
      authService,
      questionnaireService,
      storageSession,
      navigate,
    ]
  );

  const onSuccessGoogle = useCallback(
    (token) => {
      registerPromise(
        authService.loginWithGoogle(token).then((session) => {
          return onSuccessLogin(session);
        })
      ).catch(onCatchError);
    },
    [navigate, registerPromise]
  );

  const onSuccessLogin = useCallback(
    (session) => {
      storageSession.setSession(session);
      return registerPromise(questionnaireService.getQuestions()).then(
        (data) => {
          navigate(
            isUserAnsweredQuestionnaire(data.questions)
              ? APP_GROUP_LINKS.DASHBOARD
              : QUESTIONNAIRE_GROUP_LINKS.BASE
          );
        }
      );
    },
    [navigate, registerPromise, questionnaireService]
  );

  return (
    <React.Fragment>
      <AuthCard
        title="Log in to Cognate"
        borderImg={catImg}
        borderImgClassName="absolute hidden lg:block -top-20 -right-14"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setErrors }) => {
            loginUser(values, setErrors);
          }}
        >
          {({ errors, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormikInput
                id="email"
                name="email"
                containerClassName="field-auth mb-9 mt-5"
                placeholder="Email address"
                type="email"
                maxLength={MAX_EMAIL_LENGTH}
                autoFocus
              />

              <FormikInput
                id="password"
                name="password"
                containerClassName="field-auth relative"
                type="password"
                maxLength={MAX_PASSWORD_LENGTH}
                placeholder="Password"
              />

              <div className="mt-6 mb-2 text-right">
                <Link
                  to={AUTH_GROUP_LINKS.LINK_FORGOT_PASSWORD}
                  className="text-purple font-bold text-sm"
                >
                  Forgot your password?
                </Link>
              </div>

              <Button
                type="submit"
                className="btn-purple w-full"
                disabled={isLoading || !!Object.keys(errors).length}
              >
                Log in
              </Button>

              <div className="relative flex py-1 items-center">
                <div className="flex-grow border-t border-gray"></div>
                <span className="flex-shrink mx-4 text-gray text-md">Or</span>
                <div className="flex-grow border-t border-gray"></div>
              </div>

              <SocialLogin
                isRegister={true}
                onSuccess={onSuccessGoogle}
                disabled={isLoading}
              />

              <div className="mt-5 flex justify-center text-sm">
                <p className="text-font mr-1"> Don&apos;t have an account?</p>
                <Link to={AUTH_GROUP_LINKS.LINK_SIGNUP}>
                  <span className="text-purple font-bold ">Sign up</span>
                </Link>
              </div>
            </form>
          )}
        </Formik>
      </AuthCard>
      <Modal show={isBlockedModalShow}>
        <Modal.Body>
          <div className="p-2 flex flex-col items-center justify-center text-font leading-8">
            <h4 className="mb-5">Your account has been suspended</h4>
            <p> Your account has been disabled.</p>
            <p>Please contact support for further assistance.</p>
            <a
              href="mailto:support@cognatelanguages.com"
              className="text-purple font-bold"
            >
              support@cognatelanguages.com
            </a>
            <Button
              className="btn-purple w-[328px] mt-5"
              onClick={() => setIsBlockedModal(false)}
            >
              Dismiss
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default Login;
