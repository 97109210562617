import Http from "./Http";

class LearnService extends Http {
  static $displayName = "LearnService";

  async getLessons(limit, offset, params = {}) {
    return await this.get(`/lessons?limit=${limit}&offset=${offset}`, { params });
  }
  async getBookmarkedLessons(limit, offset, params = {}) {
    return await this.get(`/lessons/bookmarked?limit=${limit}&offset=${offset}`, { params });
  }
  async getLessonsTopics(params) {
    return await this.get(`/lessons/topics`, { params });
  }
  async getNextLesson(params) {
    return await this.get(`/lessons`, {
      params: { limit: 1, offset: 0, isCompleted: false, ...params },
    });
  }
  async getLesson(lessonId) {
    return await this.get(`/lessons/${lessonId}`);
  }
  async setComplete(id, values) {
    return await this.patch(`/lessons/${id}`, values);
  }
}

export default LearnService;
