import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";

import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register/index";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Verification from "./pages/Verification";
import BlockedAccount from "./pages/BlockedAccount";
import AuthLayout from "./components/AuthLayout";
import SignUp from "./pages/SignUp";

import { AUTH_GROUP_LINKS, AUTH_GROUP_ROUTES } from "./config";
import {useService} from "../../base/hooks/useService";
import SessionStorage from "../../services/SessionStorage";
import {APP_GROUP_LINKS} from "../app/config";

const DisallowAuthRoutes = ({children}) => {
  /**
   * @type SessionStorage
   */
  const storage = useService(SessionStorage);
  const {accessToken} = storage.getSession();

  if (accessToken) return (
   <Navigate to={APP_GROUP_LINKS.DASHBOARD} replace/>
  );

  return children;
}

export default function AuthRoutes() {
  return (
    <AuthLayout>
      <Routes>
        <Route path={AUTH_GROUP_ROUTES.LINK_LOGIN} element={<DisallowAuthRoutes><Login /></DisallowAuthRoutes>} />

        <Route path={AUTH_GROUP_ROUTES.LINK_REGISTER} element={<DisallowAuthRoutes><Register /></DisallowAuthRoutes>} />

        <Route path={AUTH_GROUP_ROUTES.LINK_SIGNUP} element={<DisallowAuthRoutes><SignUp /></DisallowAuthRoutes>} />

        <Route
          path={AUTH_GROUP_ROUTES.LINK_RESET_PASSWORD}
          element={<DisallowAuthRoutes><ResetPassword /></DisallowAuthRoutes>}
        />

        <Route
          path={AUTH_GROUP_ROUTES.LINK_FORGOT_PASSWORD}
          element={<DisallowAuthRoutes><ForgetPassword /></DisallowAuthRoutes>}
        />

        <Route
          path={AUTH_GROUP_ROUTES.LINK_BLOCKED_ACCOUNT}
          element={<BlockedAccount />}
        />

        <Route
          path={AUTH_GROUP_ROUTES.LINK_VERIFICATION}
          element={<Verification />}
        />

        <Route path={AUTH_GROUP_ROUTES.LINK_LOG_OUT} element={<Logout />} />

        <Route
          path={'*'}
          element={<Navigate to={AUTH_GROUP_LINKS.LINK_LOGIN} replace />}
        />
      </Routes>
    </AuthLayout>
  );
}
