import * as yup from "yup";
import { USER_EMAIL, USER_PASSWORD } from "../../../../validation/user";
import {
  VALIDATION_ERROR_MESSAGE_EMAIL_REQUIRED,
  VALIDATION_ERROR_MESSAGE_PASSWORD_REQUIRED,
} from "../../../../base/constants/messages";

export const validationSchema = yup.object().shape({
  email: USER_EMAIL.required(VALIDATION_ERROR_MESSAGE_EMAIL_REQUIRED),
  password: USER_PASSWORD.required(VALIDATION_ERROR_MESSAGE_PASSWORD_REQUIRED),
});

export const initialValues = {
  email: "",
  password: "",
};
