import * as React from "react";
import { useDispatch } from "react-redux";
import { GiHamburgerMenu } from "react-icons/gi";

import Logo from "../../Logo";

import { WHITE } from "../../../constants/colors";

import { changeSidebarType } from "../../../../store/layout/actions";

import { SIDEBAR_TYPES } from "../../../constants/appearance";

const Header = () => {
  const dispatch = useDispatch();

  const tToggle = () => {
    dispatch(changeSidebarType(SIDEBAR_TYPES.CONDENSED, true));
  };

  return (
    <header className="w-full  min-h-[50px] text-white bg-purple flex justify-between items-center px-4 header">
      <Logo color={WHITE} width={120} />
      <GiHamburgerMenu
        className="fill-[currentColor]"
        size={25}
        onClick={tToggle}
      />
    </header>
  );
};

export default Header;
