import React, { useCallback, useEffect, useState } from "react";

import PropTypes from "prop-types";
import { formatDate } from "../../../../../../../../base/helpers/formatDate";
import { connect } from "react-redux";
import {
  SUBSCRIPTION_TYPES_TRANSACTION,
  SUBSCRIPTIONS_DISPLAY_PROPERTIES
} from "../../../../../../../../base/constants/subscriptions";
import { getTransactions, getTransactionsClear } from "../../../../../../../../store/user/Transactions/actions";
import getFormatPrice, { getStripePrice } from "../../../../../../../../base/helpers/getFormatPrice";
import Button from "../../../../../../../../base/components/Button";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineKeyboardArrowRight
} from "react-icons/md";
import Spinner from "../../../../../../../../base/components/Spinner";

const DOWNLOAD_INVOICE = "Download invoice";
const NO_PAYMENTS = "No previous payments";
const DEFAULT_PAGE_LIMIT = 3;
const DEFAULT_OFFSET = 0;

const BillingHistory = ({
  currentSubscription,
  transactionsState,
  getTransactions,
  getTransactionsClear
}) => {
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET);

  const { durationType = "none" } = currentSubscription || {};

  const {
    userTransactions,
    pagination,
    loading,
  } = transactionsState;
  const {
    nextOffset = DEFAULT_OFFSET,
    totalCount = 0,
  } = pagination || {}

  const onClickPagination = useCallback((offset) => {
    getTransactions({
      limit: DEFAULT_PAGE_LIMIT,
      offset: offset
    })
    setCurrentOffset(offset);
  }, [setCurrentOffset])

  useEffect(() => {
    getTransactions({
      limit: DEFAULT_PAGE_LIMIT,
      offset: DEFAULT_OFFSET,
    })

    return () => getTransactionsClear();
  }, [])

  if (loading)
    return (
      <Spinner className={"spinner"} />
    )

  return (
    SUBSCRIPTIONS_DISPLAY_PROPERTIES?.[durationType].billingHistory &&
    <div >
      <h2 className="text-[21px] font-bold text-font mb-[5px]">Billing History</h2>
      <div className="min-h-[210px]">
        {
          !!userTransactions?.length && userTransactions.map((item, index) => {

            const {
              subscriptionPlan,
              createdAt = "",
              amount = "",
              invoicePdf = "",
            } = item;

            const price = getStripePrice(amount);

            return (
              <div key={index} className="flex flex-col text-font text-sm lg:text-base py-2 border-t border-t-1 border-dark-gray">
                <div className="flex flex-row justify-between">
                  <p className="font-bold">
                    {SUBSCRIPTION_TYPES_TRANSACTION?.[subscriptionPlan] || "Unknown"}
                  </p>
                  <p>
                    {formatDate(createdAt)}
                  </p>
                </div>
                <div className="flex flex-row justify-between mt-1.5">
                  <p className="mt-1">
                    ${getFormatPrice(price)}
                  </p>
                  <a className="link mt-1 text-3 self-center" href={invoicePdf}>
                    {DOWNLOAD_INVOICE}
                  </a>
                </div>
              </div>
            )
          })
        }
        {
          !userTransactions?.length &&
          <div className="flex flex-col text-font text-[16px] py-[8px] border-t border-t-1 border-dark-gray">
            <p>{NO_PAYMENTS}</p>
          </div>
        }
      </div>

      <div className={`${ totalCount <= DEFAULT_PAGE_LIMIT ? "hidden" : "flex"} flex-row `}>
        <Button
          className="mr-[5px] py-[5px] px-[10px] bg-purple rounded-l-[10px] hover:opacity-90"
          onClick={() => onClickPagination(currentOffset - DEFAULT_PAGE_LIMIT)}
          disabled={loading || (currentOffset - DEFAULT_PAGE_LIMIT < 0)}
        >
          <MdOutlineKeyboardArrowLeft className="text-white"/>
        </Button>
        <Button
          className="py-[5px] px-[10px] bg-purple rounded-r-[10px] hover:opacity-90"
          onClick={() => onClickPagination(nextOffset)}
          disabled={loading || (nextOffset > totalCount)}
        >
          <MdOutlineKeyboardArrowRight className="text-white"/>
        </Button>
      </div>
    </div>
  )
}

BillingHistory.propTypes = {
  currentSubscription: PropTypes.object,
  transactionsState: PropTypes.object,
  getTransactions: PropTypes.func,
  getTransactionsClear: PropTypes.func,
}

const mapStateToProps = ({
  Subscriptions: { currentSubscription },
  Transactions,
}) => ({
  currentSubscription,
  transactionsState: Transactions,
})

export default connect(mapStateToProps, {
  getTransactions,
  getTransactionsClear,
})(BillingHistory);