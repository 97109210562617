import * as yup from "yup";

import { NAME_PATTERN, PASSWORD_PATTERN } from "../base/constants/patterns";
import {
  MIN_PASSWORD_LENGTH,
  MIN_NAME_LENGTH,
} from "./lengthConstants";
import {
  VALIDATION_ERROR_MESSAGE_EMAIL_FORMAT,
  VALIDATION_ERROR_MESSAGE_NAME_FORMAT,
  VALIDATION_ERROR_MESSAGE_NAME_SPACES,
  VALIDATION_ERROR_MESSAGE_PASSWORD_FORMAT,
  VALIDATION_ERROR_MESSAGE_PASSWORD_LENGTH,
} from "../base/constants/messages";

export const USER_EMAIL = yup
  .string()
  .email(VALIDATION_ERROR_MESSAGE_EMAIL_FORMAT);

export const USER_PASSWORD = yup
  .string()
  .min(MIN_PASSWORD_LENGTH, VALIDATION_ERROR_MESSAGE_PASSWORD_LENGTH)
  .matches(PASSWORD_PATTERN, VALIDATION_ERROR_MESSAGE_PASSWORD_FORMAT)
  .trim();

export const USER_NAME = yup
  .string()
  .min(MIN_NAME_LENGTH, "")
  .matches(NAME_PATTERN, VALIDATION_ERROR_MESSAGE_NAME_FORMAT)
  .trim(VALIDATION_ERROR_MESSAGE_NAME_SPACES);
