import React, {
  useMemo,
} from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";

import { initialValues, validationSchema } from "./form";
import Button from "../../../../base/components/Button";
import { Modal } from "../../../../base/components/Modal";
import closeIcon from "../../../../base/assets/close-outline.svg";
import catIcon from "../../../auth/assets/confused-cat.svg";
import catIconMob from "../../assets/confused-cat-mob.svg";
import FormikRadio from "../../../../base/components/FormikRadio";

const REPORT_OPTIONS = [
  "The translation is incorrect",
  "The hints are incorrect or not appearing",
  "My response should have been accepted",
];

export const ReportModal = ({ isOpen, onClose, onSubmit }) => {
  const options = useMemo(() => {
    return REPORT_OPTIONS.map((option, index) => (
      <label
        className="cursor-pointer  leading-[1]  text-font  text-xs lg:text-[16px] flex items-center mb-2 lg:mb-[14px]"
        key={index}
      >
        <FormikRadio
          name="reportOption"
          value={option}
        >
          {option}
        </FormikRadio>
      </label>
    ));
  }, [REPORT_OPTIONS]);

  return (
    <Modal isOpen={isOpen}>
      <Formik
        key={isOpen}
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values) => {
          onSubmit(values.reportOption);
        }}
      >
        {({ errors, handleSubmit, values }) => (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="flex flex-col items-center justify-center p-5 lg:pt-10 lg:px-[70px] lg:pb-5 relative"
          >
            <div className="flex flex-col items-baseline justify-center">
              {options}
            </div>
            <Button
              disabled={!values.reportOption}
              onClick={() => handleSubmit(values.reportOption)}
              className="btn-purple text-[21px] lg:text-base h-[60px] lg:h-10 w-full lg:w-[190px] mt-5 lg:mt-[30px] "
            >
              Submit
            </Button>
          </form>
        )}
      </Formik>
      <img
        src={closeIcon}
        onClick={onClose}
        className="h-[30px] cursor-pointer absolute top-0 -translate-y-1/2  translate-x-1/2 right-0 lg:top-[10px] lg:right-[10px]"
      />
      <img
        src={catIcon}
        className="hidden lg:block -translate-x-1/3  absolute lg:h-[122px] -top-[105px] left-1/2"
      />
      <img
        src={catIconMob}
        className="block lg:hidden -translate-x-1/3  absolute lg:h-[122px] -top-[140px] left-1/2"
      />
    </Modal>
  );
};

ReportModal.propTypes = {
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};
