import React from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

export const Tab = ({ toggleTab, tab, activeTab, className }) => {
  const handleToggleTab = () => {
    toggleTab(tab);
  };

  return (
    <p
      className={twMerge(
        `text-xs h-full  cursor-pointer flex items-center justify-center text-purple rounded-[15px]   font-bold lg:text-base lg:w-[212px] z-10 ${
          activeTab === tab.title && "text-white bg-purple rounded-[14px]"
        } ${className}`
      )}
      onClick={handleToggleTab}
    >
      {tab.title}
    </p>
  );
};

Tab.propTypes = {
  tab: PropTypes.any,
  toggleTab: PropTypes.func,
  activeTab: PropTypes.string,
  className: PropTypes.string,
};
