import React from "react";
import Sheet from "../../components/Sheet";

import PersonalPronouns from '../../../../assets/images/grammar/pronouns/personal-pronouns.png';
import ReflexivePronouns from '../../../../assets/images/grammar/pronouns/reflexive-pronouns.png';
import RelativePronouns from '../../../../assets/images/grammar/pronouns/relative-pronouns.png';

const SHEETS = [
  {
    title: "Personal Pronouns",
    img: PersonalPronouns
  },
  {
    title: "Reflexive Pronouns",
    img: ReflexivePronouns
  },
  {
    title: "Relative Pronouns",
    img: RelativePronouns
  }
]

const Pronouns = () => {

  return (
    <div className=" flex flex-col" >
      {SHEETS.map((item, index) => {

        const { img, title } = item || {};

        return (
          <Sheet
            key={index}
            image={img}
            title={title}
          />
        )
      })}
    </div>
  )
}

export default Pronouns;