import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {useService} from "../../../base/hooks/useService";
import TransactionsService from "../../../services/TansactionsService";
import {getTransactionsError, getTransactionsSuccess} from "./actions";
import {GET_TRANSACTIONS} from "./actionTypes";

const transactionsService = useService(TransactionsService);

const getTransactionsAsync = async (data) => {
  const { limit, offset } = data
  return await transactionsService.getTransactions({ limit, offset })
}

function* getTransactions({ payload }) {
  try {
    const { data } = payload;
    const response = yield call(getTransactionsAsync, data);
    yield put(getTransactionsSuccess(response));
  } catch (error) {
    yield put(getTransactionsError(error))
  }
}

export function* watchGetTransactions() {
  yield takeEvery(GET_TRANSACTIONS, getTransactions);
}

function* transactionsSaga() {
  yield all([
    fork(watchGetTransactions),
  ])
}

export default transactionsSaga;