import React, { useState } from "react";

import { Tabs } from "../../base/components/Tabs";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { VOCABULARY_GROUP_LINKS, VOCABULARY_GROUP_ROUTES } from "./config";
import WordList from "./components/WordList";

export const CUSTOM_TAB = "Custom";
export const ESSENTIAL_TAB = "Essential";
export const SAVED_TAB = "Saved";

const VOCABULARY_TABS = [
  {
    title: ESSENTIAL_TAB,
    link: VOCABULARY_GROUP_LINKS.LINK_TO_ESSENTIAL_VOCABULARY_LEARNING,
  },
  {
    title: CUSTOM_TAB,
    link: VOCABULARY_GROUP_LINKS.LINK_TO_CUSTOM_VOCABULARY_LEARNING,
  },
  {
    title: SAVED_TAB,
    link: VOCABULARY_GROUP_LINKS.LINK_TO_SAVED_VOCABULARY_LEARNING,
  },
];
export default function Vocabulary() {
  const navigate = useNavigate();
  const currentLocation = location.pathname;

  const [activeTab, setTab] = useState("");
  const initialTab = VOCABULARY_TABS.find((tab) =>
    currentLocation.includes(tab.link)
  )?.title;

  const toggleTab = (tab) => {
    setTab(tab.title);
    navigate(tab.link);
  };

  return (
    <>
      <div className="w-full">
        <Tabs
          tabsItems={VOCABULARY_TABS}
          activeTab={activeTab || initialTab}
          toggleTab={toggleTab}
          tabClassname="lg:w-[150px]"
        />
      </div>
      <Routes>
        <Route
          key="essential"
          path={VOCABULARY_GROUP_ROUTES.LINK_TO_ESSENTIAL_VOCABULARY_LEARNING}
          element={<WordList tab={ESSENTIAL_TAB} key={currentLocation} />}
        />
        <Route
          path={VOCABULARY_GROUP_ROUTES.LINK_TO_CUSTOM_VOCABULARY_LEARNING}
          element={<WordList tab={CUSTOM_TAB} key={currentLocation} />}
        />
        <Route
          key="saved"
          path={VOCABULARY_GROUP_ROUTES.LINK_TO_SAVED_VOCABULARY_LEARNING}
          element={<WordList tab={SAVED_TAB} key={currentLocation} />}
        />
        <Route
          path="*"
          element={
            <Navigate
              to={VOCABULARY_GROUP_LINKS.LINK_TO_ESSENTIAL_VOCABULARY_LEARNING}
              replace
            />
          }
        />
      </Routes>
    </>
  );
}
