import React, { useCallback, useState } from "react";
import Button from "../../../base/components/Button";

import CompleteButton from "./CompleteButton";
import { useNavigate } from "react-router-dom";
import { LESSON_DETAILS_LINKS } from "../config";
import Header from "./Header";
import {formatHTMLContent} from "../helpers/formatHTML";
import LessonsLightBox from "../../../base/components/ImagesLightBox";


const LG = "font-lg-pure";
const MD = null;
const SM = "font-sm-pure";

const fontSizesArr = [
  {
    className: "text-base pt-[0.125rem]",
    lessonFont: SM,
  },
  {
    className: "text-[21px]",
    lessonFont: MD,
  },
  {
    className: "text-[30px] pb-[0.125rem]",
    lessonFont: LG,
  },
];

export default function Content({ lesson, onCompleteClick }) {
  const navigate = useNavigate();
  const [lessonFontSize, setLessonFontSize] = useState(MD);
  const [openedImageSrc, setOpenedImageSrc] = useState("");

  const activeClass = "font-bold bg-purple text-white";

  const onImgClick = (event) => {
    if (event.target instanceof HTMLImageElement) {
      setOpenedImageSrc(event.target.src)
    }
  }

  const handleLightBoxClose = useCallback(() => setOpenedImageSrc(""), [setOpenedImageSrc])

  return (
    <div className="w-full py-8 h-full">
      <Header>
        <h1>{lesson?.name}</h1>
        <div className=" hidden lg:flex w-[91px] h-[37px] leading-1 grid flex-row text-purple items-stretch justify-items-center">
          {fontSizesArr.map((item, index) => {
            return (
              <p
                key={index}
                className={`w-full h-full flex self-center items-center justify-center border border-purple last:border-l-0 last:rounded-r-[5px] first:border-r-0 first:rounded-l-[5px] leading-[1px] ${
                  item.className
                } ${lessonFontSize === item.lessonFont && activeClass}`}
                onClick={() => setLessonFontSize(item.lessonFont)}
              >
                <span className="pb-[2px]">
                  a
                </span>
              </p>
            );
          })}
        </div>
      </Header>

      <div
        className={`lesson-content ${lessonFontSize && lessonFontSize}`}
        dangerouslySetInnerHTML={{
          __html: formatHTMLContent(lesson?.body),
        }}
        onClick={onImgClick}
      ></div>

      <div className="grid grid-flow-row lg:grid-flow-col gap-y-[10px] gap-x-5 grid-cols-[1fr] lg:grid-cols-[auto]  items-center justify-center justify-items-center mt-[120px] lg:mt-[133px]">
        <Button
          className="btn-back text-base h-10 w-full lg:w-[260px] row-[3]	lg:row-auto"
          onClick={() =>
            navigate(
              `${LESSON_DETAILS_LINKS.BASE}/${lesson?.id}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_EXTRA_INFO}`
            )
          }
        >
          Go to Extra Info
        </Button>

        <CompleteButton lesson={lesson} onCompleteClick={onCompleteClick} />
        {lesson?.nextLessonId && (
          <Button
            className="btn-purple text-base h-10 w-full lg:w-[260px] "
            onClick={() =>
              navigate(
                `${LESSON_DETAILS_LINKS.BASE}/${lesson?.nextLessonId}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_CONTENT}`
              )
            }
          >
            Next Lesson
          </Button>
        )}
      </div>
      <LessonsLightBox src={openedImageSrc} onClose={handleLightBoxClose} />
    </div>
  );
}
