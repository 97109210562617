import {
  ACTIVATE_SUBSCRIPTION,
  ACTIVATE_SUBSCRIPTION_CLEAR,
  ACTIVATE_SUBSCRIPTION_ERROR,
  ACTIVATE_SUBSCRIPTION_SUCCESS,
  BUY_SUBSCRIPTION,
  BUY_SUBSCRIPTION_CLEAR,
  BUY_SUBSCRIPTION_ERROR,
  BUY_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_CLEAR,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_CLEAR,
  GET_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_ERROR_CLEAR,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_CLEAR,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "./actionTypes";

export const getSubscription = () => {
  return {
    type: GET_SUBSCRIPTION,
  };
};

export const getSubscriptionSuccess = (data) => {
  return {
    payload: { data },
    type: GET_SUBSCRIPTION_SUCCESS,
  };
};

export const getSubscriptionError = ({ message }) => {
  return {
    payload: { message },
    type: GET_SUBSCRIPTION_ERROR,
  };
};

export const getSubscriptionClear = () => {
  return {
    type: GET_SUBSCRIPTION_CLEAR,
  };
};

export const buySubscription = (data) => {
  return {
    payload: { data },
    type: BUY_SUBSCRIPTION,
  };
};

export const buySubscriptionSuccess = (data) => {
  return {
    payload: { data },
    type: BUY_SUBSCRIPTION_SUCCESS,
  };
};

export const buySubscriptionError = ({ message }) => {
  return {
    payload: { message },
    type: BUY_SUBSCRIPTION_ERROR,
  };
};

export const buySubscriptionClear = () => {
  return {
    type: BUY_SUBSCRIPTION_CLEAR,
  };
};

export const subscriptionErrorClear = () => {
  return {
    type: SUBSCRIPTION_ERROR_CLEAR,
  };
};

export const updateSubscription = (data) => {
  return {
    payload: { data },
    type: UPDATE_SUBSCRIPTION,
  };
};

export const updateSubscriptionSuccess = (data) => {
  return {
    payload: { data },
    type: UPDATE_SUBSCRIPTION_SUCCESS,
  };
};

export const updateSubscriptionError = ({ message }) => {
  return {
    payload: { message },
    type: UPDATE_SUBSCRIPTION_ERROR,
  };
};

export const updateSubscriptionClear = () => {
  return {
    type: UPDATE_SUBSCRIPTION_CLEAR,
  };
};

export const cancelSubscription = (data) => {
  return {
    payload: { data },
    type: CANCEL_SUBSCRIPTION,
  };
};

export const cancelSubscriptionSuccess = (data) => {
  return {
    payload: { data },
    type: CANCEL_SUBSCRIPTION_SUCCESS,
  };
};

export const cancelSubscriptionError = ({ message }) => {
  return {
    payload: { message },
    type: CANCEL_SUBSCRIPTION_ERROR,
  };
};

export const cancelSubscriptionClear = () => {
  return {
    type: CANCEL_SUBSCRIPTION_CLEAR,
  };
};

export const activateSubscription = (data) => {
  return {
    payload: { data },
    type: ACTIVATE_SUBSCRIPTION,
  };
};

export const activateSubscriptionSuccess = (data) => {
  return {
    payload: { data },
    type: ACTIVATE_SUBSCRIPTION_SUCCESS,
  };
};

export const activateSubscriptionError = ({ message }) => {
  return {
    payload: { message },
    type: ACTIVATE_SUBSCRIPTION_ERROR,
  };
};

export const activateSubscriptionClear = () => {
  return {
    type: ACTIVATE_SUBSCRIPTION_CLEAR,
  };
};
