import React, { useCallback, useEffect, useState } from "react";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";

import WordsService from "../../../../services/WordsService";

import { WordTranslation } from "../../components/WordTranslation";
import CompletedCard from "../../../../base/components/CompletedCard";
import { ExerciseHeader } from "../../../../base/components/ExerciseHeader";
import Spinner from "../../../../base/components/Spinner";

import { SPECIAL_DEUTSCH_CHARACTERS } from "../../../../base/constants/characters";
import { PRACTICE_GROUP_LINKS } from "../../config";
import NoItemsPlaceholder from "../../../../base/components/NoItemsPlaceholder";

const LIMIT = 100;

export default function WordsTranslation() {
  /**
   * @type {WordsService}
   */
  const wordsService = useService(WordsService);
  const [isLoading, { registerPromise }] = useLoading();

  const [words, setWords] = useState({
    data: [],
    pagination: {},
  });
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  const isCompleted = currentWordIndex === words.data?.length;

  const onNextClick = () => {
    if (currentWordIndex < words.data.length) {
      setCurrentWordIndex((prev) => prev + 1);
    }
  };

  const fetchWords = useCallback(() => {
    registerPromise(
      wordsService.getWords({
        limit: LIMIT,
        offset: 0,
        byStage: true,
        isCompleted: true,
      })
    ).then((data) => {
      setWords(data);
    });
  }, [registerPromise, wordsService]);

  useEffect(() => {
    fetchWords();
  }, []);

  if (isLoading && !words.data.length) return <Spinner className={"spinner"} />;

  return (
    <section className="h-full flex flex-col">
      {!!words.data.length && !isCompleted && (
        <WordTranslation
          word={words.data[currentWordIndex]}
          onNextClick={onNextClick}
          specialCharacters={SPECIAL_DEUTSCH_CHARACTERS}
          key={words.data[currentWordIndex].id}
        />
      )}
      {!!words.data.length && isCompleted && (
        <CompletedCard sectionTitle="Word" />
      )}
      {!words.data.length && <NoItemsPlaceholder sectionName="words" />}
    </section>
  );
}
