import React, { useEffect } from "react";

import InfiniteScroll from "react-infinite-scroll-component";
import { LessonCard} from "../../../learn/components/LessonCard";
import Spinner from "../../../../base/components/Spinner";
import { BOOKMARKS_GROUP_LINKS } from "../../config";
import { hasMoreLesson } from "../../helpers/hasMoreLesson";
import {useBookmarkLessons} from "../../hooks/useBookmarkLessons";
import NoLessonsPlaceholder from "../../components/NoLessonsPlaceholder";

const IS_ESSENTIAL = true;

const EssentialLessons = () => {

  const [isLoading, lessons, fetchNextLessons, onBookmark]  = useBookmarkLessons(IS_ESSENTIAL);

  useEffect(() => {
    fetchNextLessons();
  }, [fetchNextLessons])

  if (isLoading && !lessons?.data?.length)
    return <Spinner className={"spinner"} />;

  return (
    <>
      <h4 className="text-xl leading-[44px] mb-[5px] mt-2.5">Bookmarked Essential Lessons</h4>
      <div
        className="overflow-y-auto h-full py-[10px] pb-20 -mx-4 px-4 lg:mx-0 lg:px-0"
        id="scrollableDiv"
      >
        {!!lessons?.data?.length && (
          <InfiniteScroll
            dataLength={lessons.data?.length}
            next={fetchNextLessons}
            hasMore={hasMoreLesson(lessons)}
            className="grid -mx-4 px-4 pb-4 lg:mx-0 lg:px-0 lg:pb-0 grid-cols-1 gap-5"
            scrollableTarget="scrollableDiv"
          >
            {lessons?.data?.map((item, index) => (
              <LessonCard
                key={index}
                lesson={item}
                isDarkStyle
                isNextLesson
                closeDetailRedirect={BOOKMARKS_GROUP_LINKS.LINK_ESSENTIAL_LESSONS}
                onBookmark={onBookmark}
                isCompleteHighlight
              />
            ))}
          </InfiniteScroll>
        )}
        {!lessons?.data?.length && <NoLessonsPlaceholder />}
      </div>
    </>

  )
}

export default EssentialLessons;