import React from "react";
import PrepositionsImg from "../../../../assets/images/grammar/prepositions/prepositions.jpg"
import Sheet from "../../components/Sheet";

const SHEETS = [
  {
    title: "",
    img: PrepositionsImg,
  }
]

const Prepositions = () => {

  return (
    <div className=" flex flex-col" >
      {SHEETS.map((item, index) => {

        const { img, title } = item || {};

        return (
          <Sheet
            key={index}
            image={img}
            title={title}
          />
        )
      })}
    </div>
  )
}

export default Prepositions;