import React, { useEffect, useCallback, useState } from "react";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";

import ExpiredLink from "../../components/ExpiredLink";
import AuthCard from "../../components/AuthCard";
import PasswordCard from "../../components/PasswordCard";
import ThankCard from "../../components/ThankCard";

import AuthService from "../../../../services/AuthService";

import catImg from "../../assets/excited-cat.svg";
import catImgMob from "../../assets/excited-cat-mob.svg";
import {
  RESEND_RESTORE_PASSWORD_SUCCESS_MESSAGE,
  RESTORE_PASSWORD_SUCCESS_MESSAGE,
} from "../../../../base/constants/messages";
import { FORGOT_PASSWORD_MESSAGES } from "../../../../base/constants/statusMessages";

export default function ResetPassword() {
  const [, { registerPromise }] = useLoading();
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const {
    params: { code },
  } = useLocationQuery();

  const resetPassword = useCallback(
    (values) => {
      const { password } = values;
      registerPromise(
        authService.resetPassword({ password, token: code }).then(() => {
          setSuccessMessage(RESTORE_PASSWORD_SUCCESS_MESSAGE);
          setIsLinkSent(true);
        })
      );
    },
    [registerPromise, authService]
  );

  const sendResendLink = useCallback(
    (email, setErrors) => {
      registerPromise(
        authService
          .sendRestorePasswordEmail(email)
          .then(() => {
            setIsLinkExpired(false);
            setSuccessMessage(RESEND_RESTORE_PASSWORD_SUCCESS_MESSAGE);
            setIsLinkSent(true);
          })
          .catch((error) => {
            setErrors({
              email: FORGOT_PASSWORD_MESSAGES[error.statusCode] || "",
            });
          })
      );
    },
    [registerPromise, authService]
  );

  const checkLinkRelevance = useCallback(
    (token) => {
      registerPromise(
        authService.checkLinkRelevance(token).catch(() => {
          setIsLinkExpired(true);
        })
      );
    },
    [registerPromise, authService]
  );

  useEffect(() => {
    if (code) {
      checkLinkRelevance(code);
    }
  }, []);

  return (
    <>
      {isLinkExpired && !isLinkSent && (
        <ExpiredLink
          title="Your link has expired"
          onResend={sendResendLink}
          description="Your reset link has expired after 24 hours or has already been used. To reset your password, please enter your email."
        />
      )}

      {!isLinkExpired && !isLinkSent && (
        <AuthCard
          title="Reset password"
          borderImg={catImg}
          borderImgMob={catImgMob}
          borderImgClassName="h-[140px] -mb-5 lg:absolute  lg:bottom-0 lg:h-auto lg:translate-x-0 lg:left-auto lg:-top-[5rem] lg:-right-[3.5rem] "
        >
          <PasswordCard isRegisterPage={false} onSubmit={resetPassword} />
        </AuthCard>
      )}
      {isLinkSent && (
        <ThankCard title="Success!" description={successMessage} isBackButton />
      )}
    </>
  );
}
