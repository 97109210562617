import {Field} from "formik";
import React from "react";


const FormikRadio = ({value, name, children}) => {
  return (
    <>
      <div className={'radio-wrap'}>
        <Field
          type="radio"
          name={name}
          value={value}
          className="radio-button"
        />
        <span className={'check-mark'}/>
      </div>
      <span>{children}</span>
    </>

  )
}

export default FormikRadio