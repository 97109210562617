import {Route, Routes} from "react-router-dom";
import {PROFILE_ACCOUNT_INFORMATION_ROUTES} from "./config";
import React from "react";
import AccountInformation from "./pages/AccountInformation";


export default function ProfileAccountInformationRoutes() {
  return (
    <Routes>
      <Route path={PROFILE_ACCOUNT_INFORMATION_ROUTES.BASE} element={<AccountInformation />} />
    </Routes>
  );
}