import React, {useCallback, useEffect, useState} from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { Tabs } from "../../base/components/Tabs";

import EssentialLearning from "./pages/EssentialLearning";

import { LEARN_GROUP_LINKS, LEARN_GROUP_ROUTES } from "./config";
import CustomLearning from "./pages/CustomLearning";
import CustomLearningMenu from "./pages/CustomLearningMenu";

const CUSTOM_LEARNING = "Custom Learning";
const ESSENTIAL_LEARNING = "Essential Learning";

const LEARN_TABS = [
  {
    title: ESSENTIAL_LEARNING,
    link: LEARN_GROUP_LINKS.LINK_ESSENTIAL_LEARNING,
  },
  { title: CUSTOM_LEARNING, link: LEARN_GROUP_LINKS.LINK_CUSTOM_LEARNING },
];

const DEFAULT_TAB = LEARN_TABS[0];

export default function Learn() {
  const navigate = useNavigate();
  const currentLocation = location.pathname;

  const [activeTab, setTab] = useState("");

  const toggleTab = useCallback((tab) => {
    setTab(tab.title);
    navigate(tab.link);
  }, [navigate, setTab]);

  useEffect(() => {
    const initialTab = LEARN_TABS.find((tab) =>
      currentLocation.includes(tab.link)
    )

    if (!initialTab) {
      toggleTab(DEFAULT_TAB)
    } else {
      setTab(initialTab?.title)
    }
  }, [currentLocation])


  return (
    <>
      <Tabs
        tabsItems={LEARN_TABS}
        activeTab={activeTab}
        toggleTab={toggleTab}
      />

      <Routes>
        <Route
          path={LEARN_GROUP_ROUTES.LINK_ESSENTIAL_LEARNING}
          element={<EssentialLearning />}
        />
        <Route
          path={LEARN_GROUP_ROUTES.LINK_CUSTOM_LEARNING}
          element={<CustomLearningMenu />}
        />
        <Route
          path={LEARN_GROUP_ROUTES.LINK_CUSTOM_THEME}
          element={<CustomLearning />}
        />
      </Routes>
    </>
  );
}
