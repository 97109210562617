import React from "react";
import {GRAMMAR_GROUP_LINKS, GRAMMAR_GROUP_ROUTES} from "./config";
import {Navigate, Route, Routes} from "react-router-dom";
import GrammarLayout from "./components/GrammarLayout";
import ArticleEndings from "./pages/ArticleEndings";

import "./index.scss";
import Pronouns from "./pages/Pronouns";
import AdjectiveEndings from "./pages/AdjectiveEndings";
import VerbConjugations from "./pages/VerbConjugations";
import Prepositions from "./pages/Prepositions";

export default function GrammarRoutes() {
  const schema = [
    { title: "Article Endings", link: GRAMMAR_GROUP_LINKS.LINK_TO_ARTICLE_ENDINGS_PAGE },
    { title: "Pronouns", link: GRAMMAR_GROUP_LINKS.LINK_TO_PRONOUNS_PAGE },
    {
      title: "Adjective Endings",
      link: GRAMMAR_GROUP_LINKS.LINK_TO_ADJECTIVE_ENDINGS_PAGE,
    },
    { title: "Verb Conjugations", link: GRAMMAR_GROUP_LINKS.LINK_TO_VERB_CONJUGATIONS_PAGE },
    { title: "Prepositions", link: GRAMMAR_GROUP_LINKS.LINK_TO_PREPOSITIONS_PAGE },
  ];

  return <GrammarLayout schema={schema}>
    <Routes>
      <Route
        path="*"
        element={ <Navigate to={GRAMMAR_GROUP_LINKS.LINK_TO_ARTICLE_ENDINGS_PAGE} replace /> }
      />
      <Route
        index
        path={GRAMMAR_GROUP_ROUTES.LINK_TO_ARTICLE_ENDINGS_PAGE}
        element={ <ArticleEndings /> }
      />
      <Route
        path={GRAMMAR_GROUP_ROUTES.LINK_TO_PRONOUNS_PAGE}
        element={ <Pronouns /> }
      />
      <Route
        path={GRAMMAR_GROUP_ROUTES.LINK_TO_ADJECTIVE_ENDINGS_PAGE}
        element={ <AdjectiveEndings /> }
      />
      <Route
        path={GRAMMAR_GROUP_ROUTES.LINK_TO_VERB_CONJUGATIONS_PAGE}
        element={ <VerbConjugations /> }
      />
      <Route
        path={GRAMMAR_GROUP_ROUTES.LINK_TO_PREPOSITIONS_PAGE}
        element={ <Prepositions /> }
      />
    </Routes>
  </GrammarLayout>;
}
