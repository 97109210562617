import React, {useCallback, useState} from "react";

import GrammarHeader from "./GrammarHeader";
import GrammarSidebar from "./GrammarSidebar";
import ImageLightBox from "../../../base/components/ImagesLightBox";

const GrammarLayout = ({ children, schema }) => {

  const [openedImageSrc, setOpenedImageSrc] = useState("");

  const onImgClick = (event) => {
    if (event.target instanceof HTMLImageElement) {
      setOpenedImageSrc(event.target.src)
    }
  }

  const handleLightBoxClose = useCallback(() => setOpenedImageSrc(""), [setOpenedImageSrc])

  return (
    <section className="flex flex-col h-full">
      <GrammarHeader />
      <div className="flex flex-col h-full lg:mt-6 lg:flex-row overflow-y-hidden">
        <GrammarSidebar schema={schema} />
        <div
          className="h-full w-full lg:pl-[30px] overflow-y-auto custom-scrollbar"
          onClick={onImgClick}
        >
          {children}
        </div>
      </div>
      <ImageLightBox src={openedImageSrc} onClose={handleLightBoxClose}/>
    </section>
  )
}

export default GrammarLayout;