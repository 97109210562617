import React from "react";
import { Route, Routes } from "react-router-dom";

import EditProfile from "./pages/EditProfile";
import ChangeEmail from "./pages/ChangeEmail";
import ChangePassword from "./pages/ChangePassword";

import { PROFILE_INFO_ROUTES } from "./config";

export default function ProfileInfoRoutes() {
  return (
    <Routes>
      <Route path={PROFILE_INFO_ROUTES.BASE} element={<EditProfile />} />
      <Route
        path={PROFILE_INFO_ROUTES.LINK_TO_PROFILE_INFO_CHANGE_EMAIL}
        element={<ChangeEmail />}
      />
      <Route
        path={PROFILE_INFO_ROUTES.LINK_TO_PROFILE_INFO_CHANGE_PASSWORD}
        element={<ChangePassword />}
      />
    </Routes>
  );
}
