import React from "react";
import searchIcon from "../../../../../base/assets/search.svg";
import Icon from "../../../../../base/components/Icon";

export const Header = ({ sortIcon, onSortClick, onSearchChange }) => {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between px-4 lg:px-0 mt-[10px] lg:mt-[30px] lg:mb-[30px] ">
      <div className="flex justify-between items-center w-full lg:w-auto order-2 lg:order-1 mt-[10px] lg:mt-0">
        <h2 className="text-font text-xl font-bold leading-[44px]">
          Vocabulary List
        </h2>
        {sortIcon && (
          <div onClick={onSortClick}>
            <Icon icon={sortIcon} />
          </div>
        )}
      </div>
      <div className="relative order-1 lg:order-2 w-full lg:w-auto">
        <input
          onChange={onSearchChange}
          placeholder="Search list"
          className="vocabulary-search placeholder:text-sm placeholder:text-dark-gray relative z-10"
        />
        <img src={searchIcon} className="absolute left-[10px] top-2 z-10" alt={"Search"}/>
      </div>
    </div>
  );
};
