import * as yup from "yup";
import { USER_NAME } from "../../../../../../validation/user";
import {
  VALIDATION_ERROR_MESSAGE_FIRST_NAME_REQUIRED,
  VALIDATION_ERROR_MESSAGE_LAST_NAME_REQUIRED,
} from "../../../../../../base/constants/messages";

export const validationSchema = yup.object().shape({
  firstName: USER_NAME.required(VALIDATION_ERROR_MESSAGE_FIRST_NAME_REQUIRED),
  lastName: USER_NAME.required(VALIDATION_ERROR_MESSAGE_LAST_NAME_REQUIRED),
});
