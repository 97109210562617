import React, { useEffect } from "react";

import { useLoading } from "../../base/hooks/useLoading";

import { useCurrentUser } from "../../base/contexts/CurrentUser";

import Spinner from "../../base/components/Spinner";
import EssentialCard from "./components/EssentialCard";
import CustomCard from "./components/CustomCard";
import FlashcardsCard from "./components/FlashcardsCard";

const Dashboard = () => {
  const [isLoading] = useLoading();
  const { currentUser, fetchCurrentUser } = useCurrentUser();

  useEffect(() => fetchCurrentUser(), [fetchCurrentUser]);

  if (isLoading && !currentUser?.firstName)
    return <Spinner className={"spinner"} />;

  return (
    <section className="h-full relative flex flex-col">
      {currentUser?.firstName && (
        <div className="flex flex-col">
          <p className="mb-[10px] lg:mb-0 text-font lg:text-purple font-bold text-xl lg:text-base leading-[44px] lg:leading-normal">
            Welcome back {currentUser.firstName}!
          </p>
          <p className="hidden lg:block text-purple text-2xl font-bold">
            Let&apos;s get you started!
          </p>
        </div>
      )}
      <div className="lg:flex-auto  flex justify-center lg:items-center">
        <div className="w-full lg:w-auto grid gap-y-[10px] lg:gap-y-0 lg:grid-cols-3  lg:gap-x-5">
          <EssentialCard />
          <CustomCard />
          <FlashcardsCard />
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
