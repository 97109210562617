import Http from './Http'

class PaymentsService extends Http {
  static $displayName = "PaymentsService";

  async getUserCreditCard() {
    return await this.get(`/users/payment-methods`);
  }

  async updateUserCreditCard(values) {
    return await this.put(`/users/payment-methods`, values);
  }

  async deleteUserCreditCard(values) {
    return await this.delete(`/users/payment-methods`, values);
  }
}

export default PaymentsService;