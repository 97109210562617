import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import closeIcon from "../assets/close-outline.svg";
import hintIcon from "../../base/assets/bulb.svg";

export const ExerciseHeader = ({
  title,
  closeLink,
  onHintClick,
  isHint = true,
}) => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex justify-between items-center text-teal-dark lg:mt-10">
      <img
        className="cursor-pointer h-6 lg:h-[30px]"
        src={closeIcon}
        onClick={() => navigate(closeLink)}
      />
      <h2 className="text-font font-bold text-base lg:text-[21px] lg:leading-[38px] absolute top-[65px] left-0 lg:top-0 w-full text-center lg:relative">
        {title}
      </h2>
      {isHint && (
        <img
          className="cursor-pointer  h-6 lg:h-[30px]"
          src={hintIcon}
          role="button"
          onClick={onHintClick}
        />
      )}
    </div>
  );
};

ExerciseHeader.propTypes = {
  title: PropTypes.string,
  closeLink: PropTypes.string,
  onHintClick: PropTypes.func,
};
