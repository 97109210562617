import React, {useCallback, useState} from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import Button from "../../../../../../../../base/components/Button";
import CustomInput from "../../../../../../../../base/components/Input";
import { validateField } from './form';

import '../../../../../../../../assets/styles/components/stripe.css'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import CommonModal from "../../../../../../../../base/components/Modals/CommonModal";

const CARDHOLDER_FIELD_NAME = "cardHolderName";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const options = {
  style: {
    base: {
      color: "#383737",
    },
    invalid: {
      color: "#383737",
    }
  }
}

const ChangePaymentMethodForm = ({show, closeModal, onSubmit, paymentLoading, subscriptionsLoading}) => {
  const [loading, setLoading] = useState(false);
  const [stripeError, setStripeError] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const validateForm = useCallback(async (fieldArray) => {
    let errors = {};
    const validatingFields = [...fieldArray].filter(f => f)
    for (const item of validatingFields) {
      if (item?.name) {
        errors = { ...errors, ...(await validateField(item?.name, item?.value))}
      }
    }

    return errors
  },[])

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make  sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const card = elements.getElement(CardNumberElement);

      const result = await stripe.createToken(card,  { name: event.target?.[CARDHOLDER_FIELD_NAME].value });

      if (result?.error) {
        setStripeError(result?.error?.message);
        setLoading(false);
        return;
      }

      const validationResults = await validateForm([...event.target])
      if (Object.values(validationResults).filter(v => v).length) {
        setStripeError(Object.values(validationResults)?.[0]);
        setLoading(false);
        return;
      } else {
        setStripeError("")
      }

      onSubmit(result?.token?.id || "");

      setLoading(false);
    } catch (e) {
      setLoading(false);
    } finally {
      setLoading( false);
    }
  };

  return (
    <CommonModal
      show={show}
      initialState={{
        modalBodyWrapperClassName: "top-[10%] translate-y-[-10%] lg:top-0 lg:translate-y-0"
      }}
    >
      <form onSubmit={handleSubmit}>
        <h2 className="text-[21px] font-bold text-font mb-[8px]">Change your payment information</h2>
        <label className="label text-font font-bold leading-[29px]">Card number</label>
        <CardNumberElement className="mb-[20px]" options={options}/>
        <div id="Field-numberError"></div>
        <div className="flex flex-col lg:flex-row">
          <div className="w-full">
            <label className="label text-font font-bold leading-[29px]">Expiry date</label>
            <CardExpiryElement className="mb-[20px]" options={options} />
          </div>
          <div className="w-full lg:ml-[20px]">
            <label className="label text-font font-bold leading-[29px]">CVC / CVV</label>
            <CardCvcElement className="mb-[20px]" options={options} />
          </div>
        </div>
        <div className="flex flex-col stripe-input-wrapper">
          <label className="label text-font font-bold">Cardholder Name</label>
          <CustomInput
            name={CARDHOLDER_FIELD_NAME}
            className="input"
          />
        </div>

        {stripeError && <div className="error-input-msg mt-[20px]">{stripeError}</div>}

        <p className="text-font text-[16px] mt-[20px]">
          The payment method you choose will be used for all future payments.
          You will not be charged until your next payment is due.
        </p>

        <div className="flex flex-col-reverse lg:flex-row mt-[20px]">
          <Button
            className="btn-back h-[40px] mr-[10px] w-full text-base rounded-[10px] mt-[10px] lg:mt-0"
            onClick={closeModal}
            disabled={loading || paymentLoading || subscriptionsLoading}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="btn-purple h-[40px] w-full lg:ml-[10px] text-base rounded-[10px]"
            disabled={loading || paymentLoading || subscriptionsLoading}
          >
            Save
          </Button>
        </div>
      </form>
    </CommonModal>
  )
}

ChangePaymentMethodForm.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  paymentLoading: PropTypes.bool,
  subscriptionsLoading: PropTypes.bool,
}

const mapStateToProps = ({ Payments: { loading }, Subscriptions: { loading: subscriptionsLoading } }) => ({
  paymentLoading: loading,
  subscriptionsLoading
})

const ChangePaymentMethodFormRedux = connect(mapStateToProps,{})(ChangePaymentMethodForm)

const ChangePaymentMethodModal = ({show, closeModal, onSubmit }) => {

  return (
    <Elements options={{ locale: "en" }} stripe={stripePromise}>
      <ChangePaymentMethodFormRedux
        show={show}
        closeModal={closeModal}
        onSubmit={onSubmit}
      />
    </Elements>
  )
}

ChangePaymentMethodModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ChangePaymentMethodModal;