import React from "react";
import { Route, Routes } from "react-router-dom";

import { PROFILE_GROUP_LINKS, PROFILE_GROUP_ROUTES } from "./config";
import { AUTH_GROUP_LINKS } from "../auth/config";

import ProfileLayout from "./components/ProfleLayout";
import ProfileInfoRoutes from "./pages/ProfileInfo";
import ProfileAccountInformationRoutes from "./pages/ProfileAccountInformation";
import ProfileQuestionnaireRoutes from "./pages/ProfileQuestionnaire";

export default function ProfileRoutes() {
  const schema = [
    { title: "Profile", link: PROFILE_GROUP_LINKS.LINK_TO_PROFILE_INFO_PAGE },
    {
      title: "Account Information",
      link: PROFILE_GROUP_LINKS.LINK_TO_ACCOUNT_INFORMATION,
    },
    { title: "Questionnaire", link: PROFILE_GROUP_LINKS.LINK_TO_QUESTIONNAIRE },
    { title: "Sign out", link: AUTH_GROUP_LINKS.LINK_LOG_OUT },
  ];

  return (
    <ProfileLayout schema={schema}>
      <Routes>
        <Route index element={<ProfileInfoRoutes />} />
        <Route
          path={PROFILE_GROUP_ROUTES.LINK_TO_PROFILE_INFO_PAGE}
          element={<ProfileInfoRoutes />}
        />
        <Route
          path={PROFILE_GROUP_ROUTES.LINK_TO_ACCOUNT_INFORMATION}
          element={<ProfileAccountInformationRoutes />}
        />
        <Route
          path={PROFILE_GROUP_ROUTES.LINK_TO_QUESTIONNAIRE}
          element={<ProfileQuestionnaireRoutes />}
        />
      </Routes>
    </ProfileLayout>
  );
}
