import * as React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { useState } from "react";

const SidebarLinkItem = ({
  link,
  isExternalLink,
  title,
  count,
  iconItem,
  isActive,
  onClick,
}) => {
  const [isItemOnHover, setIsItemOnHover] = useState(false);

  return (
    <li
      key={link}
      onMouseEnter={() => setIsItemOnHover(true)}
      onMouseLeave={() => setIsItemOnHover(false)}
      onClick={() => onClick(link, isExternalLink)}
      className={cn(
        {
          ["sidebar-item-active"]: isActive,
        },
        "sidebar-item sidebar-item-hover transition transition-colors "
      )}
    >
      <div className="flex items-center">
        <div className="sidebar-item-icon">{iconItem}</div>
        <span className="sidebar-item-title">{title}</span>
        {count && <span className="sidebar-item-count-label ">{count}</span>}
        {isItemOnHover && <div className=" sidebar-item-tooltip ">{title}</div>}
      </div>
    </li>
  );
};

SidebarLinkItem.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.number,
  isActive: PropTypes.bool,
  iconItem: PropTypes.any,
};

export default SidebarLinkItem;
