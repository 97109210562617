import {useService} from "../../../base/hooks/useService";
import LearnService from "../../../services/LearnService";
import {useLoading} from "../../../base/hooks/useLoading";
import {useToaster} from "../../../base/hooks/useToaster";
import {useCallback, useEffect, useRef, useState} from "react";

const LIMIT = 20;

export const useBookmarkLessons = (isEssential) => {
  const learnService = useService(LearnService);
  const [isLoading, { registerPromise }] = useLoading();
  const { displayToaster } = useToaster();

  const [lessons, setLessons] = useState({});

  const lessonsRef = useRef(lessons);

  const fetchNextLessons = useCallback(() => {
    const { data: currentData, pagination: currentPagination } = lessonsRef.current;
    registerPromise(
      learnService.getBookmarkedLessons(LIMIT, currentPagination?.nextOffset || 0, { isEssential })
    ).then(({ data, pagination }) => {
      const favoriteData = data.filter(item => item.isFavorite);

      setLessons(() => ({
        data: [...(currentData || []), ...favoriteData],
        pagination,
      }));
    }).catch((error) => {
      displayToaster(error.message);
    });
  }, [registerPromise, learnService]);

  const onClickBookmark = useCallback((id, isFavorite) => {
    return registerPromise(
      learnService.setComplete(id, {
        userLesson: {
          isFavorite: !isFavorite,
        }
      })
    )
  },[learnService])

  const onBookmark = useCallback((id, isFavorite) => {
    onClickBookmark(id, isFavorite).then(() => {
      setLessons((prev) => ({...prev, data: prev?.data?.filter((item) => item.id !== id)}));
    })
  },[setLessons])

  useEffect(() => {
    lessonsRef.current = lessons;
  }, [lessons]);

  return [ isLoading, lessons, fetchNextLessons, onBookmark ]

}