import React from "react";
import Button from "../Button";
import PropTypes from "prop-types";
import CommonModal from "./CommonModal";

const DialogModal = ({
  children,
  isOpen,
  onCancel,
  onSubmit,
  initialState,
}) => {

  const {
    title = "",
    submitButtonName = "Submit",
    submitDisabled = false,
    submitButtonClassName = "btn-purple h-[40px] m-auto w-full text-base rounded-[10px]",
    cancelButtonName = "Cancel",
    cancelDisabled = false,
    cancelButtonClassName = "btn-back h-[40px] mr-0 w-full text-base rounded-[10px] lg:mr-[20px] mt-[10px] lg:mt-0",
  } = initialState || {};

  return (
    <CommonModal
      show={isOpen}
    >
      <h2 className="text-[28px] font-bold text-center text-font mb-2">{title && title}</h2>
      {children}
      <div className="flex flex-col-reverse lg:flex-row justify-center mt-5">
        <Button
          className={cancelButtonClassName}
          onClick={onCancel}
          disabled={cancelDisabled}
        >
          {cancelButtonName}
        </Button>
        <Button
          className={submitButtonClassName}
          onClick={onSubmit}
          disabled={submitDisabled}
        >
          {submitButtonName}
        </Button>
      </div>
    </CommonModal>
  )
}

DialogModal.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  initialState: PropTypes.object,
  children: PropTypes.any,
}

export default DialogModal;