import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";

import Table from "../../../../base/components/Table";
import Spinner from "../../../../base/components/Spinner";
import { InfoHint } from "./components/InfoHint";
import { Header } from "./components/Header";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import useMediaQuery from "../../../../base/hooks/useMediaQuery";

import WordsService from "../../../../services/WordsService";

import favoriteFillIcon from "../../../../base/assets/favorite-fill.svg";
import favoriteOutlineIcon from "../../../../base/assets/favorite-outline.svg";
import infoIcon from "../../../../base/assets/info.svg";

import { STAGES_MAP } from "../../../../base/constants/stages";
import {
  DEFAULT_SORT,
  DEFAULT_SORT_ICON,
  SORT_ICONS,
  SORT_SWITCHER,
} from "../../../../base/constants/sorting";

import { ESSENTIAL_TAB, SAVED_TAB } from "../../index";
import debounce from "lodash.debounce";
import { NoSearchResults } from "./components/NoSearchResults";

const LIMIT = 30;
const INITIAL_OFFSET = 0;
const DEFAULT_MOBILE_SORT_ACCESSOR = "word";
const TIME_TO_DEBOUNCE = 600;

export default function WordList({ tab }) {
  /**
   * @type {WordsService}
   */
  const wordsService = useService(WordsService);
  const [isLoading, { registerPromise }] = useLoading();
  const [isLoadingFavorite, { registerPromise: registerFavorite }] =
    useLoading();
  const [isLoadingMore, { registerPromise: registerLoadMore }] = useLoading();
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const [words, setWords] = useState({
    data: [],
    pagination: {},
    sort: {},
  });

  const [search, setSearch] = useState("");

  const [isStageInfoDisplayed, setIsStageInfoDisplayed] = useState(false);

  const hasMore = (words) =>
    words?.data?.length < words?.pagination?.totalCount;

  const getFavoriteIcon = (id, isFavorite) => {
    return (
      <img
        className="cursor-pointer"
        onClick={() => onFavoriteClick(id, isFavorite)}
        src={isFavorite ? favoriteFillIcon : favoriteOutlineIcon}
      />
    );
  };

  const getSortDirection = useCallback(
    (accessor) =>
      words.sort.accessor === accessor
        ? words.sort.sortDirection
        : DEFAULT_SORT,
    [words]
  );

  const columns = [
    {
      Header: "German",
      accessor: "word",
      sort: true,
      maxWidth: 330,
      sortDirection: getSortDirection("word"),
    },
    {
      Header: "English",
      accessor: "translation",
      sort: true,
      maxWidth: 330,
      sortDirection: getSortDirection("translation"),
    },
    {
      Header: <span className="w-full block text-center">Lesson </span>,
      accessor: "lessonName",
      sort: true,
      maxWidth: 180,
      sortDirection: getSortDirection("lessonName"),
      Cell: ({ value }) => (
        <span className="w-full block text-center truncate max-w-[147px]">
          {value}
        </span>
      ),
    },

    {
      Header: (
        <span className="inline flex  justify-between items-center relative">
          <img
            src={infoIcon}
            className="cursor-pointer mr-1"
            onClick={() => setIsStageInfoDisplayed((prevState) => !prevState)}
          />
          <span className="text-center">Your stage</span>
          <InfoHint isOpen={isStageInfoDisplayed} />
        </span>
      ),
      maxWidth: 180,
      sort: true,
      accessor: "stage",
      sortDirection: getSortDirection("stage"),
      Cell: ({ value }) => (
        <span className="w-full block text-center">{STAGES_MAP[value]}</span>
      ),
    },
    {
      Header: " ",
      accessor: "isFavorite",
      maxWidth: 64,
      Cell: ({ row: { original } }) => (
        <div className=" flex justify-center w-[30px]">
          {getFavoriteIcon(original.id, original.isFavorite)}
        </div>
      ),
    },
  ];

  const removeFromFavoriteTab = useCallback(
    (state, wordId) => {
      const filteredWords = state.filter((word) => word.id !== wordId);
      setWords((prevState) => ({
        pagination: {
          nextOffset: prevState.pagination.nextOffset - 1,
          totalCount: prevState.pagination.totalCount - 1,
          ...prevState.pagination,
        },
        data: filteredWords,
        sort: { ...prevState.sort },
      }));
    },
    [words]
  );

  const setFavorite = (wordId, isFavorite) => {
    let newState = words.data.map((word) => {
      if (word.id === wordId) {
        return {
          ...word,
          isFavorite: !isFavorite,
        };
      }

      return word;
    });
    if (tab === SAVED_TAB) {
      removeFromFavoriteTab(newState, wordId);
    } else {
      setWords((prevState) => ({ ...prevState, data: newState }));
    }
  };

  const onFavoriteClick = useCallback(
    (id, isFavorite) => {
      registerFavorite(
        wordsService.setFavorite(id, {
          userFlashcard: { isFavorite: !isFavorite },
        })
      ).then(() => setFavorite(id, isFavorite));
    },
    [registerFavorite, wordsService, words]
  );

  const columnHeaderClick = useCallback(
    (column) => {
      fetchWords(
        INITIAL_OFFSET,
        column.id,
        SORT_SWITCHER[column.sortDirection],
        search
      );
    },
    [registerPromise, wordsService, words, search]
  );

  const queryRequestParams = useCallback(
    (
      offset,
      orderBy = undefined,
      orderType = undefined,
      search = undefined
    ) => {
      const isEssential = tab === ESSENTIAL_TAB;
      const isFavorite = tab === SAVED_TAB ? true : undefined;
      return {
        limit: LIMIT,
        offset,
        isEssential: tab === SAVED_TAB ? undefined : isEssential,
        isFavorite,
        orderBy: orderBy ? orderBy : undefined,
        orderType: orderType ? orderType : undefined,
        search,
        byStage: false,
        isCompleted: true,
      };
    },
    [words]
  );

  const fetchWords = useCallback(
    (
      offset,
      orderBy = undefined,
      orderType = undefined,
      search = undefined
    ) => {
      registerPromise(
        wordsService.getWords(
          queryRequestParams(offset, orderBy, orderType, search)
        )
      ).then(({ data, pagination }) => {
        setWords(() => ({
          data,
          pagination,
          sort: {
            sortDirection: orderType || "",
            accessor: orderBy || "",
          },
        }));
      });
    },
    [registerPromise, wordsService, words]
  );

  const fetchNextWords = useCallback(() => {
    registerLoadMore(
      wordsService.getWords(
        queryRequestParams(
          words.pagination.nextOffset,
          words.sort.accessor,
          words.sort.sortDirection,
          search
        )
      )
    ).then(({ data, pagination }) => {
      setWords((prevState) => ({
        data: [...prevState.data, ...data],
        pagination,
        sort: { ...prevState.sort },
      }));
    });
  }, [registerLoadMore, wordsService, words]);

  const debounceSearch = useCallback(
    debounce(
      (search) => fetchWords(INITIAL_OFFSET, undefined, undefined, search),
      TIME_TO_DEBOUNCE
    ),
    []
  );

  useEffect(() => {
    fetchWords(INITIAL_OFFSET);
  }, []);

  return (
    <div
      className="overflow-y-auto h-full -mx-4 lg:mx-0 xl:-mr-12 pb-5"
      id="scrollableDiv"
    >
      <InfiniteScroll
        dataLength={words.data.length}
        next={fetchNextWords}
        hasMore={hasMore(words)}
        scrollableTarget="scrollableDiv"
      >
        {isDesktop && (
          <>
            <Table
              columns={columns}
              data={words.data}
              loading={isLoading}
              HeaderComponent={Header}
              onSearchChange={({ target: { value } }) => {
                setSearch(value);
                debounceSearch(value);
              }}
              onHeaderClick={columnHeaderClick}
              className="table"
            />
          </>
        )}
        {!isDesktop && (
          <>
            <Header
              sortIcon={
                words.sort.sortDirection
                  ? SORT_ICONS[words.sort.sortDirection]
                  : DEFAULT_SORT_ICON
              }
              onSortClick={() =>
                fetchWords(
                  INITIAL_OFFSET,
                  DEFAULT_MOBILE_SORT_ACCESSOR,
                  SORT_SWITCHER[words.sort.sortDirection || DEFAULT_SORT],
                  search
                )
              }
              onSearchChange={({ target: { value } }) => {
                setSearch(value);
                debounceSearch(value);
              }}
            />
            {isLoading ? (
              <Spinner
                className={
                  "spinner absolute left-0 top-0 w-full h-screen flex items-center justify-center"
                }
              />
            ) : (
              words.data.map(({ word, translation, isFavorite, id }) => (
                <div className="" key={id}>
                  <div className="bg-light-purple px-4 h-[34px] flex justify-between items-center">
                    <div className="mr-2  text-ellipsis overflow-ellipsis whitespace-nowrap overflow-hidden ">
                      {word}
                    </div>
                    {getFavoriteIcon(id, isFavorite)}
                  </div>
                  <div className="pl-8 pr-[46px] h-[34px] flex justify-between items-center">
                    <div className=" text-ellipsis overflow-ellipsis whitespace-nowrap overflow-hidden ">
                      {translation}
                    </div>
                  </div>
                </div>
              ))
            )}
          </>
        )}
        {!isLoading && search && !words.data.length && <NoSearchResults />}
      </InfiniteScroll>
    </div>
  );
}

WordList.PropTypes = {
  tab: PropTypes.string,
};
