import React from "react";
import PropTypes from "prop-types";
import { Tab } from "./components/Tab";

export const Tabs = ({ tabsItems, toggleTab, activeTab, tabClassname }) => {
  return (
    <div className="grid relative z-10 grid-flow-col  box-content  rounded-[15px] h-[30px] lg:h-10 lg:w-[max-content] lg:flex">
      {tabsItems.map((tab, index) => {
        return (
          <Tab
            tab={tab}
            key={index}
            toggleTab={toggleTab}
            activeTab={activeTab}
            className={tabClassname}
          />
        );
      })}
      <div className="absolute w-full h-full left-0 top-0 border border-purple  rounded-[15px] z-1"></div>
    </div>
  );
};

Tabs.propTypes = {
  tabsItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
  toggleTab: PropTypes.func,
  tabClassname: PropTypes.string,
  activeTab: PropTypes.string,
};
