import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { LessonCard } from "../../components/LessonCard";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";

import LearnService from "../../../../services/LearnService";
import InfiniteScroll from "react-infinite-scroll-component";
import { LessonGroup } from "../../components/LessonCard/LessonGroup";
import Spinner from "../../../../base/components/Spinner";
import AccessRestricted from "../../../../base/components/AccessRestricted";
import { updateObjectInArray } from "../helpers/updateObjectInArray";
import SubscriptionsService from "../../../../services/SubscriptionsService";
import { checkSubscription } from "../helpers/checkSubscription";
import useMediaQuery from "../../../../base/hooks/useMediaQuery";
import getSpaceBelow from "../../../../base/helpers/getSpaceBelow";

const LIMIT = 40;
const GROUP_SIZE = 10;
const ESSENTIAL_CATEGORY = 1;
const DESKTOP_BREAKPOINT = 1024;

export default function EssentialLearning() {
  /**
   * @type {LearnService}
   */
  const learnService = useService(LearnService);
  const subscriptionsService = useService(SubscriptionsService);
  const [isLoading, { registerPromise }] = useLoading();
  const [isLoadingSubscription , { registerPromise: registerPromiseSubscription }] = useLoading();

  const [lessons, setLessons] = useState([]);
  const [nextLesson, setNextLesson] = useState({});
  const [isSubscribed, setIsSubscribed] = useState(null)

  const isDesktop = useMediaQuery(`(min-width: ${DESKTOP_BREAKPOINT}px)`);
  const scrollableDiv = isDesktop ? "scrollableDiv" : ""
  const scrollRef = useRef();

  const lessonsRef = useRef(lessons);

  const groups = useMemo(() => {
    const res = [];
    for (let i = 0; i < lessons.data?.length; i += GROUP_SIZE) {
      const group = lessons.data?.slice(i, i + GROUP_SIZE);
      res.push(group);
    }
    return res;
  }, [lessons]);

  const hasMore = (lessons) =>
    lessons?.data?.length < lessons?.pagination?.totalCount;

  const fetchSubscription = useCallback(() => {
    registerPromiseSubscription(
      subscriptionsService.getSubscription().then((data) => {
        setIsSubscribed(checkSubscription(data?.status, data?.expiresAt));
      }).catch(() => setIsSubscribed(false))
    )
  },[subscriptionsService, setIsSubscribed])

  const fetchNextLessons = useCallback(() => {
    const { data: currentData, pagination: currentPagination } = lessonsRef.current;
    registerPromise(
      learnService.getLessons(LIMIT, currentPagination?.nextOffset || 0, { categoryId: ESSENTIAL_CATEGORY })
    ).then(({ data, pagination }) => {
      setLessons(() => ({
        data: [...(currentData || []), ...data],
        pagination,
      }));
    });
  }, [registerPromise, learnService]);

  const fetchNextLesson = useCallback(() => {
    registerPromise(learnService.getNextLesson({ categoryId: ESSENTIAL_CATEGORY })).then(({ data }) =>
      setNextLesson(data[0])
    );
  }, [registerPromise, learnService]);

  const onClickBookmark = useCallback((id, isFavorite) => {
    return registerPromise(
      learnService.setComplete(id, {
        userLesson: {
          isFavorite: !isFavorite,
        }
      })
    )
  },[learnService])

  const onBookmarkUpdate = useCallback((id, isFavorite) => {
    onClickBookmark(id, isFavorite).then(() => {
      setLessons((prev => {
        const { data } = prev;
        const oldElement = data.find(item => item.id === id);
        const newElement = { ...oldElement, isFavorite: !isFavorite }
        return {...prev, data: [...updateObjectInArray('id', id, newElement, data)]}
      }))
      setNextLesson((prev) => {
        if (id === prev.id) {
          return {...prev, isFavorite: !isFavorite}
        } else {
          return prev
        }
      })
    })
  },[setLessons, onClickBookmark])

  useEffect(() => {
    lessonsRef.current = lessons;
  });

  useEffect(() => {
    const toFewLessons =
      hasMore(lessons)
      && !isLoading
      && !isDesktop
      && getSpaceBelow(scrollRef)

    if (toFewLessons) {
      fetchNextLessons();
    }
  },[groups])

  useEffect(() => {
    fetchSubscription();
    fetchNextLessons();
    fetchNextLesson();
  }, [fetchSubscription, fetchNextLessons, fetchNextLesson]);

  const isLessonAllowed = Boolean(isSubscribed) || (groups?.[0]?.some((item) => item?.id === nextLesson?.id));

  const isLessonsNotLoaded = isLoading && !lessons?.data?.length;
  const isSubscriptionNotLoaded = isLoadingSubscription || isSubscribed === null;

  if (isLessonsNotLoaded || isSubscriptionNotLoaded)
    return <Spinner className={"spinner"} />;

  return (
    <div
      className="overflow-y-auto h-full py-[10px] -mx-4 px-4 lg:mx-0 lg:px-0"
      id="scrollableDiv"
    >
      {lessons?.data?.length && (
        <>
          {nextLesson?.id && (
            <>
              <h4 className="text-xl leading-[44px] mb-[5px]">Next lesson</h4>
              <LessonCard
                lesson={nextLesson}
                isDarkStyle
                isNextLesson
                isStartAllowed={isLessonAllowed}
                onBookmark={(id, isFavorite) =>
                  onClickBookmark(
                    id,
                    isFavorite
                  )
                    .then(() => {
                      setNextLesson(prev => ({...prev, isFavorite: !isFavorite}))
                      setLessons((prev) => {
                        const { data } = prev;
                        const oldElement = data.find(item => item.id === id);
                        const newElement = { ...oldElement, isFavorite: !isFavorite }
                        return {...prev, data: [...updateObjectInArray('id', id, newElement, data)]}
                      })
                    })}
              />
              <hr className="h-[1px] bg-dark-gray w-full mt-6 border-0 hidden lg:block" />
            </>
          )}
          {!isSubscribed && (
            <AccessRestricted
              subtitle="Subscribe to Cognate to achieve your personal language learning goals!"
              visibleElements={
                <LessonGroup
                  lessons={groups?.[0]}
                  title={`Lesson ${1} - ${0 + groups?.[0].length}`}
                  onBookmark={onBookmarkUpdate}
                />
              }
            >
              {groups.slice(1).map((lessonsGroup, index) => (
                <LessonGroup
                  key={index}
                  lessons={lessonsGroup}
                  title={`Lesson ${GROUP_SIZE * index + 1} - ${GROUP_SIZE * index + lessonsGroup.length}`}
                  onBookmark={onBookmarkUpdate}
                />
              ))}
            </AccessRestricted>
          )}
          {isSubscribed && (
            <div ref={scrollRef}>
              <InfiniteScroll
                dataLength={lessons.data?.length}
                next={fetchNextLessons}
                hasMore={hasMore(lessons)}
                className="-mx-4 px-4 pb-4 lg:mx-0 lg:px-0 lg:pb-0"
                scrollableTarget={scrollableDiv}
                initialScrollY="0px"
              >
                <>
                  {groups.map((lessonsGroup, index) => (
                    <LessonGroup
                      key={index}
                      lessons={lessonsGroup}
                      title={`Lesson ${GROUP_SIZE * index + 1} - ${GROUP_SIZE * index + lessonsGroup.length}`}
                      onBookmark={onBookmarkUpdate}
                    />
                  ))}
                </>
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </div>
  );
}
