import { 
  ALLOWED_EXTENTIONS_FILE, 
  ERROR_ALLOWED_EXTENTION_MESSAGE, 
  ERROR_SIZE_MESSAGE, 
  MAX_FILE_SIZE 
} from "../constants/dropzoneConstants";

import { isAllowedExtension } from "./isAllowedExtension";

export const validateFile = (file) => {
  if (file?.size > MAX_FILE_SIZE) {
    throw new Error(ERROR_SIZE_MESSAGE);
  }

  if(!isAllowedExtension(file, ALLOWED_EXTENTIONS_FILE)) {
    throw new Error(ERROR_ALLOWED_EXTENTION_MESSAGE);
  }

  return null;
};
