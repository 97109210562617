import React, { useCallback, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import ErrorBoundary from "../../base/components/ErrorBoundary";
import AppLayout from "../../base/components/AppLayout";

import Learn from "../learn";
import { LEARN_GROUP_LINKS, LEARN_GROUP_ROUTES } from "../learn/config";

import Practice from "../practice";
import {
  PRACTICE_GROUP_LINKS,
  PRACTICE_GROUP_ROUTES,
} from "../practice/config";

import Bookmarks from "../bookmarks";
import {
  BOOKMARKS_GROUP_LINKS,
  BOOKMARKS_GROUP_ROUTES,
} from "../bookmarks/config";

import Grammar from "../grammar";
import { GRAMMAR_GROUP_LINKS, GRAMMAR_GROUP_ROUTES } from "../grammar/config";

import Vocabulary from "../vocabulary";
import {
  VOCABULARY_GROUP_LINKS,
  VOCABULARY_GROUP_ROUTES,
} from "../vocabulary/config";

import Notifications from "../notifications";
import {
  NOTIFICATIONS_GROUP_LINKS,
  NOTIFICATIONS_GROUP_ROUTES,
} from "../notifications/config";

import Support from "../support";
import { SUPPORT_GROUP_LINKS, SUPPORT_GROUP_ROUTES } from "../support/config";

import { APP_GROUP_LINKS, APP_GROUP_ROUTES } from "./config";

import {
  BookmarkIcon,
  GrammarIcon,
  HomeIcon,
  LearnIcon,
  NotificationIcon,
  PracticeIcon,
  SupportIcon,
  VocabularyIcon,
} from "../../assets/images/nav-icons";
import Dashboard from "../dashboard";

import { PROFILE_GROUP_ROUTES } from "../profile/config";
import ProfileRoutes from "../profile";
import { LESSON_DETAILS_ROUTES } from "../lesson/config";
import LessonDetails from "../lesson";
import { CUSTOM_QUESTIONNAIRE_GROUP_ROUTES } from "../customQuestionnaire/config";
import Questionnaire from "../../base/components/Questionnaire";
import CustomQuestionnairePage from "../customQuestionnaire";

import { AUTH_GROUP_LINKS } from "../auth/config";
import StorageService from "../../services/StorageService";
import { useService } from "../../base/hooks/useService";
import ProfileService from "../../services/ProfileService";
const FAQ_LINK = "https://cognatelanguages.com/faq.html";

const AppRoutes = () => {
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  const navigate = useNavigate();

  const fetchCurrentUser = useCallback(() => {
    profileService.getProfileInfo().catch(() => {
      storage.clear();
      navigate(AUTH_GROUP_LINKS.LINK_LOGIN);
    });
  }, [profileService, storage, navigate]);

  useEffect(() => {
    fetchCurrentUser();
  }, [fetchCurrentUser, location.pathname]);

  const schema = {
    main: [
      {
        label: "Menu",
        children: [
          {
            label: "Home",
            linkTo: APP_GROUP_LINKS.DASHBOARD,
            iconClassname: <HomeIcon />,
          },
          {
            label: "Learn",
            linkTo: LEARN_GROUP_LINKS.BASE,
            iconClassname: <LearnIcon fill="#000" />,
          },
          {
            label: "Practice",
            linkTo: PRACTICE_GROUP_LINKS.BASE,
            iconClassname: <PracticeIcon />,
          },
        ],
      },
      {
        label: "Library",
        children: [
          {
            label: "Bookmarked Lessons",
            linkTo: BOOKMARKS_GROUP_LINKS.BASE,
            iconClassname: <BookmarkIcon />,
          },
          {
            label: "Grammar",
            linkTo: GRAMMAR_GROUP_LINKS.BASE,
            iconClassname: <GrammarIcon />,
          },
          {
            label: "Vocabulary list",
            linkTo: VOCABULARY_GROUP_LINKS.BASE,
            iconClassname: <VocabularyIcon />,
          },
        ],
      },
    ],
    footer: [
      {
        children: [
          {
            label: "Support",
            linkTo: FAQ_LINK,
            iconClassname: <SupportIcon />,
            isExternal: true,
          },
        ],
      },
    ],
  };

  return (
    <AppLayout schema={schema}>
      <ErrorBoundary>
        <Routes>
          <Route path={APP_GROUP_ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={LEARN_GROUP_ROUTES.BASE} element={<Learn />} />
          <Route path={PRACTICE_GROUP_ROUTES.BASE} element={<Practice />} />
          <Route path={BOOKMARKS_GROUP_ROUTES.BASE} element={<Bookmarks />} />
          <Route path={GRAMMAR_GROUP_ROUTES.BASE} element={<Grammar />} />
          <Route path={VOCABULARY_GROUP_ROUTES.BASE} element={<Vocabulary />} />
          <Route
            path={NOTIFICATIONS_GROUP_ROUTES.BASE}
            element={<Notifications />}
          />
          <Route path={SUPPORT_GROUP_ROUTES.BASE} element={<Support />} />
          <Route path={PROFILE_GROUP_ROUTES.BASE} element={<ProfileRoutes />} />
          <Route
            path={LESSON_DETAILS_ROUTES.BASE}
            element={<LessonDetails />}
          />
          <Route
            path={CUSTOM_QUESTIONNAIRE_GROUP_ROUTES.BASE}
            element={<CustomQuestionnairePage />}
          />
          <Route
            path="*"
            element={<Navigate to={APP_GROUP_LINKS.DASHBOARD} replace />}
          />
        </Routes>
      </ErrorBoundary>
    </AppLayout>
  );
};

export default AppRoutes;
