import {
  CUSTOM_QUESTIONNAIRE_BASE_PARAMS,
  CUSTOM_QUESTIONNAIRE_GROUP_LINKS
} from "../../../../customQuestionnaire/config";
import {getQuizIdByLink} from "./getQuizIdByLink";
import {LEARN_GROUP_LINKS} from "../../../config";

export const getCardLink = (link, quizArray) => {
  const quizId = getQuizIdByLink(link);
  if (quizArray.find((item) => item.quizId === quizId)?.isQuizCompleted) {
    return {
      link: `${LEARN_GROUP_LINKS.LINK_CUSTOM_LEARNING}/${link}`,
      replace: false,
    };
  } else {
    return {
      link: `${CUSTOM_QUESTIONNAIRE_GROUP_LINKS.BASE}/${quizId}?${CUSTOM_QUESTIONNAIRE_BASE_PARAMS.REDIRECT}=${LEARN_GROUP_LINKS.LINK_CUSTOM_LEARNING}/${link}`,
      replace: true,
    }
  }
}