import React, { useRef } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

export const Modal = ({ isOpen, children, maxWidthClassname }) => {
  return (
    <div
      id="popup-modal"
      tabIndex="-1"
      className={twMerge(
        `${
          isOpen ? "block" : "hidden"
        } overflow-y-auto overflow-x-hidden bg-gray-transparent fixed top-0 right-0 left-0 z-50 inset-0 h-full`
      )}
    >
      <div className="w-full h-full flex items-center justify-center">
        <div
          className={twMerge(
            `relative  ${maxWidthClassname} h-auto px-4 lg:px-0`
          )}
        >
          <div className=" relative bg-white rounded-[15px] shadow-card">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  maxWidthClassname: PropTypes.string,
};
