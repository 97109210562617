import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_CLEAR,
  GET_TRANSACTIONS_ERROR,
  GET_TRANSACTIONS_SUCCESS
} from "./actionTypes";

export const getTransactions = ({ limit, offset }) => {
  return {
    payload: { data: { limit, offset } },
    type: GET_TRANSACTIONS,
  }
}

export const getTransactionsSuccess = (data) => {
  return {
    payload: { data },
    type: GET_TRANSACTIONS_SUCCESS,
  };
};

export const getTransactionsError = ({ message }) => {
  return {
    payload: { message },
    type: GET_TRANSACTIONS_ERROR,
  };
};

export const getTransactionsClear = () => {
  return {
    type: GET_TRANSACTIONS_CLEAR,
  };
};
