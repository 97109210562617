const LINK_TO_VOCABULARY_ROOT = `/app/vocabulary`;
const LINK_TO_ESSENTIAL_VOCABULARY_LEARNING = `${LINK_TO_VOCABULARY_ROOT}/essential`;
const LINK_TO_CUSTOM_VOCABULARY_LEARNING = `${LINK_TO_VOCABULARY_ROOT}/custom`;
const LINK_TO_SAVED_VOCABULARY_LEARNING = `${LINK_TO_VOCABULARY_ROOT}/saved`;

export const VOCABULARY_GROUP_LINKS = {
  BASE: LINK_TO_VOCABULARY_ROOT,
  LINK_TO_ESSENTIAL_VOCABULARY_LEARNING,
  LINK_TO_CUSTOM_VOCABULARY_LEARNING,
  LINK_TO_SAVED_VOCABULARY_LEARNING,
};

export const VOCABULARY_GROUP_ROUTES = {
  BASE: "/vocabulary/*",
  LINK_TO_ESSENTIAL_VOCABULARY_LEARNING: "/essential",
  LINK_TO_CUSTOM_VOCABULARY_LEARNING: "/custom",
  LINK_TO_SAVED_VOCABULARY_LEARNING: "/saved",
};
