import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";

import Actions from "./components/Actions";
import Answer from "./components/Answer";

import { validationSchema } from "./form";
import { answerType, questionType } from "../../types";

const DEFAULT_TYPE_ID = 1;
const MULTIPLE_TYPE_ID = 2;

const ANSWER_TYPES = {
  [DEFAULT_TYPE_ID]: "radio",
  [MULTIPLE_TYPE_ID]: "checkbox",
};

export default function Question({
  currentQuestionIndex,
  currentQuestion,
  onNextClick,
  onSaveClick,
  onBackClick,
}) {
  const getAnswerByIndex = (id) =>
    currentQuestion.answers.find((answer) => answer.id === id);

  const getAnswerValues = (answers) => {
    if (Array.isArray(answers)) {
      return answers;
    }
    return answers ? [answers] : undefined;
  };

  const getInitialValues = (answers) => {
    if (currentQuestion.answerType === DEFAULT_TYPE_ID) {
      return getAnswerByIndex(answers[0]?.answerId)?.title || "";
    }
    return answers.map(({ answerId }) => getAnswerByIndex(answerId)?.title);
  };

  return (
    <>
      <p className="leading-loose text-xs text-font mt-2 lg:mt-8 lg:leading-normal lg:text-base lg:ml-[88px]">
        {currentQuestionIndex + 1}. {currentQuestion.title}
      </p>
      <Formik
        onSubmit={(values, { resetForm }) => resetForm()}
        validationSchema={validationSchema}
        initialValues={{
          answer: currentQuestion.userAnswers
            ? getInitialValues(currentQuestion.userAnswers)
            : "",
        }}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="flex-auto  flex flex-col  lg:ml-[88px] mt-4"
          >
            {currentQuestion.answers.map((answer, index) => (
              <Answer
                key={index}
                title={answer.title}
                id={answer.id}
                type={ANSWER_TYPES[currentQuestion.answerType]}
              />
            ))}
            <Actions
              currentQuestion={currentQuestion}
              currentQuestionIndex={currentQuestionIndex}
              answerValues={getAnswerValues(props.values.answer)}
              onNextClick={onNextClick}
              onSaveClick={onSaveClick}
              onBackClick={onBackClick}
            />
          </form>
        )}
      </Formik>
    </>
  );
}

Question.propTypes = {
  currentQuestionIndex: PropTypes.number,
  currentQuestion: questionType,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
  onSaveClick: PropTypes.func,
};
