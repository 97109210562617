import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { Tabs } from "../../base/components/Tabs";
import { changeSidebarType } from "../../store/layout/actions";
import { SIDEBAR_TYPES } from "../../base/constants/appearance";
import { useDispatch } from "react-redux";
import { useService } from "../../base/hooks/useService";
import LearnService from "../../services/LearnService";
import { useLoading } from "../../base/hooks/useLoading";
import closeIcon from "../../base/assets/close-outline.svg";
import { LEARN_GROUP_LINKS } from "../learn/config";
import { LESSON_DETAILS_LINKS, LESSON_DETAILS_PARAMS } from "./config";
import Vocabulary from "./components/Vocabulary";
import Content from "./components/Content";
import ExtraInfo from "./components/ExtraInfo";
import Spinner from "../../base/components/Spinner";
import checkAccessError from "./helpers/checkAccessError";

const CONTENT = "Lesson Content";
const VOCABULARY = "Vocabulary";
const EXTRA_INFO = "Extra Info";

export default function LessonDetails() {
  /**
   * @type {LearnService}
   */
  const learnService = useService(LearnService);
  const [isLoading, { registerPromise }] = useLoading();

  const navigate = useNavigate();
  const { lessonId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const currentLocation = location.pathname;
  const onCloseLink =
    searchParams.get(LESSON_DETAILS_PARAMS.REDIRECT) || LEARN_GROUP_LINKS.BASE;

  const [lesson, setLesson] = useState({});
  const LESSON_DETAILS_TABS = [
    {
      title: VOCABULARY,
      link: `${LESSON_DETAILS_LINKS.BASE}/${lessonId}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_VOCABULARY}`,
    },
    {
      title: CONTENT,
      link: `${LESSON_DETAILS_LINKS.BASE}/${lessonId}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_CONTENT}`,
    },
    {
      title: EXTRA_INFO,
      link: `${LESSON_DETAILS_LINKS.BASE}/${lessonId}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_EXTRA_INFO}`,
    },
  ];

  const initialTab = useMemo(
    () =>
      LESSON_DETAILS_TABS.find((tab) => currentLocation.includes(tab.link))
        ?.title,
    [lessonId, currentLocation]
  );

  const toggleTab = (tab) => {
    navigate(
      tab.link +
        (onCloseLink ? `?${LESSON_DETAILS_PARAMS.REDIRECT}=${onCloseLink}` : "")
    );
  };

  const onCompleteClick = useCallback(() => {
    registerPromise(
      learnService.setComplete(lesson?.id, {
        userLesson: { isCompleted: !lesson?.isCompleted },
      })
    ).then(() =>
      setLesson((prevState) => ({
        ...prevState,
        isCompleted: !prevState.isCompleted,
      }))
    );
  }, [registerPromise, learnService, lesson]);

  const redirectIfNoAccess = useCallback((data) => {
    const link = checkAccessError(data);
    if (link) {
      navigate(link);
    } else {
      return data
    }
  }, [navigate])

  useEffect(() => {
    dispatch(changeSidebarType(SIDEBAR_TYPES.REMOVED));
    return () => {
      dispatch(changeSidebarType(SIDEBAR_TYPES.DEFAULT));
    };
  }, []);

  useEffect(() => {
    if (lessonId) {
      registerPromise(learnService.getLesson(lessonId))
        .then(setLesson)
        .catch(redirectIfNoAccess);
    }
  }, [registerPromise, learnService, lessonId]);

  if (isLoading && !lesson.lessonNumber)
    return <Spinner className={"spinner"} />;

  return (
    <div className="h-full flex flex-col justify-between">
      {!isLoading && (
        <>
          <div className="flex flex-col ">
            <div className="flex items-center justify-between mb-2">
              <h2
                className="text-purple text-xl font-bold leading-[44px]"
                onClick={() => console.log(lesson)}
              >
                {lesson?.title}
              </h2>
              <img
                className="cursor-pointer"
                src={closeIcon}
                alt="close"
                onClick={() => navigate(onCloseLink)}
              />
            </div>
            <Tabs
              tabsItems={LESSON_DETAILS_TABS}
              activeTab={initialTab}
              toggleTab={toggleTab}
            />
          </div>
          <Routes>
            <Route
              path={"/content"}
              element={
                <Content lesson={lesson} onCompleteClick={onCompleteClick} />
              }
            />
            <Route path={"/vocabulary"} element={<Vocabulary isCompletedRequire={false} />} />
            <Route
              path={"/extra-info"}
              element={
                <ExtraInfo lesson={lesson} onCompleteClick={onCompleteClick} />
              }
            />
          </Routes>
        </>
      )}
    </div>
  );
}
