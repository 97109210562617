import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { useService } from "../../../base/hooks/useService";
import { useLoading } from "../../../base/hooks/useLoading";

import LearnService from "../../../services/LearnService";
import QuestionnaireService from "../../../services/QuestionnaireService";

import Card from "./Card";
import LessonDescription from "./Card/components/LessonDescription";
import LockedButton from "./Card/components/LockedButton";
import Spinner from "../../../base/components/Spinner";

import catStudy from "../assets/cat-study.svg";
import catQuestioned from "../assets/cat-questioned.svg";

import { LESSON_DETAILS_LINKS } from "../../lesson/config";
import { CUSTOM_QUESTIONNAIRE_GROUP_LINKS } from "../../customQuestionnaire/config";

import {
  CATEGORIES_BY_QUIZZES_MAP,
  QUIZ_ID_1,
  QUIZ_ID_2,
  QUIZ_ID_3,
} from "../../../base/constants/customLessons";
import { SUBSCRIPTION_ACTIVE_STATUS_ID } from "../../../base/constants/subscriptions";

import { PROFILE_GROUP_LINKS } from "../../profile/config";

import {
  getSubscription,
  getSubscriptionClear,
} from "../../../store/user/Subscriptions/actions";

import { getPrioritizedLessons } from "../helpers/getPrioritizedLessons";
import { getNextTopic } from "../helpers/getNextTopic";
import { getQuizById } from "../helpers/getQuizById";
import { getNextQuizId } from "../helpers/getNextQuizId";
import { getNextLesson } from "../helpers/getNextLesson";
import { twMerge } from "tailwind-merge";

const CustomCard = ({ subscriptionsState, getSubscription }) => {
  /**
   * @type {LearnService}
   */
  const learnService = useService(LearnService);

  /**
   * @type {QuestionnaireService}
   */
  const questionnaireService = useService(QuestionnaireService);

  const [isLoading, { registerPromise }] = useLoading();

  const { updated, currentSubscription } = subscriptionsState;

  const [quizzes, setQuizzes] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [lessonsTopic, setLessonsTopic] = useState([]);

  const isUserSubscribed =
    !!currentSubscription?.durationType &&
    currentSubscription?.status === SUBSCRIPTION_ACTIVE_STATUS_ID;

  const prioritizedQuizzes = [
    getQuizById(quizzes, QUIZ_ID_2),
    getQuizById(quizzes, QUIZ_ID_3),
    getQuizById(quizzes, QUIZ_ID_1),
  ];
  const prioritizedLessons = getPrioritizedLessons(lessons);

  const nextQuizId = getNextQuizId(prioritizedQuizzes)?.quizId;
  const nextTopic = isUserSubscribed
    ? getNextTopic(prioritizedLessons)
    : prioritizedLessons.find((lesson) =>
        lesson.lessons.find((lesson) => !lesson.isCompleted)
      );
  const nextLesson = getNextLesson(
    isUserSubscribed ? lessonsTopic : nextTopic?.lessons
  );
  const nextUnavailableLessonForUnsubscribed = getNextLesson(lessonsTopic);

  const isQuizCompletedForNextLesson =
    nextTopic?.categoryId < CATEGORIES_BY_QUIZZES_MAP[nextQuizId];
  const isTakeQuestionnaireShown =
    (!isQuizCompletedForNextLesson || !nextTopic) && nextQuizId;

  const fetchLessonsFromNextTopic = useCallback(
    (categoryId, topicId) => {
      registerPromise(
        learnService.getLessons(100, 0, { categoryId, topicId })
      ).then(({ data }) => {
        setLessonsTopic(data);
      });
    },
    [registerPromise, learnService]
  );

  const fetchNextCustomLesson = useCallback(() => {
    registerPromise(learnService.getLessonsTopics()).then(({ data }) => {
      const prioritizedLessons = getPrioritizedLessons(data);
      const nextTopic = getNextTopic(prioritizedLessons);
      if (nextTopic?.id && nextTopic?.categoryId) {
        fetchLessonsFromNextTopic(nextTopic.categoryId, nextTopic.id);
      }
      setLessons(data);
    });
  }, [registerPromise, learnService]);

  const fetchQuizzes = useCallback(() => {
    registerPromise(
      questionnaireService.getQuizzes({
        limit: 100,
        offset: 0,
      })
    ).then(({ data }) => {
      setQuizzes(data);
    });
  }, [registerPromise, questionnaireService]);

  useEffect(() => {
    fetchQuizzes();
    fetchNextCustomLesson();
    getSubscription();
  }, [fetchQuizzes, fetchNextCustomLesson, getSubscription]);

  if (isLoading && !nextTopic && !nextLesson)
    return <Spinner className={"spinner"} />;

  return (
    <Card title="Custom Learning">
      {(isQuizCompletedForNextLesson || !nextQuizId) && nextLesson && (
        <LessonDescription
          name={nextLesson.name}
          description={nextLesson.description}
          title={nextLesson.title}
          link={`${LESSON_DETAILS_LINKS.BASE}/${nextLesson.id}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_VOCABULARY}`}
        />
      )}
      {isUserSubscribed &&
        (isQuizCompletedForNextLesson || !nextQuizId) &&
        !nextLesson && (
          <p className="flex text-sm font-light mt-2 text-center flex-auto  justify-center items-center">
            You completed all lessons
          </p>
        )}
      {isTakeQuestionnaireShown && (
        <>
          <p className="hidden lg:flex text-sm font-light mt-2 text-center flex-auto  justify-center items-center">
            You need to fill out the questionnaire to unlock this!
          </p>
          <LockedButton
            isSubscribeBtn={false}
            text="Take Questionnaire"
            link={`${CUSTOM_QUESTIONNAIRE_GROUP_LINKS.BASE}/${nextQuizId}`}
          />
        </>
      )}
      {!isUserSubscribed &&
        !nextQuizId &&
        !nextLesson &&
        nextUnavailableLessonForUnsubscribed && (
          <>
            <LessonDescription
              name={nextUnavailableLessonForUnsubscribed.name}
              description={nextUnavailableLessonForUnsubscribed.description}
              title={nextUnavailableLessonForUnsubscribed.title}
              link={`${LESSON_DETAILS_LINKS.BASE}/${nextUnavailableLessonForUnsubscribed.id}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_VOCABULARY}`}
              isAvailable={false}
            />
            <LockedButton
              text="Subscribe to proceed"
              link={PROFILE_GROUP_LINKS.LINK_TO_ACCOUNT_INFORMATION}
            />
          </>
        )}
      <img
        src={isTakeQuestionnaireShown ? catQuestioned : catStudy}
        className={twMerge(
          `hidden lg:block   lg:absolute lg:h-auto lg:-top-[154px] lg:left-1/2 ${
            isTakeQuestionnaireShown ? "-translate-x-1/3" : "-translate-x-1/2"
          }`
        )}
      />
    </Card>
  );
};

const mapStateToProps = ({ Subscriptions }) => ({
  subscriptionsState: Subscriptions,
});

export default connect(mapStateToProps, {
  getSubscription,
  getSubscriptionClear,
})(CustomCard);
