import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const LinkCard = ({ children, link, replace = false }) => {
  return (
    <Link
      to={link}
      replace={replace}
      className="bg-80% bg-lesson-category  text-center bg-purple bg-blend-multiply bg-right bg-no-repeat  rounded-[17px]  lg:bg-auto hover:opacity-90"
    >
      {children}
    </Link>
  );
};

LinkCard.propTypes = {
  children: PropTypes.any,
  link: PropTypes.string,
  replace: PropTypes.bool,
};

export default LinkCard;
