import React from "react";
import { Toaster } from "react-hot-toast";

import ProfileHeader from "./ProfileHeader";
import ProfileSidebar from "./ProfileSidebar";

const ProfileLayout = ({ children, schema }) => {
  return (
    <>
      <section className="flex flex-col h-full">
        <ProfileHeader />
        <div className="flex flex-col h-full lg:mt-6 lg:flex-row overflow-y-auto">
          <ProfileSidebar schema={schema} />
          <div className="h-full w-full lg:pl-[88px]">{children}</div>
        </div>
      </section>
      <Toaster />
    </>
  );
};

export default ProfileLayout;
