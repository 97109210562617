import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../../../../../../../base/components/Button";
import { formatDate } from "../../../../../../../../base/helpers/formatDate";
import { connect } from "react-redux";
import {
  deleteCreditCard,
  deleteCreditCardClear,
  getCreditCard,
  getCreditCardClear,
  updateCreditCard,
  updateCreditCardClear,
} from "../../../../../../../../store/user/Payments/actions";
import ChangePaymentMethodModal from "../ChangePaymentMethodModal";
import InfoModal from "../../../../../../../../base/components/Modals/InfoModal";
import ChangeSubscriptionModal from "../ChangeSubscriptionModal";
import {
  buySubscription,
  buySubscriptionClear,
  getSubscription,
  updateSubscription,
} from "../../../../../../../../store/user/Subscriptions/actions";
import DialogModal from "../../../../../../../../base/components/Modals/DialogModal";
import {
  SUBSCRIPTION_FREE_TRIAL_TYPE_ID,
  SUBSCRIPTIONS_DISPLAY_PROPERTIES,
} from "../../../../../../../../base/constants/subscriptions";

const PAYMENT_METHOD_FIELD = "Payment method:";
const CARD_NUMBER_FIELD = "Credit Index number:";
const CARD_HIDDEN_DIGITS = "**** **** **** ";
const EXPIRATION_DATE_FIELD = "Expiration Date:";
const NAME_OF_CARDHOLDER = "Name of cardholder:";

const PaymentInformation = ({
  paymentsState,
  subscriptionsState,
  getCreditCard,
  getCreditCardClear,
  updateCreditCard,
  updateCreditCardClear,
  buySubscriptionClear,
  buySubscription,
  updateSubscription,
  deleteCreditCard,
  deleteCreditCardClear,
  getSubscription,
}) => {
  const [isShowChangeModal, setIsShowChangeModal] = useState(false);
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [isShowChangeSubModal, setIsShowChangeSubModal] = useState(false);
  const [isShowSuccessSubModal, setIsShowSuccessSubModal] = useState(false);
  const [isShowDeleteCardModal, setIsShowDeleteCardModal] = useState(false);
  const [isFirstBuy, setIsFirstBuy] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const {
    paymentMethod,
    loading: loadingPayments,
    updated: updatedPayments,
  } = paymentsState;
  const {
    loading: loadingSubscriptions,
    updated: updatedSubscriptions,
    currentSubscription,
  } = subscriptionsState;
  const {
    brand = "",
    lastFourDigits = "",
    expirationDate = "",
    cardholderName = "",
  } = paymentMethod || {};

  const { durationType = "none" } = currentSubscription || {};

  useEffect(() => {
    getCreditCard();
    return () => getCreditCardClear();
  }, []);

  const handleSubmitChange = (token) => {
    updateCreditCard({ data: token });
  };

  const handleSubmitSubscriptionSelect = (id) => {
    setSelectedSubscription(id);
  };

  const handleCloseSubscriptionSelect = () => {
    setIsShowChangeSubModal(false);
    setIsFirstBuy(false);
    setSelectedSubscription(null);
  };

  const handleCloseChangePayment = () => {
    setIsShowChangeModal(false);
    if (isFirstBuy) setIsFirstBuy(false);
    if (selectedSubscription) setSelectedSubscription(null);
  };

  const onClickChooseSubscription = () => {
    setIsFirstBuy(true);
    setIsShowChangeSubModal(true);
  };

  const handleDeleteCard = useCallback(() => {
    deleteCreditCard();
  }, []);

  const onCloseSuccessModal = useCallback(() => {
    updateCreditCardClear();
    setIsShowSuccessModal(false);
  }, []);

  const onCloseSuccessSubModal = useCallback(() => {
    updateCreditCardClear();
    buySubscriptionClear();
    setIsShowSuccessSubModal(false);
    setIsFirstBuy(false);
  }, []);

  useEffect(() => {
    const paymentMethodChanged =
      !loadingPayments &&
      updatedPayments &&
      paymentMethod &&
      !loadingSubscriptions &&
      !updatedSubscriptions &&
      !isFirstBuy;

    const cardDeleted =
      !loadingPayments &&
      updatedPayments &&
      !paymentMethod &&
      !loadingSubscriptions &&
      !updatedSubscriptions &&
      !isFirstBuy;

    const subscriptionPurchased =
      !loadingPayments &&
      !loadingSubscriptions &&
      updatedPayments &&
      updatedSubscriptions &&
      isFirstBuy;

    const subscriptionSelected =
      !loadingPayments &&
      !loadingSubscriptions &&
      selectedSubscription &&
      !updatedSubscriptions &&
      isFirstBuy;

    const paymentMethodAddedDuringSubscriptionPurchase =
      !loadingPayments &&
      !loadingSubscriptions &&
      updatedPayments &&
      !updatedSubscriptions &&
      selectedSubscription &&
      isFirstBuy;

    if (paymentMethodChanged) {
      setIsShowChangeModal(false);
      setIsShowSuccessModal(true);
    }
    if (cardDeleted) {
      setIsShowDeleteCardModal(false);
      deleteCreditCardClear();
      getSubscription();
    }
    if (subscriptionPurchased) {
      setIsShowChangeModal(false);
      setIsShowSuccessSubModal(true);
    }
    if (subscriptionSelected) {
      setIsShowChangeSubModal(false);
      setIsShowChangeModal(true);
    }
    if (paymentMethodAddedDuringSubscriptionPurchase) {
      setIsShowChangeModal(false);
      if (String(durationType) === String(SUBSCRIPTION_FREE_TRIAL_TYPE_ID)) {
        updateSubscription(selectedSubscription);
      } else {
        buySubscription(selectedSubscription);
      }
    }
  }, [
    updatedSubscriptions,
    updatedPayments,
    loadingSubscriptions,
    loadingPayments,
    selectedSubscription,
  ]);

  return SUBSCRIPTIONS_DISPLAY_PROPERTIES?.[durationType].paymentMethod ? (
    <div className="mt-4 order-2">
      <h2 className="text-[21px] font-bold text-font">Payment Information</h2>
      {paymentMethod ? (
        <div className="text-font font-light text-sm lg:text-base">
          <div className="grid grid-cols-2 mt-2.5">
            <p>{PAYMENT_METHOD_FIELD}</p>
            <p className="ml-2.5">{brand}</p>
          </div>
          <div className="grid grid-cols-2 mt-2.5">
            <p>{CARD_NUMBER_FIELD}</p>
            <p className="ml-2.5">{CARD_HIDDEN_DIGITS + lastFourDigits}</p>
          </div>
          <div className="grid grid-cols-2 mt-2.5">
            <p>{EXPIRATION_DATE_FIELD}</p>
            <p className="ml-2.5">{formatDate(expirationDate)}</p>
          </div>
          <div className="grid grid-cols-2 mt-2.5">
            <p>{NAME_OF_CARDHOLDER}</p>
            <p className="ml-2.5">{cardholderName}</p>
          </div>

          <div className="flex flex-col lg:flex-row mt-5">
            <Button
              onClick={() => setIsShowChangeModal(true)}
              className="btn-purple h-10 w-full lg:mr-2.5 text-base rounded-[10px]"
            >
              Change
            </Button>
            <Button
              className="btn-back h-10 w-full text-base rounded-[10px] mt-2.5 lg:mr-5 lg:mt-0"
              onClick={() => setIsShowDeleteCardModal(true)}
            >
              Delete
            </Button>
          </div>
        </div>
      ) : !currentSubscription ||
        String(durationType) === String(SUBSCRIPTION_FREE_TRIAL_TYPE_ID) ? (
        <Button
          className="btn-purple h-[40px] w-full lg:w-[190px] text-base rounded-[10px] mt-[30px] lg:mt-2.5"
          onClick={onClickChooseSubscription}
        >
          Choose subscription
        </Button>
      ) : (
        <Button
          className="btn-purple h-[40px] w-full lg:w-[190px] text-base rounded-[10px] mt-[30px] lg:mt-2.5"
          onClick={() => setIsShowChangeModal(true)}
        >
          Add Card
        </Button>
      )}
      <ChangePaymentMethodModal
        closeModal={handleCloseChangePayment}
        show={!loadingPayments && isShowChangeModal}
        onSubmit={handleSubmitChange}
      />
      <InfoModal
        isOpen={isShowSuccessModal}
        closeModal={onCloseSuccessModal}
        initialState={{
          title: "Success",
          buttonName: "Close",
        }}
      >
        <p className="text-font text-base font-bold text-center">
          From now on, these details will be used for any payments to Cognate.
        </p>
      </InfoModal>
      <ChangeSubscriptionModal
        show={isShowChangeSubModal}
        closeModal={handleCloseSubscriptionSelect}
        onSubmit={handleSubmitSubscriptionSelect}
      />
      <InfoModal
        isOpen={isShowSuccessSubModal}
        closeModal={onCloseSuccessSubModal}
        initialState={{
          title: "Success",
          buttonName: "Close",
        }}
      >
        <p className="text-font text-base font-bold text-center">
          You have successfully signed up to Cognate. Welcome!
        </p>
      </InfoModal>
      <DialogModal
        isOpen={!loadingPayments && isShowDeleteCardModal}
        onCancel={handleDeleteCard}
        onSubmit={() => setIsShowDeleteCardModal(false)}
        size="xl"
        initialState={{
          cancelButtonName: "Delete",
          submitButtonName: "Cancel",
          submitButtonClassName:
            "btn-purple h-[40px] m-auto w-full text-base rounded-[10px] order-1 mt-2.5 lg:mt-0",
          cancelButtonClassName:
            "btn-back h-[40px] mr-0 w-full text-base rounded-[10px] lg:mr-5 order-2 lg:order-1 ",
          title: "Delete payment method",
          submitDisabled: loadingPayments,
          cancelDisabled: loadingPayments,
        }}
      >
        <p className="text-font text-center font-bold text-base leading-[20px] ">
          Are you sure you want to delete your payment method?
        </p>
        <p className=" text-font text-center font-light text-base mt-2.5">
          By deleting your payment method your subscription won&#39;t be renewed
          at the end of your billing cycle.
        </p>
      </DialogModal>
    </div>
  ) : (
    ""
  );
};

PaymentInformation.propTypes = {
  paymentsState: PropTypes.object,
  subscriptionsState: PropTypes.object,
  getCreditCard: PropTypes.func,
  getCreditCardClear: PropTypes.func,
  updateCreditCard: PropTypes.func,
  updateCreditCardClear: PropTypes.func,
  buySubscriptionClear: PropTypes.func,
  buySubscription: PropTypes.func,
  deleteCreditCard: PropTypes.func,
  deleteCreditCardClear: PropTypes.func,
  getSubscription: PropTypes.func,
};

const mapStateToProps = ({ Payments, Subscriptions }) => ({
  paymentsState: Payments,
  subscriptionsState: Subscriptions,
});

export default connect(mapStateToProps, {
  getCreditCard,
  getCreditCardClear,
  updateCreditCard,
  updateCreditCardClear,
  deleteCreditCard,
  deleteCreditCardClear,
  buySubscriptionClear,
  buySubscription,
  getSubscription,
  updateSubscription,
})(PaymentInformation);
