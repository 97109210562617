import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_CLEAR,
  GET_TRANSACTIONS_ERROR,
  GET_TRANSACTIONS_SUCCESS
} from "./actionTypes";


const initialState = {
  userTransactions: [],
  pagination: {},
  loading: false,
  error: ""
}

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRANSACTIONS:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case GET_TRANSACTIONS_SUCCESS:
      state = {
        ...state,
        ...action.payload.data,
        loading: false,
      }
      break;
    case GET_TRANSACTIONS_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case GET_TRANSACTIONS_CLEAR:
      state = {
        ...state,
        userTransactions: [],
        pagination: {},
      };
      break;
    default:
      state = { ...state };
  }

  return state;
}