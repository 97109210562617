import React from "react";

import PaymentInformation from "./components/PaymentInformation";
import BillingHistory from "./components/BillingHistory";
import CurrentSubscription from "./components/CurrentSubscription";
import { Toaster } from "react-hot-toast";
import Spinner from "../../../../../../base/components/Spinner";
import {connect} from "react-redux";
import joinClassNames from "../../../../../../base/helpers/joinClassNames";

const AccountInformation = ({
  paymentsLoading,
  subscriptionsLoading,
  transactionsLoading
}) => {

  const isLoading = paymentsLoading || subscriptionsLoading || transactionsLoading;

  return (
    <>
      <Spinner className={joinClassNames(
        "spinner",
        !isLoading && "hidden"
      )} />
      <section className={joinClassNames(
        "mt-2",
        isLoading && "hidden"
      )}>
        <h2 className="text-[21px] font-bold text-font">Account Information</h2>
        <div className="grid lg:grid-cols-[336px_336px] min-h-[243px] gap-x-[90px] ">
          <CurrentSubscription />
          <PaymentInformation />
        </div>
        <div className="w-full lg:max-w-[336px] mt-[50px]">
          <BillingHistory />
        </div>
        <Toaster />
      </section>
    </>

  )
}

const mapStateToProps = ({
  Payments: { loading: paymentsLoading },
  Subscriptions: { loading: subscriptionsLoading },
  Transactions: { loading: transactionsLoading },
}) => ({
  paymentsLoading,
  subscriptionsLoading,
  transactionsLoading
})

export default connect(mapStateToProps, {})(AccountInformation);