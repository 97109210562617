import {SUBSCRIPTION_ACTIVE_STATUS_ID, SUBSCRIPTION_CANCELED_STATUS_ID} from "../../../../base/constants/subscriptions";
import moment from "moment";

export const checkSubscription = (id, date) => {
  if (!id) return false

  if (id === SUBSCRIPTION_ACTIVE_STATUS_ID) return true;

  if ((id === SUBSCRIPTION_CANCELED_STATUS_ID) && moment(date).isValid() ) return moment().isBefore(date)

  return false
}