import Http from "./Http";

class SentencesService extends Http {
  static $displayName = "SentencesService";

  async getSentences(values) {
    return await this.get(`/sentences`, {
      params: values,
    });
  }

  async setRemember(id, values) {
    return await this.post(`/sentences/${id}`, values);
  }
}

export default SentencesService;
