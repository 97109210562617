import * as yup from "yup";

import { USER_EMAIL } from "../../../../validation/user";
import { VALIDATION_ERROR_MESSAGE_EMAIL_REQUIRED } from "../../../../base/constants/messages";

export const validationSchema = yup.object().shape({
  email: USER_EMAIL.required(VALIDATION_ERROR_MESSAGE_EMAIL_REQUIRED),
});

export const initialValues = {
  email: "",
};
