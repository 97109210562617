import {
  ACTIVATE_SUBSCRIPTION,
  ACTIVATE_SUBSCRIPTION_CLEAR,
  ACTIVATE_SUBSCRIPTION_ERROR,
  ACTIVATE_SUBSCRIPTION_SUCCESS,
  BUY_SUBSCRIPTION,
  BUY_SUBSCRIPTION_CLEAR,
  BUY_SUBSCRIPTION_ERROR,
  BUY_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_CLEAR,
  CANCEL_SUBSCRIPTION_ERROR,
  CANCEL_SUBSCRIPTION_SUCCESS,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_CLEAR,
  GET_SUBSCRIPTION_ERROR,
  GET_SUBSCRIPTION_SUCCESS,
  SUBSCRIPTION_ERROR_CLEAR,
  UPDATE_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION_CLEAR,
  UPDATE_SUBSCRIPTION_ERROR,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "./actionTypes";

const initialState = {
  currentSubscription: null,
  updated: false,
  canceled: false,
  activated: false,
  loading: false,
  error: "",
}

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case GET_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case GET_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        currentSubscription: action.payload.data,
        loading: false,
      }
      break;
    case GET_SUBSCRIPTION_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case GET_SUBSCRIPTION_CLEAR:
      state = {
        ...state,
        currentSubscription: null,
        loading: false,
        error: "",
      };
      break;
    case BUY_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case BUY_SUBSCRIPTION_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case BUY_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        currentSubscription: action.payload.data,
        loading: false,
        updated: true,
      };
      break;
    case BUY_SUBSCRIPTION_CLEAR:
      state = {
        ...state,
        updated: false,
      };
      break;
    case SUBSCRIPTION_ERROR_CLEAR:
      state = {
        ...state,
        error: "",
      };
      break;
    case UPDATE_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case UPDATE_SUBSCRIPTION_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case UPDATE_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        currentSubscription: action.payload.data,
        loading: false,
        updated: true,
      };
      break;
    case UPDATE_SUBSCRIPTION_CLEAR:
      state = {
        ...state,
        updated: false,
      };
      break;
    case CANCEL_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case CANCEL_SUBSCRIPTION_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case CANCEL_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        currentSubscription: action.payload.data,
        loading: false,
        canceled: true,
      };
      break;
    case CANCEL_SUBSCRIPTION_CLEAR:
      state = {
        ...state,
        canceled: false,
      };
      break;
    case ACTIVATE_SUBSCRIPTION:
      state = {
        ...state,
        loading: true,
        error: "",
      };
      break;
    case ACTIVATE_SUBSCRIPTION_ERROR:
      state = {
        ...state,
        error: action.payload.message,
        loading: false,
      };
      break;
    case ACTIVATE_SUBSCRIPTION_SUCCESS:
      state = {
        ...state,
        currentSubscription: action.payload.data,
        loading: false,
        activated: true,
      };
      break;
    case ACTIVATE_SUBSCRIPTION_CLEAR:
      state = {
        ...state,
        activated: false,
      };
      break;
    default:
      state = { ...state }
  }

  return state;
}
//CANCEL_SUBSCRIPTION