import * as React from "react";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const ProfileSidebar = ({ schema }) => {
  const location = useLocation();
  const currentLocation = location.pathname;

  const isActive = (link) => currentLocation.includes(link);

  return (
    <div className="flex flex-col pt-[5px] pb-[10px] border-b border-b-1 border-dark-gray lg:border-b-0 lg:py-0 lg:min-w-[184px] lg:border-r lg:border-r-1  lg:h-full">
      {schema.map((item) => (
        <Link to={item.link} key={item.link}>
          <li
            className={twMerge(
              ` list-none mt-[10px] text-base text-font ${
                isActive(item.link) && "text-purple font-bold"
              }`
            )}
          >
            <span>{item.title}</span>
          </li>
        </Link>
      ))}
    </div>
  );
};

ProfileSidebar.propTypes = {
  schema: PropTypes.any,
};

export default ProfileSidebar;
