const LINK_TO_LESSON_DETAILS_ROOT = `/app/lesson`;
const LINK_TO_LESSON_CONTENT = `/content`;
const LINK_TO_LESSON_VOCABULARY = `/vocabulary`;
const LINK_TO_LESSON_EXTRA_INFO = `/extra-info`;

export const LESSON_DETAILS_LINKS = {
  BASE: LINK_TO_LESSON_DETAILS_ROOT,
  LINK_TO_LESSON_CONTENT,
  LINK_TO_LESSON_VOCABULARY,
  LINK_TO_LESSON_EXTRA_INFO,
};

export const LESSON_DETAILS_ROUTES = {
  BASE: "/lesson/:lessonId/*",
  LINK_TO_LESSON_CONTENT: "/content",
  LINK_TO_LESSON_VOCABULARY: "/vocabulary",
  LINK_TO_LESSON_EXTRA_INFO: "/extra-info",
};

export const LESSON_DETAILS_PARAMS = {
  REDIRECT: 'redirect',
}