import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import AuthCard from "./AuthCard";
import Button from "../../../base/components/Button";

import happyCatImg from "../assets/happy-cat.svg";
import happyCatImgMob from "../assets/happy-cat-mob.svg";

import { AUTH_GROUP_LINKS } from "../config";

export default function ThankCard({
  title,
  description,
  buttonTitle,
  onClick,
  isBackButton,
  isResendButton,
}) {
  const navigate = useNavigate();

  return (
    <AuthCard
      title={title}
      isCenterMob
      borderImg={happyCatImg}
      borderImgMob={happyCatImgMob}
      borderImgClassName="h-[20vh] -translate-x-1/2 absolute -mb-1  -bottom-3 left-1/2 lg:bottom-0 lg:mb-0 lg:h-auto lg:-top-[90px] lg:left-1/2 "
    >
      <p className="text-sm mt-5 lg:mt-2 text-center lg:-mx-2">{description}</p>
      {isResendButton && (
        <Button
          type="submit"
          className="btn-purple w-full mt-10 lg:mt-5"
          onClick={onClick}
        >
          {buttonTitle}
        </Button>
      )}

      {isBackButton && (
        <Button
          type="submit"
          className="btn-back w-full mt-5"
          onClick={() => {
            navigate(AUTH_GROUP_LINKS.LINK_LOGIN);
          }}
        >
          Back to Login
        </Button>
      )}
    </AuthCard>
  );
}

ThankCard.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  onClick: PropTypes.func,
  isBackButton: PropTypes.bool,
  isResendButton: PropTypes.bool,
};
