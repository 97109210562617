import React from "react";
import Button from "../../../../../base/components/Button";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";

export default function LockedButton({ text, link, isSubscribeBtn = true }) {
  const navigate = useNavigate();

  return (
    <div
      className={twMerge(
        `  w-full flex flex-col ${
          isSubscribeBtn ? "flex-auto" : "flex-auto lg:flex-initial"
        }`
      )}
    >
      <Button
        className="btn-red bg-white lg:bg-red text-red lg:text-white leading-none h-10  lg:mx-4  text-base mt-auto"
        onClick={() => navigate(link)}
      >
        {text}
      </Button>
    </div>
  );
}
