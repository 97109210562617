import * as React from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";


import avatar from "../../../../assets/images/avatar.svg";

import { PROFILE_INFO_LINKS } from "../../../../groups/profile/pages/ProfileInfo/config";
import { changeSidebarType } from "../../../../store/layout/actions";
import { SIDEBAR_TYPES } from "../../../constants/appearance";
import { useCurrentUser } from "../../../contexts/CurrentUser";

const SidebarProfile = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { currentUser, fetchCurrentUser } = useCurrentUser();

  const onLinkClick = () => {
    if (isMobile) {
      dispatch(changeSidebarType(SIDEBAR_TYPES.DEFAULT, isMobile));
    }
  };

  useEffect(() => fetchCurrentUser(), []);

  return (
    <Link className="sidebar-profile "
          to={PROFILE_INFO_LINKS.BASE}
          onClick={onLinkClick}>
      <div className="sidebar-profile-img">
        <img src={avatar} alt={'Avatar'}/>
      </div>
      <div className="sidebar-profile-content">
        <div className="flex flex-col text-font mr-2">
          <p className=" font-bold overflow-hidden text-ellipsis lg:max-w-[160px]">
            {currentUser?.firstName} {currentUser?.lastName}
          </p>
          <div
            className="flex justify-between items-baseline text-purple"
          >
            <p className=" text-sm">Profile Settings</p>
            <BsChevronRight size={10} className="ml-9 fill-[currentColor]" />
          </div>
        </div>
      </div>
    </Link>
  );
};
SidebarProfile.propTypes = {
  isMobile: PropTypes.bool,
};
export default SidebarProfile;
