import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { useService } from "../../../base/hooks/useService";
import SubscriptionsService from "../../../services/SubscriptionsService";
import {
  activateSubscriptionError,
  activateSubscriptionSuccess,
  buySubscriptionError,
  buySubscriptionSuccess,
  cancelSubscriptionError,
  cancelSubscriptionSuccess,
  getSubscriptionError,
  getSubscriptionSuccess,
  updateSubscriptionError,
  updateSubscriptionSuccess
} from "./actions";
import {
  ACTIVATE_SUBSCRIPTION,
  BUY_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  GET_SUBSCRIPTION,
  UPDATE_SUBSCRIPTION
} from "./actionTypes";

const subscriptionsService = useService(SubscriptionsService);

const getSubscriptionAsync = async () => {
  return await subscriptionsService.getSubscription()
}

const buySubscriptionAsync = async (durationType) => {
  const values = { durationType }
  return await subscriptionsService.buySubscription(values)
}

const updateSubscriptionAsync = async (durationType) => {
  const values = { durationType }
  return await subscriptionsService.updateSubscription(values)
}

const cancelSubscriptionAsync = async (data) => {
  const values = { ...data }
  return await subscriptionsService.cancelSubscription(values)
}

const activateSubscriptionAsync = async () => {
  return await subscriptionsService.activateSubscription()
}

function* getSubscription() {
  try {
    const response = yield call(getSubscriptionAsync);
    yield put(getSubscriptionSuccess(response));
  } catch (error) {
    yield put(getSubscriptionError(error))
  }
}

function* buySubscription({ payload }) {
  try {
    const { data } = payload;
    const response = yield call(buySubscriptionAsync, data);
    yield put(buySubscriptionSuccess(response));
  } catch (error) {
    yield put(buySubscriptionError(error))
  }
}

function* updateSubscription({ payload }) {
  try {
    const { data } = payload;
    const response = yield call(updateSubscriptionAsync, data);
    yield put(updateSubscriptionSuccess(response));
  } catch (error) {
    yield put(updateSubscriptionError(error))
  }
}

function* cancelSubscription({ payload }) {
  try {
    const { data } = payload;
    const response = yield call(cancelSubscriptionAsync, data);
    yield put(cancelSubscriptionSuccess(response));
  } catch (error) {
    yield put(cancelSubscriptionError(error))
  }
}

function* activateSubscription() {
  try {
    const response = yield call(activateSubscriptionAsync);
    yield put(activateSubscriptionSuccess(response));
  } catch (error) {
    yield put(activateSubscriptionError(error))
  }
}

export function* watchGetSubscription() {
  yield takeEvery(GET_SUBSCRIPTION, getSubscription);
}

export function* watchBuySubscription() {
  yield takeEvery(BUY_SUBSCRIPTION, buySubscription);
}

export function* watchUpdateSubscription() {
  yield takeEvery(UPDATE_SUBSCRIPTION, updateSubscription);
}

export function* watchCancelSubscription() {
  yield takeEvery(CANCEL_SUBSCRIPTION, cancelSubscription);
}

export function* watchActivateSubscription() {
  yield takeEvery(ACTIVATE_SUBSCRIPTION, activateSubscription);
}

function* subscriptionsSaga() {
  yield all([
    fork(watchGetSubscription),
    fork(watchBuySubscription),
    fork(watchUpdateSubscription),
    fork(watchCancelSubscription),
    fork(watchActivateSubscription),
  ])
}

export default subscriptionsSaga;