import React from "react";
import PropTypes from "prop-types";

import Button from "../../Button";

export default function Actions({
  currentCardIndex,
  onNextClick,
  onBackClick,
  isCompleted,
}) {
  return (
    <div className="self-end grid lg:grid-flow-col gap-y-[10px] gap-x-2 w-full lg:w-auto ">
      {Boolean(currentCardIndex) && (
        <Button
          type="button"
          onClick={onBackClick}
          className="btn-back w-full lg:w-[190px] h-[40px] text-base"
        >
          Previous
        </Button>
      )}
      <Button
        onClick={onNextClick}
        className="btn-purple w-full row-[1] lg:row-auto lg:w-[190px] h-[40px] text-base "
      >
        {isCompleted ? "Go to Content" : "Next"}
      </Button>
    </div>
  );
}

Actions.propTypes = {
  currentCardIndex: PropTypes.number,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
  isCompleted: PropTypes.bool,
};
