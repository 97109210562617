import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import Button from "../../../../Button";
import { questionType } from "../../../types";

export default function Actions({
  currentQuestionIndex,
  currentQuestion,
  onNextClick,
  onSaveClick,
  onBackClick,
  answerValues,
}) {
  const getAnswerByValue = (value) =>
    currentQuestion.answers.find((answer) => answer.title === value);

  const answersNextQuestionIds = answerValues?.map(
    (answer) => getAnswerByValue(answer)?.nextQuestionId
  );
  const nextQuestionId =
    answersNextQuestionIds?.length &&
    (Math.min(...answersNextQuestionIds) || null);
  const answersIds = answerValues?.map(
    (answer) => getAnswerByValue(answer)?.id
  );

  return (
    <div className="grid w-full gap-x-2 mt-auto self-end gap-y-2 lg:grid-flow-col lg:grid-cols-[max-content] lg:justify-end ">
      <div className="grid grid-cols-2  gap-x-2 ">
        {Boolean(currentQuestionIndex) && (
          <Button
            type="button"
            onClick={() => {
              onBackClick(currentQuestion.id, currentQuestionIndex);
            }}
            className="btn-purple bg-dark-gray lg:w-[190px] h-[40px] text-base text-font   self-end"
          >
            Back
          </Button>
        )}
        <Button
          type="button"
          onClick={() => {
            onSaveClick(
              answerValues?.length ? answersIds : undefined,
              currentQuestion.id
            );
          }}
          className={cn(
            "btn-purple h-[40px] text-base text-font self-end bg-dark-gray lg:w-[190px] lg:row-[1]",
            { "col-[1_/span_2]": !currentQuestionIndex }
          )}
        >
          Save & Exit
        </Button>
      </div>
      <Button
        type="submit"
        onClick={() => {
          if (answerValues?.length) {
            onNextClick(answersIds, currentQuestion.id, nextQuestionId);
          }
        }}
        className="btn-purple row-[1]	  lg:w-[190px] h-[40px] text-base "
      >
        Next
      </Button>
    </div>
  );
}

Actions.propTypes = {
  currentQuestionIndex: PropTypes.number,
  currentQuestion: questionType,
  onNextClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  onBackClick: PropTypes.func,
  answerValue: PropTypes.string,
};
