import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import FlashcardList from "../../../base/components/FlashcardList";
import Spinner from "../../../base/components/Spinner";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";

import WordsService from "../../../services/WordsService";

import { ANSWERS } from "../../../base/constants/answers";
import NoItemsPlaceholder from "../../../base/components/NoItemsPlaceholder";

const LIMIT = 100;

export default function Vocabulary({ isPracticePage, isCompletedRequire = true }) {
  /**
   * @type {WordsService}
   */
  const wordsService = useService(WordsService);

  const { lessonId } = useParams();
  const [isLoading, { registerPromise }] = useLoading();
  const [isLoadingCards, { registerPromise: registerLoadPromise }] =
    useLoading();

  const [flashcards, setFlashcards] = useState({ data: [], pagination: {} });
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const onNextClick = () => {
    if (isPracticePage) {
      onRememberClick(ANSWERS.YES);
    }
    if (currentCardIndex < flashcards.data?.length) {
      setCurrentCardIndex((prev) => prev + 1);
    }
  };

  const onBackClick = () => {
    if (isPracticePage) {
      onRememberClick(ANSWERS.NO);
      setCurrentCardIndex((prev) => prev + 1);
    } else {
      setCurrentCardIndex((prev) => prev - 1);
    }
  };

  const setFavorite = (cardId) => {
    const newState = flashcards.data?.map((card) => {
      if (card.id === cardId) {
        return {
          ...card,
          isFavorite: !card.isFavorite,
        };
      }

      return card;
    });

    setFlashcards((prevState) => ({ ...prevState, data: newState }));
  };
  const onRememberClick = useCallback(
    (answer) => {
      const currentCard = flashcards.data[currentCardIndex];

      registerPromise(
        wordsService.setRemember(currentCard?.id, {
          answer,
        })
      );
    },
    [registerPromise, wordsService, flashcards, currentCardIndex]
  );

  const onFavoriteClick = useCallback(() => {
    const currentCard = flashcards.data[currentCardIndex];
    const isCurrentCardFavorite = currentCard?.isFavorite;

    registerPromise(
      wordsService.setFavorite(currentCard?.id, {
        userFlashcard: { isFavorite: !isCurrentCardFavorite },
      })
    ).then(() => setFavorite(currentCard?.id));
  }, [registerPromise, wordsService, flashcards, currentCardIndex]);

  const fetchFlashcards = useCallback(
    (flashcardsLength = 0, offset = 0, flashcards = []) => {
      registerPromise(
        wordsService.getWords({
          limit: LIMIT,
          offset,
          lessonId,
          byStage: !!isPracticePage,
          ...( isCompletedRequire && { isCompleted: !!isPracticePage }),
        })
      ).then(({ data, pagination }) => {
        setFlashcards(() => ({
          data: [...flashcards, ...data],
          pagination,
        }));
        if (flashcardsLength < pagination.totalCount) {
          fetchFlashcards(flashcardsLength + LIMIT, pagination.nextOffset, [
            ...flashcards,
            ...data,
          ]);
        }
      });
    },
    [registerPromise, wordsService]
  );

  useEffect(() => {
    fetchFlashcards();
  }, []);

  if (isLoadingCards)
    return (
      <Spinner
        className={"m-auto flex flex-col justify-center items-center h-full"}
      />
    );

  if (isLoading && !flashcards.data?.length)
    return <Spinner className={"spinner"} />;

  return (
    <div className="h-full">
      {!!flashcards.data.length && (
        <FlashcardList
          currentCard={flashcards.data[currentCardIndex]}
          currentCardIndex={currentCardIndex}
          totalCardsCount={flashcards.data.length}
          onNextClick={onNextClick}
          onBackLick={onBackClick}
          lessonId={lessonId}
          isPracticePage={isPracticePage}
          onFavoriteClick={onFavoriteClick}
          key={flashcards.data[currentCardIndex]?.id}
        />
      )}
      {isPracticePage && !flashcards.data.length && (
        <NoItemsPlaceholder sectionName="flashcards" />
      )}
    </div>
  );
}
