export const STAGES = {
  ONE_DAY: 1,
  TWO_DAYS: 2,
  THREE_DAYS: 3,
  ONE_WEEK: 4,
  TWO_WEEKS: 5,
  ONE_MONTH: 6,
  THREE_MONTH: 7,
  SIX_MONTH: 8,
  TWELVE_MONTH: 9,
  TWENTY_FOUR_MONTH: 10,
};

export const STAGES_MAP = {
  [STAGES.ONE_DAY]: "1 Day",
  [STAGES.TWO_DAYS]: "2 Days",
  [STAGES.THREE_DAYS]: "3 Days",
  [STAGES.ONE_WEEK]: "1 Week",
  [STAGES.TWO_WEEKS]: "2 Weeks",
  [STAGES.ONE_MONTH]: "1 Month",
  [STAGES.THREE_MONTH]: "3 Months",
  [STAGES.SIX_MONTH]: "6 Months",
  [STAGES.TWELVE_MONTH]: "12 Months",
  [STAGES.TWENTY_FOUR_MONTH]: "24 Months",
};
