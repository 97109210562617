import React, {useEffect} from "react";

import SubscriptionCard from "./SubscriptionCard";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import CloseOutline from "../../../../../../../../base/assets/close-outline.svg";
import {SUBSCRIPTION_NONE_TYPE_ID, SUBSCRIPTION_PLANS} from "../../../../../../../../base/constants/subscriptions";
import Button from "../../../../../../../../base/components/Button";
import CommonModal from "../../../../../../../../base/components/Modals/CommonModal";

const ChangeSubscriptionModal = ({show, closeModal, onSubmit, subscriptionsState}) => {

  const { updated, currentSubscription } = subscriptionsState;
  const { durationType = SUBSCRIPTION_NONE_TYPE_ID } = currentSubscription || {}

  useEffect(() => {
    if (updated) {
      closeModal();
    }
  },[updated])

  return (
    <CommonModal
      show={show}
      initialState={{
        modalBodyWrapperClassName: "lg:min-w-[664px]"
      }}
    >
      <div className="relative">
        <img
          src={CloseOutline}
          alt=""
          width={30}
          height={30}
          className="absolute top-[-14px] right-[-14px] cursor-pointer hidden lg:block"
          onClick={closeModal}
        />
      </div>
      <div className="flex flex-col lg:flex-row justify-between m-[8px] h-[480px] lg:h-auto lg:my-[48px] lg:mx-[40px]">
        {
          SUBSCRIPTION_PLANS.map((plan, index) => {

            const { title, currency, amount, period, id, badge } = plan;

            return (
              <SubscriptionCard
                key={index}
                title={title}
                currency={currency}
                amount={amount}
                period={period}
                id={id}
                badge={badge}
                onSelect={onSubmit}
                isCurrent={id?.toString() === durationType?.toString()}
              />
            )
          })
        }
        <Button
          className="btn-back h-10 border-[1px] w-full text-base rounded-[10px] lg:mt-0 lg:hidden"
          onClick={closeModal}
        >
          Close
        </Button>
      </div>
    </CommonModal>
  )
}

ChangeSubscriptionModal.propTypes = {
  show: PropTypes.bool,
  closeModal: PropTypes.func,
  buySubscription: PropTypes.func,
  subscriptionsState: PropTypes.object,
  onSubmit: PropTypes.func,
}

const mapStateToProps = ({Subscriptions}) => ({
  subscriptionsState: Subscriptions
})

export default connect(mapStateToProps, {})(ChangeSubscriptionModal);