import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useNavigate, useParams } from "react-router-dom";

import ProgressBar from "../ProgressBar";
import CompletedCard from "./components/CompletedCard/CompletedCard";
import Question from "./components/Question";

import { useService } from "../../hooks/useService";
import { useLoading } from "../../hooks/useLoading";

import QuestionnaireService from "../../../services/QuestionnaireService";
import Spinner from "../Spinner";

export default function Questionnaire({
  description,
  quizId,
  completedCatIcon,
  onExit,
  redirect,
  change,
  isInitialQuiz,
}) {
  /**
   * @type {QuestionnaireService}
   */
  const questionnaireService = useService(QuestionnaireService);
  const navigate = useNavigate();
  const [isLoading, { registerPromise }] = useLoading();
  const { id } = useParams();

  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [isAnswersShouldSave, setIsAnswersShouldSave] = useState(false);

  const currentQuestion = questions[currentQuestionIndex];

  const isQuizCompleted =
    !change &&
    questions[0]?.userAnswers &&
    questions[0]?.userAnswers[0]?.isQuizCompleted;

  const getQuestionIndexById = (questionId) =>
    questions.findIndex((question) => question.id === questionId);

  const getLastAnsweredQuestionIndex = (questions) => {
    return Math.max(
      questions.findLastIndex((question) => question.userAnswers),
      0
    );
  };

  const getAnsweredPercentage = () => {
    return `${Math.round((currentQuestionIndex / questions.length) * 100)}%`;
  };

  const getAnswers = () => {
    const answers = [];
    questions.forEach(
      (question) =>
        question.userAnswers &&
        answers.push(
          ...question.userAnswers.map(({ answerId, questionId }) => ({
            answerId,
            questionId,
          }))
        )
    );
    return answers;
  };

  const setAnswerToQuestions = (questionId, answerIds) => {
    const newState = questions.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          userAnswers: answerIds.map((id) => ({ questionId, answerId: id })),
        };
      }

      return question;
    });

    setQuestions(newState);
  };

  const clearAnswer = (questionId) => {
    const newState = questions.map((question) => {
      if (question.id === questionId) {
        return {
          ...question,
          userAnswers: undefined,
        };
      }

      return question;
    });

    setQuestions(newState);
  };

  const onNextClick = (answerIds, questionId, nextQuestionId) => {
    setAnswerToQuestions(questionId, answerIds);
    setCurrentQuestionIndex(getQuestionIndexById(nextQuestionId));
    if (nextQuestionId === null) {
      setIsAnswersShouldSave(true);
    }
  };

  const onSaveClick = (answerIds, questionId) => {
    if (answerIds) {
      setAnswerToQuestions(questionId, answerIds);
      setIsAnswersShouldSave(true);
    } else {
      sendAnswers();
    }
  };

  const onBackClick = (questionId, currentQuestionIndex) => {
    setCurrentQuestionIndex(
      getLastAnsweredQuestionIndex(questions.slice(0, currentQuestionIndex))
    );
    clearAnswer(questionId);
  };

  const sendAnswers = useCallback(() => {
    const answers = getAnswers();
    if (answers.length) {
      registerPromise(
        questionnaireService.sendAnswers(answers, quizId || id)
      ).then(() => {
        if (currentQuestionIndex >= 0) {
          onExit();
        }
      });
    } else {
      onExit();
    }
  }, [navigate, questions, registerPromise, questionnaireService]);

  useEffect(() => {
    if (isAnswersShouldSave) {
      sendAnswers();
    }
  }, [questions, isAnswersShouldSave]);

  useEffect(() => {
    registerPromise(questionnaireService.getQuestions(quizId || id)).then(
      (data) => {
        setQuestions(data.questions);
        setCurrentQuestionIndex(getLastAnsweredQuestionIndex(data.questions));
      }
    );
  }, [registerPromise, questionnaireService]);

  if (isLoading && !questions.length) return <Spinner className={"spinner"} />;
  return (
    <>
      {!isLoading && Boolean(questions.length) && (
        <>
          {currentQuestionIndex < 0 || isQuizCompleted ? (
            <CompletedCard
              isInitialQuiz={isInitialQuiz}
              catIcon={completedCatIcon}
              keepGoingRedirect={redirect}
            />
          ) : (
            <div className="h-full overflow-y-auto flex-auto container flex flex-col pt-5 pb-9">
              {Boolean(description) && (
                <h4 className=" leading-loose mb-2 text-[12px] text-left  lg:text-[21px] font-bold lg:leading-normal lg:text-center lg:mb-8">
                  {description}
                </h4>
              )}
              <div className="lg:mx-[175px]">
                <ProgressBar width={getAnsweredPercentage()} />
              </div>
              <Question
                currentQuestion={currentQuestion}
                currentQuestionIndex={currentQuestionIndex}
                onNextClick={onNextClick}
                onSaveClick={onSaveClick}
                onBackClick={onBackClick}
                key={currentQuestionIndex}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

Questionnaire.propTypes = {
  description: PropTypes.string,
  quizId: PropTypes.string,
};
