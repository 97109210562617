import React, { useCallback, useEffect, useState } from "react";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";

import Spinner from "../../../../base/components/Spinner";
import { ExerciseHeader } from "../../../../base/components/ExerciseHeader";
import { SentenceTranslation } from "../../components/SentenceTranslation";
import CompletedCard from "../../../../base/components/CompletedCard";

import SentencesService from "../../../../services/SentecesService";
import { PRACTICE_GROUP_LINKS } from "../../config";
import NoItemsPlaceholder from "../../../../base/components/NoItemsPlaceholder";

const LIMIT = 100;

export default function SentencesTranslation() {
  /**
   * @type {SentencesService}
   */
  const sentenceService = useService(SentencesService);
  const [isLoading, { registerPromise }] = useLoading();

  const [sentences, setSentences] = useState({
    data: [],
    pagination: {},
  });
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);

  const isCompleted = currentSentenceIndex === sentences.data.length;

  const onNextClick = () => {
    if (currentSentenceIndex < sentences.data.length) {
      setCurrentSentenceIndex((prev) => prev + 1);
    }
  };

  const fetchSentences = useCallback(
    (sentencesLength = 0, offset = 0, sentences = []) => {
      registerPromise(
        sentenceService.getSentences({
          limit: LIMIT,
          offset,
          byStage: true,
          isCompleted: true,
        })
      ).then(({ data, pagination }) => {
        setSentences(() => ({ pagination, data: [...sentences, ...data] }));

        if (sentencesLength < pagination.totalCount) {
          fetchSentences(sentencesLength + LIMIT, pagination.nextOffset, [
            ...sentences,
            ...data,
          ]);
        }
      });
    },
    [registerPromise, sentenceService]
  );

  useEffect(() => {
    fetchSentences();
  }, []);

  if (isLoading && !sentences.data.length)
    return <Spinner className={"spinner"} />;

  return (
    <section className="h-full flex flex-col">
      {!!sentences.data.length && !isCompleted && (
        <SentenceTranslation
          key={sentences.data[currentSentenceIndex].id}
          sentence={sentences.data[currentSentenceIndex]}
          onNextClick={onNextClick}
        />
      )}
      {!!sentences.data.length && isCompleted && (
        <CompletedCard sectionTitle="Sentences" />
      )}
      {!sentences.data.length && <NoItemsPlaceholder sectionName="sentences" />}
    </section>
  );
}
