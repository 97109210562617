import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import PaymentsService from "../../../services/PaymentsService";
import {
  deleteCreditCardError,
  deleteCreditCardSuccess,
  getCreditCardError,
  getCreditCardSuccess,
  updateCreditCardError,
  updateCreditCardSuccess
} from "./actions";
import {DELETE_CREDIT_CARD, GET_CREDIT_CARD, UPDATE_CREDIT_CARD} from "./actionTypes";
import {useService} from "../../../base/hooks/useService";

const paymentsService = useService(PaymentsService);

const getCreditCardAsync = async () => {
  return await paymentsService.getUserCreditCard();
}

const updateCreditCardAsync = async (token) => {
  const values = { stripeCardToken: token }
  return await paymentsService.updateUserCreditCard(values);
}

const deleteCreditCardAsync = async () => {
  return await paymentsService.deleteUserCreditCard();
}

function* getUserCreditCard() {
  try {
    const response = yield call(getCreditCardAsync);
    yield put(getCreditCardSuccess(response));
  } catch (error) {
    yield put(getCreditCardError(error))
  }
}

function* updateUserCreditCard({ payload }) {
  try {
    const { data } = payload;
    const response = yield call(updateCreditCardAsync, data);
    yield put(updateCreditCardSuccess(response));
  } catch (error) {
    yield put(updateCreditCardError(error))
  }
}

function* deleteUserCreditCard() {
  try {
    const response = yield call(deleteCreditCardAsync);
    yield put(deleteCreditCardSuccess(response));
  } catch (error) {
    yield put(deleteCreditCardError(error))
  }
}

export function* watchGetUserCreditCard() {
  yield takeEvery(GET_CREDIT_CARD, getUserCreditCard);
}

export function* watchUpdateUserCreditCard() {
  yield takeEvery(UPDATE_CREDIT_CARD, updateUserCreditCard);
}

export function* watchDeleteUserCreditCard() {
  yield takeEvery(DELETE_CREDIT_CARD, deleteUserCreditCard);
}

function* paymentsSaga() {
  yield all([
    fork(watchGetUserCreditCard),
    fork(watchUpdateUserCreditCard),
    fork(watchDeleteUserCreditCard),
  ])
}

export default paymentsSaga;