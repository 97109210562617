import * as React from "react";
import PropTypes from "prop-types";
import generateSidebarLinks from "./generateSidebarLinks";
import SidebarProfile from "./SidebarProfile";

const SidebarFooter = ({ schema, isMobile }) => {
  return (
    <div className="sidebar-footer ">
      {generateSidebarLinks(schema, isMobile)}
      <SidebarProfile isMobile={isMobile} />
    </div>
  );
};

SidebarFooter.propTypes = {
  schema: PropTypes.any,
  isMobile: PropTypes.bool,
};

export default SidebarFooter;
