import Http from './Http'

class TransactionsService extends Http {
  static $displayName = "TransactionsService";

  async getTransactions({ limit, offset }) {
    return await this.get(`/users/transactions`, { params: { limit, offset } });
  }
}

export default TransactionsService;