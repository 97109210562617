import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import catIcon from "../components/FlashcardList/assets/cat-completed-practice.svg";

import Button from "./Button";

import { LEARN_GROUP_LINKS } from "../../groups/learn/config";
import { PRACTICE_GROUP_LINKS } from "../../groups/practice/config";

export default function CompletedCard({ sectionTitle }) {
  const navigate = useNavigate();
  return (
    <div className="h-full w-full flex flex-col items-center mt-10 lg:mt-[160px] relative text-center">
      <h1 className="text-md lg:text-2xl">
        You have completed your {sectionTitle} practice for the day!
      </h1>
      <p className="text-center mt-4 lg:mt-[50px]">
        Well done on keeping up to speed! <br />
        If you want more exercises for the day consider completing the next
        lesson.
      </p>
      <div className=" grid lg:grid-flow-col gap-y-[10px] gap-x-2 w-full lg:w-auto mt-auto lg:mt-[136px]">
        <Button
          type="button"
          onClick={() => navigate(LEARN_GROUP_LINKS.LINK_ESSENTIAL_LEARNING)}
          className="btn-purple w-full lg:w-[190px] h-[40px] text-base"
        >
          Take me to lessons
        </Button>

        <Button
          onClick={() => navigate(PRACTICE_GROUP_LINKS.BASE)}
          className="btn-back w-full lg:w-[190px] h-[40px] text-base "
        >
          Return to practice
        </Button>
      </div>
      <img
        src={catIcon}
        className="absolute bottom-20 lg:-bottom-[50px] h-[155px] lg:h-[200px]"
      />
    </div>
  );
}

CompletedCard.PropTypes = {
  sectionTitle: PropTypes.string,
};
