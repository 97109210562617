import * as yup from "yup";
import {
  USER_EMAIL,
  USER_NAME,
  USER_PASSWORD,
} from "../../../../validation/user";
import {
  VALIDATION_ERROR_MESSAGE_EMAIL_REQUIRED,
  VALIDATION_ERROR_MESSAGE_FIRST_NAME_REQUIRED,
  VALIDATION_ERROR_MESSAGE_LAST_NAME_REQUIRED,
  VALIDATION_ERROR_MESSAGE_PASSWORD_MATCH,
  VALIDATION_ERROR_MESSAGE_PASSWORD_REQUIRED,
  VALIDATION_ERROR_MESSAGE_REPEAT_PASSWORD_REQUIRED,
} from "../../../../base/constants/messages";

export const validationSchema = yup.object().shape({
  email: USER_EMAIL.required(VALIDATION_ERROR_MESSAGE_EMAIL_REQUIRED),
  firstName: USER_NAME.required(VALIDATION_ERROR_MESSAGE_FIRST_NAME_REQUIRED),
  lastName: USER_NAME.required(VALIDATION_ERROR_MESSAGE_LAST_NAME_REQUIRED),
  password: USER_PASSWORD.required(VALIDATION_ERROR_MESSAGE_PASSWORD_REQUIRED),
  confirmPassword: yup
    .string()
    .required(VALIDATION_ERROR_MESSAGE_REPEAT_PASSWORD_REQUIRED)
    .test(
      "passwords-match",
      VALIDATION_ERROR_MESSAGE_PASSWORD_MATCH,
      function (value) {
        return this.parent.password === value;
      }
    ),
  referralCode: yup.string().notRequired(),
});
export const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  confirmPassword: "",
  referralCode: "",
};
