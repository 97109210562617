import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import favoriteFillIcon from "../../../assets/favorite-fill.svg";
import favoriteOutlineIcon from "../../../assets/favorite-outline.svg";

import Button from "../../Button";
import useMediaQuery from "../../../hooks/useMediaQuery";

const MAX_SYMBOLS_IN_ROW = 12;
const ROW_HEIGHT = 28;
const INITIAL_HEIGHT_MOBILE = 200;
const INITIAL_HEIGHT = 295;

const DESKTOP_BREAKPOINT = 1024;

export default function Card({
  flashcard: { word, translation, isFavorite },
  isFlipped,
  onFlip,
  onFavoriteClick,
  isPracticePage,
}) {
  const isDesktop = useMediaQuery(`(min-width: ${DESKTOP_BREAKPOINT})`);

  const IconFavorite = () => (
    <img
      src={isFavorite ? favoriteFillIcon : favoriteOutlineIcon}
      className="self-end cursor-pointer h-5 w-5 lg:h-6 lg:w-6"
      onClick={onFavoriteClick}
    />
  );

  const cardHeightMobile = useMemo(() => {
    const height = word.split("").reduce((height, letter, index) => {
      if (index > 0 && index % MAX_SYMBOLS_IN_ROW === 0) {
        height = height + ROW_HEIGHT;
      }
      return height;
    }, INITIAL_HEIGHT_MOBILE);
    return height;
  }, [word]);

  return (
    <div
      className={twMerge(
        `flex-auto flex items-center justify-center py-5 ${
          isPracticePage && "pt-[70px] pb-5 lg:py-0"
        }`
      )}
    >
      <div
        style={{
          height: isDesktop ? INITIAL_HEIGHT : cardHeightMobile,
        }}
        className={twMerge(
          ` w-full  w-[250px] lg:w-[464px] lg:h-[295px]  relative  rounded-[15px] text-center text-purple transition-transform duration-500 transform-3d ${
            isFlipped && "rotate-y-180"
          }`
        )}
      >
        <div className="w-full shadow-card pt-[15px] lg:pt-8  px-[15px] lg:pb-8 lg:pt-7 pb-10 lg:pb-[71px] flex flex-col justify-between rounded-[15px] absolute backface-hidden bg-light-purple">
          <IconFavorite />

          <div>
            <p className="text-lg lg:text-2xl font-bold leading-[1] mt-[25px] lg:mt-12 break-all	">
              {word}
            </p>
            <Button
              className="btn-purple mt-8 lg:mt-8 h-[40px] w-[121px] lg:w-[310px] text-base"
              onClick={onFlip}
            >
              Flip Card
            </Button>
          </div>
        </div>
        <div className="w-full shadow-card   pt-[15px] lg:pt-8  px-[15px] lg:pt-7 pb-[45px] lg:pb-[76px] flex flex-col justify-between rounded-[15px] absolute backface-hidden bg-white rotate-y-180">
          <div className="flex justify-end ">
            <IconFavorite />
          </div>

          <div>
            <p className="text-lg lg:text-2xl font-bold leading-[1] mt-[25px] lg:mt-12 break-all	">
              {word}
            </p>
            <p className="mt-[15px] leading-[1] lg:mt-[38px] text-font font-bold">
              {translation}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

Card.propTypes = {
  flashcard: PropTypes.shape({
    word: PropTypes.string,
    translation: PropTypes.string,
    isFavorite: PropTypes.bool,
  }),
  isFlipped: PropTypes.bool,
  onFlip: PropTypes.func,
  onFavoriteClick: PropTypes.func,
  isPracticePage: PropTypes.bool,
};
