import React, {useCallback, useEffect, useState} from "react";
import LinkCard from "../../../../base/components/LinkCard";
import {useService} from "../../../../base/hooks/useService";
import {useLoading} from "../../../../base/hooks/useLoading";
import QuestionnaireService from "../../../../services/QuestionnaireService";
import {CUSTOM_LEARNING_MENU} from "../../../../base/constants/customLessons";
import Spinner from "../../../../base/components/Spinner";
import {getCardLink} from "./helpers/getCardLink";

import "../../index.scss";

const SEPARATOR = "&";

const CustomLearningMenu = () => {
  const getCorrectFormatOfTitle = (title) => {
    const [first, second] = title.split(SEPARATOR);
    return (
      <>
        <span className="block"> {first}</span>
        <span className="block"> &</span>
        <span className="block"> {second}</span>
      </>
    );
  };

  const questionnaireService = useService(QuestionnaireService);
  const [isLoading, { registerPromise }] = useLoading();

  const [quizzes, setQuizzes] = useState([]);

  const fetchQuizzes = useCallback(() => {
    registerPromise(questionnaireService.getQuizzes({
      limit: 100,
      offset: 0,
    })).then(({ data }) =>
      setQuizzes(data)
    );
  }, [registerPromise, questionnaireService]);

  useEffect(() => {
    setQuizzes([]);
    fetchQuizzes();
  }, [])

  if (isLoading && !quizzes?.data?.length)
    return <Spinner className={"spinner"} />;

  return (
    <div className="mt-7">
      <h4 className="text-xl leading-[44px] mb-[20px] ">
        Custom Learning Menu
      </h4>
      <div className="w-full grid grid-rows-[repeat(3,146px)] gap-y-[10px] lg:gap-y-0 lg:grid-cols-3 lg:grid-rows-[248px] lg:gap-x-[20px]">
        {CUSTOM_LEARNING_MENU.map(({ link, title }) => (
          <LinkCard { ...getCardLink(link, quizzes)} key={title}>
            <div className="w-full h-full flex  justify-center items-center">
              <h4 className="text-white text-[21px] lg:text-[26px] leading-[37px]">
                {getCorrectFormatOfTitle(title)}
              </h4>
            </div>
          </LinkCard>
        ))}
      </div>
    </div>
  );
};

export default CustomLearningMenu;