import * as yup from "yup";
import {
  VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_FORMAT,
  VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_MAX_LENGTH,
  VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_REQUIRED,
  VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_SPACES,

} from "../../../../../../../../base/constants/messages";
import {
  MAX_CARDHOLDER_LENGTH,
  MIN_CARDHOLDER_LENGTH,
} from "../../../../../../../../validation/lengthConstants";
import {NAME_PATTERN} from "../../../../../../../../base/constants/patterns";

export const validationSchema = yup.object().shape({
  cardHolderName: yup.string()
    .min(MIN_CARDHOLDER_LENGTH, VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_REQUIRED)
    .max(MAX_CARDHOLDER_LENGTH, VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_MAX_LENGTH)
    .matches(NAME_PATTERN, VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_FORMAT)
    .trim(VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_SPACES)
    .required(VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_REQUIRED),
});

export const validateField = async (fieldName, fieldValue) => {
  try {
    await validationSchema.validate({ [fieldName]: fieldValue });
    return { [fieldName]: "" };
  } catch (e) {
    return { [fieldName]: e.message };
  }
}