import React, { useCallback } from "react";
import PropTypes from "prop-types";

const FLATPICKER_FORMAT = "d/m/y";

export default function CustomDatePicker({
  handleChangeDate,
  minDate,
  initialValue,
  format,
}) {
  const onChange = useCallback(
    (date) => {
      handleChangeDate(date[0]);
    },
    [handleChangeDate]
  );

  return (
    <></>
    // <Flatpickr
    //   value={initialValue}
    //   className="form-control d-block"
    //   placeholder="Pick a date"
    //   options={{
    //     altInput: true,
    //     altFormat: FLATPICKER_FORMAT,
    //     dateFormat: format || FLATPICKER_FORMAT,
    //     maxDate: Date.now(),
    //     minDate: minDate,
    //   }}
    //   onChange={onChange}
    // />
  );
}

CustomDatePicker.propTypes = {
  handleChangeDate: PropTypes.func,
  minDate: PropTypes.string,
  initialValue: PropTypes.string,
  format: PropTypes.string,
};
