import React from "react";
import PropTypes from "prop-types";

import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import "./index.scss";

const ImagesLightBox = ({src, onClose}) => {

  return (src && (
      <Lightbox
        mainSrc={src}
        onCloseRequest={onClose}
      />
    )
  )
}

ImagesLightBox.propTypes = {
  src: PropTypes.string,
  onClose: PropTypes.func,
}

export default ImagesLightBox