import PropTypes from "prop-types";
import generateSidebarLinks from "./generateSidebarLinks";

const SidebarContent = ({ isMobile, schema = [] }) => {
  return generateSidebarLinks(schema, isMobile);
};

SidebarContent.propTypes = {
  isMobile: PropTypes.bool,
  schema: PropTypes.any,
};

export default SidebarContent;
