export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";
export const GET_SUBSCRIPTION_SUCCESS = "GET_SUBSCRIPTION_SUCCESS";
export const GET_SUBSCRIPTION_ERROR = "GET_SUBSCRIPTION_ERROR";
export const GET_SUBSCRIPTION_CLEAR = "GET_SUBSCRIPTION_CLEAR";

export const BUY_SUBSCRIPTION = "BUY_SUBSCRIPTION";
export const BUY_SUBSCRIPTION_SUCCESS = "BUY_SUBSCRIPTION_SUCCESS";
export const BUY_SUBSCRIPTION_ERROR = "BUY_SUBSCRIPTION_ERROR";
export const BUY_SUBSCRIPTION_CLEAR = "BUY_SUBSCRIPTION_CLEAR";

export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";
export const UPDATE_SUBSCRIPTION_ERROR = "UPDATE_SUBSCRIPTION_ERROR";
export const UPDATE_SUBSCRIPTION_CLEAR = "UPDATE_SUBSCRIPTION_CLEAR";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";
export const CANCEL_SUBSCRIPTION_ERROR = "CANCEL_SUBSCRIPTION_ERROR";
export const CANCEL_SUBSCRIPTION_CLEAR = "CANCEL_SUBSCRIPTION_CLEAR";

export const ACTIVATE_SUBSCRIPTION = "ACTIVATE_SUBSCRIPTION";
export const ACTIVATE_SUBSCRIPTION_SUCCESS = "ACTIVATE_SUBSCRIPTION_SUCCESS";
export const ACTIVATE_SUBSCRIPTION_ERROR = "ACTIVATE_SUBSCRIPTION_ERROR";
export const ACTIVATE_SUBSCRIPTION_CLEAR = "ACTIVATE_SUBSCRIPTION_CLEAR";

export const SUBSCRIPTION_ERROR_CLEAR = "SUBSCRIPTION_ERROR_CLEAR";