import React from "react";
import Vocabulary from "../../../lesson/components/Vocabulary";


export default function Flashcards() {
  return (
    <div className="h-full ">
      <Vocabulary isPracticePage />
    </div>
  );
}
