import React, { useEffect, useState } from "react";
import QuestionnaireCard from "./components/QuestionnaireCard";
import { useNavigate } from "react-router-dom";
import {
  CUSTOM_QUESTIONNAIRE_BASE_PARAMS,
  CUSTOM_QUESTIONNAIRE_GROUP_LINKS,
} from "../../../../../customQuestionnaire/config";
import { useService } from "../../../../../../base/hooks/useService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import Spinner from "../../../../../../base/components/Spinner";
import QuestionnaireService from "../../../../../../services/QuestionnaireService";
import { QUESTIONNAIRE_THEMES } from "../../../../../../base/constants/customLessons";
import { PROFILE_GROUP_LINKS } from "../../../../config";
import { questionnaireThemeSortHelper } from "../../../helpers/questionnaireThemeSortHelper";

const LIMIT = 30;

const Questionnaire = () => {
  const navigate = useNavigate();
  const questionnaireService = useService(QuestionnaireService);
  const [isLoading, { registerPromise }] = useLoading();
  const [quizzes, setQuizzes] = useState({});

  useEffect(() => {
    registerPromise(
      questionnaireService.getQuizzes({
        limit: LIMIT,
        offset: quizzes?.pagination?.nextOffset || 0,
      })
    ).then(setQuizzes);
  }, [registerPromise, questionnaireService]);

  if (isLoading && !quizzes?.data?.length)
    return <Spinner className={"spinner"} />;

  return (
    <div>
      <h2 className="text-[21px] font-bold text-font ">
        Questionnaire Settings
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-[246px_246px_246px] gap-[14px] mt-[10px] lg:mt-[26px]">
        {quizzes?.data
          ?.sort(questionnaireThemeSortHelper)
          .map((item, index) => {
            const { userAnswers, quizId } = item || {};
            const isQuizCompleted = userAnswers
              ? userAnswers[0].isQuizCompleted
              : false;

            const params = isQuizCompleted
              ? `/?${CUSTOM_QUESTIONNAIRE_BASE_PARAMS.CHANGE}=true&` +
                `${CUSTOM_QUESTIONNAIRE_BASE_PARAMS.REDIRECT}=${PROFILE_GROUP_LINKS.LINK_TO_QUESTIONNAIRE}`
              : `/?${CUSTOM_QUESTIONNAIRE_BASE_PARAMS.REDIRECT}=${PROFILE_GROUP_LINKS.LINK_TO_QUESTIONNAIRE}`;

            return (
              <QuestionnaireCard
                key={index}
                completed={isQuizCompleted}
                title={QUESTIONNAIRE_THEMES?.[quizId]}
                onClickButton={() =>
                  navigate(
                    `${CUSTOM_QUESTIONNAIRE_GROUP_LINKS.BASE}/${quizId}${params}`
                  )
                }
              />
            );
          })}
      </div>
    </div>
  );
};

Questionnaire.propTypes = {};

export default Questionnaire;
