import { CUSTOM_LEARNING_MENU } from "../../../../../base/constants/customLessons";
import { LEARN_GROUP_LINKS } from "../../../config";

export const getLessonsLinkByQuizId = (id) => {
  const themeLink = CUSTOM_LEARNING_MENU.find(
    ({ quizId }) => quizId === +id
  ).link;

  return `${LEARN_GROUP_LINKS.LINK_CUSTOM_LEARNING}/${themeLink}`;
};
