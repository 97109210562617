import React from "react";
import PropTypes from "prop-types";

import Cube from "../../assets/images/unsubscribed-warning-cube.svg";
import Puzzle from "../../assets/images/unsubscribed-warning-puzzle.svg";
import Pen from "../../assets/images/unsubscribed-warning-pen.svg";
import Book from "../../assets/images/unsubscribed-warning-book.svg";
import BeggingCat from "../../assets/images/begging-cat@3x.png";
import {useNavigate} from "react-router-dom";
import {PROFILE_GROUP_LINKS} from "../../groups/profile/config";

const features = [
  {
    icon: Cube,
    text: "The complete essentials course"
  },
  {
    icon: Puzzle,
    text: "Customized lessons based on your needs"
  },
  {
    icon: Pen,
    text: "Practice modules"
  },
  {
    icon: Book,
    text: "Reference tools"
  }
]

const AccessRestricted = ({ children, visibleElements, subtitle }) => {

  const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full align justify-start">
      {visibleElements}
      <div className="relative ">
        <div className="absolute  w-full lg:pt-[30px] z-[3] ">
          <div className="flex flex-col items-center h-full pt-[30px] text-font">
            <h2 className=" text-lg lg:text-[32px] font-bold text-center leading-[59px]">
              Learn German the way YOU need it
            </h2>
            <h2 className="text-md lg:text-[21px] text-center">
              {subtitle}
            </h2>
            <h2 className="text-md lg:text-2xl font-bold mt-10 text-center">
              Your membership will include
            </h2>
            <div className="flex flex-row mt-[30px] flex-wrap justify-center md:px-20 lg:px-30 xl:px-0">
              {features.map((feature, index) => {

                const { icon, text } = feature;

                return (
                  <div key={index} className="flex flex-col items-center max-w-[180px] my-3 lg:mx-3 lg:my-0">
                    <img
                      src={icon}
                      alt=""
                    />
                    <icon/>
                    <h2 className=" text-center font-[300] text-base lg:text-md w-[190px]">
                      {text}
                    </h2>
                  </div>
                )
              })}
            </div>
            <div className=" mt-8 lg:mt-14 mb-8 h-full ">
              <div className="relative pt-[100px]">
                <img
                  width={154}
                  height={99}
                  className="absolute bottom-[-8px] left-[50%] translate-x-[-53%]"
                  src={BeggingCat}
                />
              </div>
              <button
                className="btn-purple py-2 px-12 "
                onClick={() => navigate(PROFILE_GROUP_LINKS.LINK_TO_ACCOUNT_INFORMATION)}
              >
                Subscribe Now
              </button>
            </div>
          </div>

        </div>
        <div className=" pointer-events-none">
          <div className="bg-white opacity-95 w-full h-full absolute lg:rounded-[12px] z-[2]"/>
          <div className="min-h-[1300px] md:min-h-[825px]">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

AccessRestricted.propTypes = {
  children: PropTypes.any,
  visibleElements: PropTypes.any,
  subtitle: PropTypes.string,
}

export default AccessRestricted;