const LINK_TO_PROFILE_ROOT = `/app/profile`;
const LINK_TO_PROFILE_INFO_PAGE = `${LINK_TO_PROFILE_ROOT}/info`;
const LINK_TO_SETTINGS_PAGE = `${LINK_TO_PROFILE_ROOT}/settings`;
const LINK_TO_ACCOUNT_INFORMATION = `${LINK_TO_PROFILE_ROOT}/account-information`;
const LINK_TO_QUESTIONNAIRE = `${LINK_TO_PROFILE_ROOT}/questionnaire`;

export const PROFILE_GROUP_LINKS = {
  BASE: LINK_TO_PROFILE_ROOT,
  LINK_TO_PROFILE_INFO_PAGE,
  LINK_TO_SETTINGS_PAGE,
  LINK_TO_ACCOUNT_INFORMATION,
  LINK_TO_QUESTIONNAIRE,
};

export const PROFILE_GROUP_ROUTES = {
  BASE: `/profile/*`,
  PROFILE: "/",
  LINK_TO_PROFILE_INFO_PAGE: "/info/*",
  LINK_TO_SETTINGS_PAGE: "/settings",
  LINK_TO_ACCOUNT_INFORMATION: "/account-information",
  LINK_TO_QUESTIONNAIRE: "/questionnaire",
};
