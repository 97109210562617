import {
  DELETE_CREDIT_CARD, DELETE_CREDIT_CARD_CLEAR, DELETE_CREDIT_CARD_ERROR, DELETE_CREDIT_CARD_SUCCESS,
  GET_CREDIT_CARD,
  GET_CREDIT_CARD_CLEAR,
  GET_CREDIT_CARD_ERROR,
  GET_CREDIT_CARD_ERROR_CLEAR,
  GET_CREDIT_CARD_SUCCESS,
  UPDATE_CREDIT_CARD,
  UPDATE_CREDIT_CARD_CLEAR,
  UPDATE_CREDIT_CARD_ERROR,
  UPDATE_CREDIT_CARD_SUCCESS
} from "./actionTypes";

export const getCreditCard = () => {
  return {
    type: GET_CREDIT_CARD,
  };
};

export const getCreditCardSuccess = (data) => {
  return {
    payload: { data },
    type: GET_CREDIT_CARD_SUCCESS,
  };
};

export const getCreditCardClear = () => {
  return {
    type: GET_CREDIT_CARD_CLEAR,
  };
};

export const getCreditCardError = ({ message }) => {
  return {
    payload: { message },
    type: GET_CREDIT_CARD_ERROR,
  };
};

export const getCreditCardErrorClear = () => {
  return {
    type: GET_CREDIT_CARD_ERROR_CLEAR,
  }
}

export const updateCreditCard = ({ data }) => {
  return {
    payload: { data },
    type: UPDATE_CREDIT_CARD,
  }
}

export const updateCreditCardSuccess = (data) => {
  return {
    payload: { data },
    type: UPDATE_CREDIT_CARD_SUCCESS,
  };
};

export const updateCreditCardError = ({ message }) => {
  return {
    payload: { message },
    type: UPDATE_CREDIT_CARD_ERROR,
  };
};

export const updateCreditCardClear = () => {
  return {
    type: UPDATE_CREDIT_CARD_CLEAR,
  }
}

export const deleteCreditCard = () => {
  return {
    type: DELETE_CREDIT_CARD,
  }
}

export const deleteCreditCardSuccess = (data) => {
  return {
    payload: { data },
    type: DELETE_CREDIT_CARD_SUCCESS,
  };
};

export const deleteCreditCardError = ({ message }) => {
  return {
    payload: { message },
    type: DELETE_CREDIT_CARD_ERROR,
  };
};

export const deleteCreditCardClear = () => {
  return {
    type: DELETE_CREDIT_CARD_CLEAR,
  }
}