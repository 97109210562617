import React, { useCallback, useState } from "react";

import Index from "../../components/EmailCard";
import ThankCard from "../../components/ThankCard";

import catIcon from "../../assets/confused-cat.svg";
import catIconMob from "../../assets/confused-cat-mob.svg";
import AuthCard from "../../components/AuthCard";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import { FORGOT_PASSWORD_MESSAGES } from "../../../../base/constants/statusMessages";

const ForgetPasswordPage = () => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [, { registerPromise }] = useLoading();

  const sendEmailLink = useCallback(
    (email, setErrors) => {
      registerPromise(authService.sendRestorePasswordEmail(email))
        .then(() => setIsLinkSent(true))
        .catch((error) => {
          setErrors({
            email: FORGOT_PASSWORD_MESSAGES[error.statusCode] || "",
          });
        });
    },
    [registerPromise, authService]
  );

  return (
    <>
      {!isLinkSent && (
        <AuthCard
          title="Forgot password"
          borderImg={catIcon}
          borderImgMob={catIconMob}
          borderImgClassName="h-[138px]  lg:absolute  lg:bottom-0 lg:h-auto lg:translate-x-0 lg:left-auto lg:-top-[7rem] lg:-right-20"
        >
          <Index
            description={`Please enter your email address and click on "Reset password." We will send you a link to set a new password.`}
            onSubmit={sendEmailLink}
            buttonTitle="Reset password"
          />
        </AuthCard>
      )}
      {isLinkSent && (
        <ThankCard
          title="Thank you!"
          description="Please check your email to reset your password."
          isBackButton
          isResendButton={false}
        />
      )}
    </>
  );
};

export default ForgetPasswordPage;
