export const GET_CREDIT_CARD = "GET_CREDIT_CARD";
export const GET_CREDIT_CARD_SUCCESS = "GET_CREDIT_CARD_SUCCESS";
export const GET_CREDIT_CARD_CLEAR = "GET_CREDIT_CARD_CLEAR";
export const GET_CREDIT_CARD_ERROR = "GET_CREDIT_CARD_ERROR";
export const GET_CREDIT_CARD_ERROR_CLEAR = "GET_CREDIT_CARD_ERROR_CLEAR";

export const UPDATE_CREDIT_CARD = "UPDATE_CREDIT_CARD";
export const UPDATE_CREDIT_CARD_SUCCESS = "UPDATE_CREDIT_CARD_SUCCESS";
export const UPDATE_CREDIT_CARD_ERROR = "UPDATE_CREDIT_CARD_ERROR";
export const UPDATE_CREDIT_CARD_CLEAR = "UPDATE_CREDIT_CARD_CLEAR";

export const DELETE_CREDIT_CARD = "DELETE_CREDIT_CARD";
export const DELETE_CREDIT_CARD_SUCCESS = "DELETE_CREDIT_CARD_SUCCESS";
export const DELETE_CREDIT_CARD_ERROR = "DELETE_CREDIT_CARD_ERROR";
export const DELETE_CREDIT_CARD_CLEAR = "DELETE_CREDIT_CARD_CLEAR";