import React from "react";

import catIcon from "../assets/cat.svg";

export default function CompletedCard() {
  return (
    <div className="flex-auto flex items-center justify-center">
      <div className="relative h-[175px] lg:h-[200px] lg:w-[464px] w-[250px] lg:w-[464px]  shadow-card rounded-[15px] bg-light-purple flex justify-center items-center">
        <p className="text-2xl font-bold text-purple leading-[36px] text-center">
          Great job! <br /> Keep going!
        </p>
        <img
          className=" -translate-x-1/3  absolute lg:h-auto -top-[90px] left-1/2"
          src={catIcon}
        />
      </div>
    </div>
  );
}
