import React from "react";
import { twMerge } from "tailwind-merge";

import Button from "../../../base/components/Button";

import successIcon from "../../../base/assets/success.svg";
import catIcon from "../assets/cat.svg";
import successCatIcon from "../assets/success-cat.svg";

export default function CompleteButton({ lesson, onCompleteClick }) {
  return (
    <div className="relative w-full lg:w-[260px]">
      <Button
        onClick={onCompleteClick}
        className={twMerge(
          `text-base h-full w-full  h-10 flex items-center justify-center ${
            lesson?.isCompleted ? "btn-success" : "btn-back"
          }`
        )}
      >
        {lesson?.isCompleted ? (
          <>
            <img src={successIcon} className="mr-5" />
            <span>Lesson Completed</span>
          </>
        ) : (
          <span>Mark Lesson as Complete</span>
        )}
      </Button>
      <img
        src={lesson?.isCompleted ? successCatIcon : catIcon}
        className=" -translate-x-1/3  absolute lg:h-auto -top-[94px] left-1/2"
      />
    </div>
  );
}
