const LINK_TO_BOOKMARKS_ROOT = `/app/bookmarks`;
const LINK_CUSTOM_LESSONS = `${LINK_TO_BOOKMARKS_ROOT}/custom-lessons`;
const LINK_ESSENTIAL_LESSONS = `${LINK_TO_BOOKMARKS_ROOT}/essential-lessons`;

export const BOOKMARKS_GROUP_LINKS = {
  BASE: LINK_TO_BOOKMARKS_ROOT,
  LINK_CUSTOM_LESSONS,
  LINK_ESSENTIAL_LESSONS,
};

export const BOOKMARKS_GROUP_ROUTES = {
  BASE: "/bookmarks/*",
  LINK_CUSTOM_LESSONS: "/custom-lessons",
  LINK_ESSENTIAL_LESSONS: "/essential-lessons",
};
