export const SUBSCRIPTION_NONE_TYPE_ID = "none";
export const SUBSCRIPTION_MONTHLY_TYPE_ID = 1;
export const SUBSCRIPTION_YEARLY_TYPE_ID = 2;
export const SUBSCRIPTION_FREE_TRIAL_TYPE_ID = 3;
export const SUBSCRIPTION_ORGANISATION_TYPE_ID = 6;
export const SUBSCRIPTION_UNLIMITED_TYPE_ID = 4;
export const SUBSCRIPTION_FREE_BETA_TESTER_TYPE_ID = 5;

export const SUBSCRIPTION_NONE_TYPE_TITLE = "None";
export const SUBSCRIPTION_MONTHLY_TYPE_TITLE = "Monthly";
export const SUBSCRIPTION_YEARLY_TYPE_TITLE = "Yearly";
export const SUBSCRIPTION_FREE_TRIAL_TYPE_TITLE = "Free trial (1 month)";
export const SUBSCRIPTION_ORGANISATION_TYPE_TITLE = "Organization";
export const SUBSCRIPTION_UNLIMITED_TYPE_TITLE = "Free (lifetime)";
export const SUBSCRIPTION_FREE_BETA_TESTER_TYPE_TITLE = "Free (beta tester)";

export const SUBSCRIPTION_NONE_STATUS_ID = "none";
export const SUBSCRIPTION_ACTIVE_STATUS_ID = 1;
export const SUBSCRIPTION_INACTIVE_STATUS_ID = 2;
export const SUBSCRIPTION_PENDING_STATUS_ID = 3;
export const SUBSCRIPTION_CANCELED_STATUS_ID = 4;

export const SUBSCRIPTION_NONE_STATUS_TITLE = "Inactive";
export const SUBSCRIPTION_ACTIVE_STATUS_TITLE = "Active";
export const SUBSCRIPTION_INACTIVE_STATUS_TITLE = "Inactive";
export const SUBSCRIPTION_PENDING_STATUS_TITLE = "Pending";
export const SUBSCRIPTION_CANCELED_STATUS_TITLE = "Canceled";


export const SUBSCRIPTION_PLANS = [
  {
    title: "Monthly Plan",
    currency: "$",
    amount: "9.99",
    period: "month",
    id: SUBSCRIPTION_MONTHLY_TYPE_ID,
    badge: 0,
  },
  {
    title: "Yearly Plan",
    currency: "$",
    amount: "79.99",
    period: "year",
    id: SUBSCRIPTION_YEARLY_TYPE_ID,
    badge: 1,
  }
]

export const SUBSCRIPTION_TYPES = {
  [SUBSCRIPTION_NONE_TYPE_ID]: SUBSCRIPTION_NONE_TYPE_TITLE,
  [SUBSCRIPTION_MONTHLY_TYPE_ID]: SUBSCRIPTION_MONTHLY_TYPE_TITLE,
  [SUBSCRIPTION_YEARLY_TYPE_ID]: SUBSCRIPTION_YEARLY_TYPE_TITLE,
  [SUBSCRIPTION_FREE_TRIAL_TYPE_ID]: SUBSCRIPTION_FREE_TRIAL_TYPE_TITLE,
  [SUBSCRIPTION_ORGANISATION_TYPE_ID]: SUBSCRIPTION_ORGANISATION_TYPE_TITLE,
  [SUBSCRIPTION_UNLIMITED_TYPE_ID]: SUBSCRIPTION_UNLIMITED_TYPE_TITLE,
  [SUBSCRIPTION_FREE_BETA_TESTER_TYPE_ID]: SUBSCRIPTION_FREE_BETA_TESTER_TYPE_TITLE,
}

export const SUBSCRIPTION_STATUSES = {
  [SUBSCRIPTION_NONE_STATUS_ID]: SUBSCRIPTION_NONE_STATUS_TITLE,
  [SUBSCRIPTION_ACTIVE_STATUS_ID]: SUBSCRIPTION_ACTIVE_STATUS_TITLE,
  [SUBSCRIPTION_INACTIVE_STATUS_ID]: SUBSCRIPTION_INACTIVE_STATUS_TITLE,
  [SUBSCRIPTION_PENDING_STATUS_ID]: SUBSCRIPTION_PENDING_STATUS_TITLE,
  [SUBSCRIPTION_CANCELED_STATUS_ID]: SUBSCRIPTION_CANCELED_STATUS_TITLE,
}

export const SUBSCRIPTION_SWITCH_MESSAGES = {
  [SUBSCRIPTION_YEARLY_TYPE_ID]: "You have changed your Monthly plan to Yearly plan!",
  [SUBSCRIPTION_MONTHLY_TYPE_ID]: "You have changed your Yearly plan to Monthly plan!"
}

export const SUBSCRIPTIONS_DISPLAY_PROPERTIES = {
  [SUBSCRIPTION_NONE_TYPE_ID]: {
    paymentMethod: true,
    expiryDate: false,
    changeSubscriptionPlanButton: false,
    cancelAutoRenewal: false,
    billingHistory: true,
  },
  [SUBSCRIPTION_MONTHLY_TYPE_ID]: {
    paymentMethod: true,
    expiryDate: true,
    changeSubscriptionPlanButton: true,
    cancelAutoRenewal: true,
    billingHistory: true,
  },
  [SUBSCRIPTION_YEARLY_TYPE_ID]: {
    paymentMethod: true,
    expiryDate: true,
    changeSubscriptionPlanButton: true,
    cancelAutoRenewal: true,
    billingHistory: true,
  },
  [SUBSCRIPTION_FREE_TRIAL_TYPE_ID]: {
    paymentMethod: true,
    expiryDate: true,
    changeSubscriptionPlanButton: false,
    cancelAutoRenewal: false,
    billingHistory: true,
  },
  [SUBSCRIPTION_ORGANISATION_TYPE_ID]: {
    paymentMethod: false,
    expiryDate: false,
    changeSubscriptionPlanButton: false,
    cancelAutoRenewal: false,
    billingHistory: false,
  },
  [SUBSCRIPTION_UNLIMITED_TYPE_ID]: {
    paymentMethod: false,
    expiryDate: false,
    changeSubscriptionPlanButton: false,
    cancelAutoRenewal: false,
    billingHistory: false,
  },
  [SUBSCRIPTION_FREE_BETA_TESTER_TYPE_ID]: {
    paymentMethod: false,
    expiryDate: false,
    changeSubscriptionPlanButton: false,
    cancelAutoRenewal: false,
    billingHistory: false,
  },
}

export const SUBSCRIPTION_TYPES_TRANSACTION = {
  [SUBSCRIPTION_NONE_TYPE_ID]: "membership",
  [SUBSCRIPTION_MONTHLY_TYPE_ID]: "1 month membership",
  [SUBSCRIPTION_YEARLY_TYPE_ID]: "1 year membership",
  [SUBSCRIPTION_FREE_TRIAL_TYPE_ID]: SUBSCRIPTION_FREE_TRIAL_TYPE_TITLE,
  [SUBSCRIPTION_ORGANISATION_TYPE_ID]: SUBSCRIPTION_ORGANISATION_TYPE_TITLE,
  [SUBSCRIPTION_UNLIMITED_TYPE_ID]: SUBSCRIPTION_UNLIMITED_TYPE_TITLE,
  [SUBSCRIPTION_FREE_BETA_TESTER_TYPE_ID]: SUBSCRIPTION_FREE_BETA_TESTER_TYPE_TITLE,
}

export const SUBSCRIPTION_ERRORS = [
  {
    error: "The maximum number of trial period days is 730 (2 years).",
    message: "Subscription period must be lower than 2 years."
  }
]