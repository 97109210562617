import React, { useState } from "react";
import Card from "./components/Card";
import Actions from "./components/Actions";
import CompletedCardLesson from "./components/CompletedCard";
import { useNavigate } from "react-router-dom";
import { LESSON_DETAILS_LINKS } from "../../../groups/lesson/config";
import { IoBulbOutline } from "react-icons/io5";
import closeIcon from "../../assets/close-outline.svg";
import { PRACTICE_GROUP_LINKS } from "../../../groups/practice/config";
import ActionsPractice from "./components/ActionsPractice";
import { twMerge } from "tailwind-merge";
import CompletedCard from "../CompletedCard";
import { ExerciseHeader } from "../ExerciseHeader";

export default function FlashcardList({
  currentCard,
  currentCardIndex,
  totalCardsCount,
  onNextClick,
  onBackLick,
  lessonId,
  onFavoriteClick,
  isPracticePage,
}) {
  const [isFlipped, setIsFlipped] = useState(false);
  const navigate = useNavigate();

  const isCompleted = currentCardIndex === totalCardsCount;

  return (
    <div className="w-full h-full flex flex-col justify-between items-center ">
      {isPracticePage && !isCompleted && (
        <ExerciseHeader
          title="Do you remember these words?"
          closeLink={PRACTICE_GROUP_LINKS.BASE}
          isHint={false}
        />
      )}
      {!isCompleted && (
        <Card
          flashcard={currentCard}
          isFlipped={isFlipped}
          onFlip={() => setIsFlipped(true)}
          onFavoriteClick={onFavoriteClick}
          isPracticePage={isPracticePage}
        />
      )}
      {isCompleted && !isPracticePage && <CompletedCardLesson />}
      {isCompleted && isPracticePage && (
        <CompletedCard sectionTitle="Vocabulary" />
      )}

      {!isPracticePage && (
        <Actions
          onNextClick={() => {
            if (isCompleted) {
              navigate(
                `${LESSON_DETAILS_LINKS.BASE}/${lessonId}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_CONTENT}`
              );
            } else {
              onNextClick();
              setIsFlipped(false);
            }
          }}
          onBackClick={() => {
            onBackLick();
            setIsFlipped(false);
          }}
          currentCardIndex={currentCardIndex}
          isCompleted={isCompleted}
        />
      )}
      {isPracticePage && !isCompleted && (
        <ActionsPractice
          onNextClick={() => {
            onNextClick();
            setIsFlipped(false);
          }}
          onBackClick={() => {
            onBackLick();
            setIsFlipped(false);
          }}
          currentCardIndex={currentCardIndex}
        />
      )}
    </div>
  );
}
