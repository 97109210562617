import Http from './Http'

class SubscriptionsService extends Http {
  static $displayName = "SubscriptionsService";

  async buySubscription(values) {
    return await this.post(`/subscriptions`, values);
  }

  async updateSubscription(values) {
    return await this.patch(`/subscriptions`, values);
  }

  async cancelSubscription(values) {
    return await this.delete(`/subscriptions`, { data: values });
  }

  async activateSubscription() {
    return await this.put(`/subscriptions`);
  }

  async getSubscription() {
    return await this.get(`/subscriptions`);
  }
}

export default SubscriptionsService;