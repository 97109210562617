import React, { useCallback, useEffect, useState } from "react";
import { useService } from "../../../../base/hooks/useService";
import LearnService from "../../../../services/LearnService";
import { useLoading } from "../../../../base/hooks/useLoading";
import Spinner from "../../../../base/components/Spinner";
import { Link, useParams } from "react-router-dom";
import { CUSTOM_LESSONS_LINKS_ID, CUSTOM_LESSONS_LINKS_TITLE } from "../../../../base/constants/customLessons";
import InfiniteScroll from "react-infinite-scroll-component";
import LessonsByTopic from "./components/LessonsByTopic";
import { LEARN_GROUP_LINKS } from "../../config";
import { IoIosArrowBack } from "react-icons/io";
import SubscriptionsService from "../../../../services/SubscriptionsService";
import {checkSubscription} from "../helpers/checkSubscription";

const CustomLearning = () => {
  /**
   * @type {LearnService}
   */
  const learnService = useService(LearnService);
  const subscriptionsService = useService(SubscriptionsService);

  const { link } = useParams();
  const categoryId = CUSTOM_LESSONS_LINKS_ID?.[link];

  const [isLoading, { registerPromise }] = useLoading();
  const [isLoadingSubscription , { registerPromise: registerPromiseSubscription }] = useLoading();

  const [topics, setTopics] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(null)

  const fetchTopics = useCallback(() => {
    registerPromise(
      learnService.getLessonsTopics(
        {
          categoryId
        })
    ).then(({ data }) => {
      setTopics(data);
    });
  }, [registerPromise, learnService]);

  const fetchSubscription = useCallback(() => {
    registerPromiseSubscription(
      subscriptionsService.getSubscription().then((data) => {
        setIsSubscribed(checkSubscription(data?.status, data?.expiresAt) );
      }).catch(() => setIsSubscribed(false))
    )
  },[subscriptionsService, setIsSubscribed])

  useEffect(() => {
    fetchSubscription();
    fetchTopics();
  }, []);

  const isTopicsNotLoaded = isLoading && !topics?.length;
  const isSubscriptionNotLoaded = isLoadingSubscription || isSubscribed === null;

  if (isTopicsNotLoaded || isSubscriptionNotLoaded)
    return <Spinner className={"spinner"} />;

  return (
    <>
      <Link
        to={LEARN_GROUP_LINKS.LINK_CUSTOM_LEARNING}
      >
        <div className="font-bold sm:text-md lg:text-lg text-purple mt-4 mb-1.5 flex flex-row items-center" >
          <IoIosArrowBack className="align-middle w-3 h-3 mr-1.5"/>{" "}<div>{CUSTOM_LESSONS_LINKS_TITLE?.[link] || "Back"}</div>
        </div>
      </Link>

      <div
        className="overflow-y-auto h-full pb-2.5 -mx-4 px-4 lg:mx-0 lg:px-0"
        id="scrollableDivCustom"
      >
        {!!topics?.length && (
          <>
            <InfiniteScroll
              dataLength={topics?.length}
              className="-mx-4 px-4 pb-4 lg:mx-0 lg:px-0 lg:pb-0 lg:pb-16 after:transition-all"
              scrollableTarget="scrollableDivCustom"
            >
              <>
                {topics.map((topic, index) => {
                  const { id, lessons, title, lessonsCount, completedLessonsCount } = topic || {};

                  return (
                    <LessonsByTopic
                      key={index}
                      categoryId={categoryId}
                      topicId={id}
                      initialLesson={lessons?.[0] || {}}
                      title={title}
                      count={lessonsCount}
                      completed={completedLessonsCount}
                      closeDetailsRedirectLink={`${LEARN_GROUP_LINKS.LINK_CUSTOM_LEARNING}/${link}`}
                      isSubscribed={isSubscribed}
                    />
                  )
                })}
              </>
            </InfiniteScroll>
          </>
        )}
      </div>
    </>
  );
}

export default CustomLearning;