import React from "react";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

import { LessonCardInfo } from "./LessonCardInfo";
import { LessonCardActions } from "./LessonCardActions";

import { lessonType } from "./types";

export const LessonCard = ({
  lesson: {
    id,
    title,
    file,
    description,
    name,
    isCompleted,
    isFavorite,
  },
  closeDetailRedirect,
  defaultDetailTab,
  isNextLesson,
  isDarkStyle,
  onBookmark,
  isStartAllowed,
  isCompleteHighlight,
}) => {
  const { link = "" } = file || {};

  return (
    <div
      className={twMerge(
        `p-[10px] w-full flex flex-wrap rounded-[10px] lg:py-4 lg:px-[30px] justify-between items-stretch ${
          isDarkStyle ? "dark-lesson" : "light-lesson"
        } ${
          isCompleteHighlight && "bg-cover"
        }`
      )}
    >
      <LessonCardInfo
        isNextLesson={isNextLesson}
        title={title}
        iconLink={link}
        name={name}
        isCompleted={isCompleted}
        isDarkStyle={isDarkStyle}
        isCompleteHighlight={isCompleteHighlight}
      />
      <LessonCardActions
        id={id}
        description={description}
        isDarkStyle={isDarkStyle}
        isBookmarked={isFavorite}
        closeDetailRedirect={closeDetailRedirect}
        defaultDetailTab={defaultDetailTab}
        onClickBookmark={onBookmark}
        isStartAllowed={isStartAllowed}
      />
    </div>
  );
};

LessonCard.propTypes = {
  lesson: lessonType,
  closeDetailRedirect: PropTypes.string,
  defaultDetailTab: PropTypes.string,
  isNextLesson: PropTypes.bool,
  isDarkStyle: PropTypes.bool,
  isStartAllowed: PropTypes.bool,
  isCompleteHighlight: PropTypes.bool,
};
