import React, {useCallback, useState} from "react";
import Button from "../../../base/components/Button";
import CompleteButton from "./CompleteButton";
import Header from "./Header";
import { LESSON_DETAILS_LINKS } from "../config";
import { useNavigate } from "react-router-dom";
import { formatHTMLContent } from "../helpers/formatHTML";
import LessonsLightBox from "../../../base/components/ImagesLightBox";

const LG = "font-lg-pure";
const MD = null;
const SM = "font-sm-pure";

const fontSizesArr = [
  {
    className: "text-base",
    lessonFont: SM,
  },
  {
    className: "text-[21px]",
    lessonFont: MD,
  },
  {
    className: "text-[30px]",
    lessonFont: LG,
  },
];

export default function ExtraInfo({ lesson, onCompleteClick }) {
  const navigate = useNavigate();
  const [lessonFontSize, setLessonFontSize] = useState(MD);
  const [openedImageSrc, setOpenedImageSrc] = useState("");

  const onImgClick = (event) => {
    if (event.target instanceof HTMLImageElement) {
      setOpenedImageSrc(event.target.src)
    }
  }

  const handleLightBoxClose = useCallback(() => setOpenedImageSrc(""), [setOpenedImageSrc])

  const activeClass = "font-bold bg-purple text-white";

  return (
    <div className="py-8 h-full">
      <Header>
        <h1>{lesson?.name}</h1>
        <div className=" hidden lg:grid leading-1 grid grid-rows-[32px] grid-cols-[repeat(3,32px)] grid-flow-col rounded-[5px] border border-purple text-purple items-center justify-items-center">
          {fontSizesArr.map((item, index) => {
            return (
              <p
                key={index}
                className={`w-full flex items-center justify-center h-full max-h-[32px] ${
                  item.className
                } ${lessonFontSize === item.lessonFont && activeClass}`}
                onClick={() => setLessonFontSize(item.lessonFont)}
              >
                a
              </p>
            );
          })}
        </div>
      </Header>

      <div
        className={`lesson-content ${lessonFontSize && lessonFontSize}`}
        dangerouslySetInnerHTML={{
          __html: formatHTMLContent(lesson?.extraInfo),
        }}
        onClick={onImgClick}
      ></div>

      <div className="grid grid-flow-row gap-y-[10px] gap-x-5 grid-cols-1 lg:grid-cols-auto items-center justify-center justify-items-center mt-[120px] lg:mt-[133px]">
        <CompleteButton lesson={lesson} onCompleteClick={onCompleteClick} />
        {lesson?.nextLessonId && (
          <Button
            className="btn-purple lg:w-[260px] text-base h-10 w-full "
            onClick={() =>
              navigate(
                `${LESSON_DETAILS_LINKS.BASE}/${lesson?.nextLessonId}${LESSON_DETAILS_LINKS.LINK_TO_LESSON_CONTENT}`
              )
            }
          >
            Next Lesson
          </Button>
        )}
      </div>
      <LessonsLightBox src={openedImageSrc} onClose={handleLightBoxClose} />
    </div>
  );
}
