import PropTypes from "prop-types";

export const answerType = PropTypes.shape({
  id: PropTypes.number,
  questionId: PropTypes.number,
  nextQuestionId: PropTypes.number,
  title: PropTypes.string,
});

export const userAnswerType = PropTypes.shape({
  answerId: PropTypes.number,
  questionId: PropTypes.number,
});

export const questionType = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  answers: PropTypes.arrayOf(answerType),
  userAnswer: userAnswerType,
});
