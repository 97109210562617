import React, { useCallback, useEffect, useState } from "react";

import { LessonCard } from "../../../components/LessonCard";
import Button from "../../../../../base/components/Button";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";
import {useService} from "../../../../../base/hooks/useService";
import LearnService from "../../../../../services/LearnService";
import {useLoading} from "../../../../../base/hooks/useLoading";
import Spinner from "../../../../../base/components/Spinner";
import {LESSON_DETAILS_LINKS} from "../../../../lesson/config";
import AccessRestricted from "../../../../../base/components/AccessRestricted";
import {updateObjectInArray} from "../../helpers/updateObjectInArray";

const LIMIT = 100;
const OFFSET = 0;

const LessonsByTopic = ({
  title,
  categoryId,
  topicId,
  initialLesson,
  count,
  completed,
  closeDetailsRedirectLink,
  isSubscribed
}) => {
  /**
   * @type {LearnService}
   */
  const learnService = useService(LearnService);
  const [isLoading, { registerPromise }] = useLoading();
  const [lessons, setLessons] = useState([]);
  const [initialLessonState, setInitialLessonsState] = useState(initialLesson || {})
  const [isViewAll, setIsViewAll] = useState(false);

  const Icon = isViewAll ? MdKeyboardArrowUp : MdKeyboardArrowDown;

  const findInitialLesson = (array, initialLesson) => array.find(item => item?.id === initialLesson?.id) || initialLesson;

  const getLessonsByTopic = useCallback(() => {
    registerPromise(learnService.getLessons(LIMIT,OFFSET, { categoryId, topicId } )).then(({ data }) => {
      setLessons(data);
    });
  },[registerPromise, learnService])

  useEffect(() => {
    if (isViewAll) {
      getLessonsByTopic();
    } else {
      setLessons([])
    }

    return () => setLessons([]);
  }, [isViewAll])

  const onClickBookmark = useCallback((id, isFavorite) => {
    registerPromise(
      learnService.setComplete(id, {
        userLesson: {
          isFavorite: !isFavorite,
        }
      }).then(() => {
        if (initialLessonState.id === id ) {
          setInitialLessonsState(prev => ({...prev, isFavorite: !isFavorite}))
        }

        setLessons((prev) => {
          const oldElement = prev.find(item => item.id === id);
          const newElement = { ...oldElement, isFavorite: !isFavorite }
          return [...updateObjectInArray('id', id, newElement, prev)]
        })
      })
    )
  },[learnService, setLessons])

  useEffect(() => {
    if (lessons.length) {
      setInitialLessonsState(findInitialLesson(lessons, initialLesson));
    }
  }, [lessons])

  return (
    <>
      <h4 className=" lg:hidden my-2.5 text-xl lg:text-xl lg:leading-[44px]  lg:ml-0 lg:mb-[5px]">
        {title}
      </h4>
      {!isViewAll && (
        <div className="lg:hidden pt-[5px] relative shadow-lesson-mob rounded-[10px]">
          <LessonCard
            lesson={initialLessonState || {}}
            isNextLesson={!!completed}
            closeDetailRedirect={closeDetailsRedirectLink}
            defaultDetailTab={LESSON_DETAILS_LINKS.LINK_TO_LESSON_VOCABULARY}
            onBookmark={onClickBookmark}
          />
        </div>
      )}
      <div className="pt-[5px] relative shadow-lesson-mob  lg:shadow-none rounded-[10px] lg:pt-0 lg:rounded-0 mt-[10px] lg:mt-5">
        <h4 className="  ml-[10px] text-md lg:text-xl lg:leading-[44px]  lg:ml-0 lg:mb-[5px]">
          <span className="hidden lg:block">{title}</span>
          <span className="block lg:hidden">Lessons</span>
        </h4>
        <div
          className="flex w-full lg:bg-teal-dark lg:bg-lessons-group  flex-col rounded-[10px] lg:pt-7 lg:pl-7 lg:pr-9 lg:pb-[10px]"
        >
          {isViewAll ? (
            <div className=" gap-y-2 order-2 grid grid-auto-cols lg:gap-y-5">
              {!lessons?.length && isLoading && isViewAll &&
                <div className={`bg-white p-[10px] w-full rounded-[10px] lg:py-4 lg:px-[30px] `}>
                  <Spinner className={"spinner"} />
                </div>}

              {!!lessons?.length && !isLoading && !isSubscribed && (
                <AccessRestricted
                  subtitle="Subscribe to Cognate to unlock all of your custom lessons!"
                  visibleElements={
                    <div
                      className="border-border border-t-[3px] lg:border-0 mb-2 lg:mb-5"
                    >
                      <LessonCard
                        lesson={lessons?.[0]}
                        closeDetailRedirect={closeDetailsRedirectLink}
                        onBookmark={onClickBookmark}
                      />
                    </div>
                  }
                >
                  <div className="gap-y-2 order-2 grid grid-auto-cols lg:gap-y-5">
                    {lessons.slice(1).map((lesson) => (
                      <div
                        className="border-border border-t-[3px] lg:border-0"
                        key={lesson.id}
                      >
                        <LessonCard
                          lesson={lesson}
                          closeDetailRedirect={closeDetailsRedirectLink}
                          onBookmark={onClickBookmark}
                        />
                      </div>
                    ))}
                  </div>
                </AccessRestricted>
              )}

              {!!lessons?.length && isSubscribed && lessons.map((lesson) => (
                <div
                  className="border-border border-t-[3px] lg:border-0"
                  key={lesson.id}
                >
                  <LessonCard
                    lesson={lesson}
                    closeDetailRedirect={closeDetailsRedirectLink}
                    onBookmark={onClickBookmark}
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="hidden lg:block">
              <LessonCard
                lesson={initialLessonState}
                isNextLesson={!!completed}
                closeDetailRedirect={closeDetailsRedirectLink}
                defaultDetailTab={LESSON_DETAILS_LINKS.LINK_TO_LESSON_VOCABULARY}
                onBookmark={onClickBookmark}
              />
            </div>
          )}
          <div className="order-1 w-full flex justify-between items-center lg:order-2 lg:mt-[14px]">
            <>
              <p className="ml-[10px] mb-[5px] text-xs text-font lg:text-[21px] lg:ml-0  lg:mb-0 lg:text-white lg:font-bold">
                Completed {completed} / {count}
              </p>
              <Button
                className="hidden lg:flex text-bg-light-grey font-bold  items-center justify-center"
                onClick={() => setIsViewAll((prev) => !prev)}
              >
              <span className="mr-1">
                {isViewAll ? "View Less" : "View All Lessons"}
              </span>
                <Icon size={15} className="fill-[currentColor]" />
              </Button>
            </>
          </div>
          <div className="absolute text-font top-4 right-4 right-0 lg:hidden">
            <Button onClick={() => setIsViewAll((prev) => !prev)}>
              <Icon size={30} className="fill-[currentColor]" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default LessonsByTopic;