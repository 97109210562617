import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./store";

import { CurrentUserProvider } from "./base/contexts/CurrentUser";

const Context = ({ children }) => (
  <Provider store={store}>
    <CurrentUserProvider>
      <BrowserRouter>{children}</BrowserRouter>
    </CurrentUserProvider>
  </Provider>
);

Context.propTypes = {
  children: PropTypes.any,
};

export default Context;
