import toast from "react-hot-toast";
import { LIGHT_PURPLE, PURPLE, RED } from "../constants/colors";

export function useToaster() {
  const displayToaster = (msg = "Something went wrong...") => {
    toast.success(msg, {
      duration: 4000,
      position: "top-right",
      style: {
        background: LIGHT_PURPLE,
      },
      className: "h-[44px] pr-[100px] text-purple border border-purple",
      iconTheme: {
        primary: PURPLE,
      },
    });
  };
  const errorToaster = (msg = "Something went wrong...") => {
    toast.error(msg, {
      duration: 4000,
      position: "top-right",
      className:
        "min-h-[44px] pr-[30px] bg-[#FFF7F7] text-red border border-red max-h-full",
      iconTheme: {
        primary: RED,
      },
      success: {
        duration: 300000,
      },
    });
  };

  return { displayToaster, errorToaster };
}
