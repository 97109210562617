import {ERROR_CODES} from "../../../base/constants/errorCodes";
import {PROFILE_GROUP_LINKS} from "../../profile/config";

const checkAccessError = (data) => {
  const { errorCode } = data || {};

  if (errorCode === ERROR_CODES.NOT_ALLOWED_TO_SEE_THIS_LESSON_WITHOUT_SUBSCRIPTION) {
    return PROFILE_GROUP_LINKS.LINK_TO_ACCOUNT_INFORMATION;
  }

  return null
}

export default checkAccessError;