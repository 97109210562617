import React, { useCallback, useEffect, useState } from "react";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";

import AuthService from "../../../../services/AuthService";
import ThankCard from "../../components/ThankCard";

import {
  VERIFICATION_EMAIL_ERROR_MESSAGE,
  VERIFICATION_NEW_LINK_SENT_MESSAGE,
  VERIFICATION_SENT_MESSAGE,
  VERIFICATION_SUCCESS_MESSAGE,
} from "../../../../base/constants/messages";
import ExpiredLink from "../../components/ExpiredLink";

const Verification = () => {
  const [isLoading, { registerPromise }] = useLoading();
  const [, setErrorMessage] = useState("");
  const [isLinkExpired, setIsLinkExpired] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [isSuccessfullyVerified, setIsSuccessfullyVerified] = useState(false);

  const [successMessage, setSuccessMessage] = useState(
    VERIFICATION_SENT_MESSAGE
  );

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  const {
    params: { code, email },
  } = useLocationQuery();

  const verifyToken = useCallback(
    async (values) => {
      try {
        const response = await authService.verifyUser(values);

        if (response) {
          setSuccessMessage(VERIFICATION_SUCCESS_MESSAGE);
        }
      } catch {
        setErrorMessage(VERIFICATION_EMAIL_ERROR_MESSAGE);
      }
    },
    [authService]
  );

  const checkValidToken = useCallback(
    (token) => {
      registerPromise(
        authService
          .checkValidToken(token)
          .then(() => {
            setIsSuccessfullyVerified(true);
            setSuccessMessage(VERIFICATION_SUCCESS_MESSAGE);
          })
          .catch(() => {
            setIsLinkExpired(true);
          })
      );
    },
    [registerPromise, authService, verifyToken]
  );

  const resendVerificationLink = async (values) => {
    try {
      await authService.resendVerificationLink({
        email: email || values.email,
      });
      setSuccessMessage(VERIFICATION_NEW_LINK_SENT_MESSAGE);
      setIsLinkSent(true);
    } catch (error) {
      setErrorMessage(error?.message);
    }
  };

  useEffect(() => {
    if (code) {
      checkValidToken(code);
    }
  }, []);

  return (
    <>
      {isLinkExpired && !isLinkSent && (
        <ExpiredLink
          title="The link is expired"
          onResend={resendVerificationLink}
          description="Your reset link has expired after 24 hours or has already been used. Please click the button below to resend the link."
        />
      )}
      {(!isLinkExpired || isLinkSent) && !isLoading && (
        <ThankCard
          title="Success!"
          description={successMessage}
          buttonTitle="Resend link"
          isBackButton
          isResendButton={
            !isLinkExpired && !isLinkSent && !isSuccessfullyVerified
          }
          onClick={resendVerificationLink}
        />
      )}
    </>
  );
};

export default Verification;
