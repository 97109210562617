import {
  MAX_COUNT_TO_RESENT,
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from "../../validation/lengthConstants";

export const VERIFICATION_SUCCESS_MESSAGE =
  "Email has been successfully verified";
export const VERIFICATION_SENT_MESSAGE =
  "Please check your email to verify your account.";
export const VERIFICATION_NEW_LINK_SENT_MESSAGE =
  "New verification link has been sent";
export const VERIFICATION_EMAIL_ERROR_MESSAGE = "Email is not verified";

export const FORGOT_PASSWORD_LIMIT_ERROR_MESSAGE = `You can only request for a reset ${MAX_COUNT_TO_RESENT} times a day.`;
export const FORGOT_PASSWORD_EMAIL_NOT_FOUND_ERROR_MESSAGE =
  "This email is not registered with Cognate. Please try again";

export const RESTORE_PASSWORD_SUCCESS_MESSAGE =
  "Your password has been successfully reset";
export const RESEND_RESTORE_PASSWORD_SUCCESS_MESSAGE =
  "A new email to reset your password has been sent";

export const VALIDATION_ERROR_MESSAGE_EMAIL_FORMAT =
  "Please enter a valid email address";
export const VALIDATION_ERROR_MESSAGE_EMAIL_REQUIRED = "Please enter the email";
export const VALIDATION_ERROR_MESSAGE_EMAIL_EXPIRED = `You can only request for a reset ${MAX_COUNT_TO_RESENT} times a day.`;

export const VALIDATION_ERROR_MESSAGE_PASSWORD_FORMAT =
  "Password should contain at least 1 digit, 1 upper and lowercase character";
export const VALIDATION_ERROR_MESSAGE_PASSWORD_LENGTH = `Password should be between ${MIN_PASSWORD_LENGTH} and ${MAX_PASSWORD_LENGTH} characters`;
export const VALIDATION_ERROR_MESSAGE_PASSWORD_REQUIRED =
  "Please enter the password";
export const VALIDATION_ERROR_MESSAGE_REPEAT_PASSWORD_REQUIRED =
  "Please confirm your new password";
export const VALIDATION_ERROR_MESSAGE_PASSWORD_MATCH =
  "Passwords do not match, please try again";

export const VALIDATION_ERROR_MESSAGE_NAME_FORMAT =
  "Cannot contain digits or symbols";
export const VALIDATION_ERROR_MESSAGE_NAME_SPACES =
  "Cannot contain only spaces";
export const VALIDATION_ERROR_MESSAGE_FIRST_NAME_REQUIRED =
  "Please enter your first name";
export const VALIDATION_ERROR_MESSAGE_LAST_NAME_REQUIRED =
  "Please enter your last name.";

export const EDIT_PROFILE_SUCCESS_MESSAGE = "Your profile is updated";
export const EDIT_EMAIL_SUCCESS_MESSAGE = "Your email is updated";
export const EDIT_PASSWORD_SUCCESS_MESSAGE = "Your password is updated";
export const EDIT_EMAIL_USER_EXIST_ERROR_MESSAGE =
  "This email has already been registered";
export const EDIT_PROFILE_WRONG_PASSWORD_ERROR_MESSAGE =
  "Wrong password. Please try again";

export const WRONG_REFERRAL_CODE_ERROR_MESSAGE =
  "This promo code does not exist";

export const VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_REQUIRED =
  "Please enter cardholder name";
export const VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_MAX_LENGTH =
  "cardholder name is too long";
export const VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_SPACES =
  "Cardholder name cannot contain only spaces";
export const VALIDATION_ERROR_MESSAGE_CARDHOLDER_NAME_FORMAT =
  "Cardholder name cannot contain digits or symbols";

export const VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_MAX_LENGTH =
  "Other text can be up to 200 symbols;";
export const VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_REQUIRED =
  "“Other” field is required";
export const VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_SPACES =
  "“Other” field can’t contain spaces only";
