import * as yup from "yup";
import {
  MAX_CANCEL_AUTO_RENEWAL_OTHER_LENGTH,
  MIN_CANCEL_AUTO_RENEWAL_OTHER_LENGTH,
} from "../../../../../../../../validation/lengthConstants";
import {
  VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_MAX_LENGTH,
  VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_REQUIRED,
  VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_SPACES,
} from "../../../../../../../../base/constants/messages";

export const validationSchema = yup.object().shape({
  cancellationReason: yup.string()
    .min(MIN_CANCEL_AUTO_RENEWAL_OTHER_LENGTH, VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_REQUIRED)
    .max(MAX_CANCEL_AUTO_RENEWAL_OTHER_LENGTH, VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_MAX_LENGTH)
    .trim(VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_SPACES)
    .required(VALIDATION_ERROR_MESSAGE_AUTO_RENEWAL_OTHER_REQUIRED),
});

export const validateField = async (fieldName, fieldValue) => {
  try {
    await validationSchema.validate({ [fieldName]: fieldValue });
    return { [fieldName]: "" };
  } catch (e) {
    return { [fieldName]: e.message };
  }
}