import React from "react";
import PropTypes from "prop-types";

import LinkCard from "../../../base/components/LinkCard";

export const Menu = ({ schema }) => {
  return (
    <div className="lg:mt-[160px]">
      <h4 className="text-2xl leading-[51px] mb-[34px] lg:mb-[50px] text-center">
        Let&apos;s practice!
      </h4>
      <div className="w-full grid grid-rows-[repeat(3,100px)] gap-y-5 lg:gap-y-0 lg:grid-cols-3 lg:grid-rows-[248px] lg:gap-x-5">
        {schema.map(({ link, title, icon }) => (
          <LinkCard link={link} key={title}>
            <div className="pl-6 pr-[45px] lg:py-6 lg:px-0 h-full flex flex-row-reverse lg:flex-col lg:justify-between justify-end  items-center">
              <h4 className="text-white text-left ml-[19px] lg:ml-0 lg:text-center text-[21px] lg:text-[24px] leading-[37px] lg:leading-[48px]">
                {title}
              </h4>
              <div className="flex items-center lg:justify-center  h-[58px] w-20 lg:w-[210px] lg:h-[133px]">
                <img src={icon} className="max-h-full" />
              </div>
            </div>
          </LinkCard>
        ))}
      </div>
    </div>
  );
};

Menu.propTypes = {
  schema: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
};
