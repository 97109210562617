import * as React from "react";
import PropTypes from "prop-types";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import {useEffect, useState} from "react";

const check = () => !window.matchMedia(`(min-width: 1024px)`).matches;

const useMobileMedia = () => {
  const [isMobile, setIsMobile] = useState(check());

  useEffect(() => {
    const handler = () => {
      setIsMobile(check())
    }
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, [])

  return isMobile
}

const AppLayout = ({ children, schema }) => {
  const isMobile = useMobileMedia();

  return (
    <div className="flex flex-col lg:block h-full">
      {isMobile && <Header />}
      <div className="layout">
        <Sidebar schema={schema} isMobile={isMobile} />
        <div className="main-content ">
          <main className="flex flex-col relative w-full bg-white h-full lg:rounded-l-3xl overflow-hidden">
            <div className="main-inner flex-auto">{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
};

AppLayout.propTypes = {
  children: PropTypes.any,
  schema: PropTypes.any,
};

export default AppLayout;
