import React from "react";
import { Field } from "formik";
import { answerType } from "../../../types";

export default function Answer({ title, id, type }) {
  return (
    <label className="cursor-pointer leading-loose text-sm text-font mb-1 lg:text-base  lg:mb-2">
      <Field
        type={type}
        name="answer"
        id={id}
        value={title}
        className=" mr-3 focus:shadow-none focus:ring-inset focus:ring-offset-0 focus:outline-0 focus:ring-transparent"
      />
      {title}
    </label>
  );
}

Answer.propTypes = answerType;
