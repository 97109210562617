import React from "react";
import PropTypes from "prop-types";

import Button from "../../../base/components/Button";
import { Modal } from "../../../base/components/Modal";
import catIcon from "../../auth/assets/happy-cat.svg";
import catIconMob from "../assets/happy-cat-mob.svg";

export const SuccessModal = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen}>
      <div className="flex flex-col items-center justify-center p-5  lg:px-[44px] lg:py-5 relative">
        <h2 className="text-[21px] font-semibold text-light-black-2 leading-[38px] text-center">
          Thank you for submitting the error!
        </h2>
        <Button
          onClick={onClose}
          className="btn-purple text-[21px] lg:text-base h-[60px] lg:h-10 w-full lg:w-[190px] mt-5 lg:mt-[30px] "
        >
          Close
        </Button>
      </div>
      <img
        src={catIcon}
        className="hidden lg:block -translate-x-1/3  absolute lg:h-[122px] -top-[105px] left-1/2"
      />
      <img
        src={catIconMob}
        className="block lg:hidden -translate-x-1/3  absolute  h-[153px] -top-[140px] left-1/2"
      />
    </Modal>
  );
};

SuccessModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
