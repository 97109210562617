import React from "react";
import { Button } from "reactstrap";
import PropTypes from "prop-types";
import { FiCheck } from "react-icons/fi";

const IS_COMPLETED_BUTTON_TEXT = "Change Answers";
const IS_NOT_COMPLETED_BUTTON_TEXT = "Take Questionnaire";

const QuestionnaireCard = ({title, completed, onClickButton}) => {

  return (
    <div className={`
      flex flex-col justify-between 
      w-full h-[146px] lg:h-[180px] rounded-[15px] 
      py-[16px] lg:py-[10px] px-[10px] lg:px-[30px] box-border
      bg-purple bg-blend-multiply bg-right bg-no-repeat bg-80% bg-lesson-category bg-contain
      ${completed && "border-solid border-[3px] border-teal-dark"}
      `}
    >
      <div className="relative">
        {completed &&
          <div className="absolute bg-teal-light px-[5px] py-[5px] top-[-6px] lg:top-[0px] right-[0px] lg:right-[-20px] rounded-[100px]">
            <FiCheck className="text-white"/>
          </div>}
      </div>
      <div className="flex flex-col justify-end">
        <p
          className="mb-[15px] text-white leading-[20px] font-bold text-center tracking-tighter md:tracking-normal whitespace-nowrap lg:whitespace-pre-wrap"
        >
          {title}
        </p>
        <Button
          className="btn-back h-[40px] w-full text-base rounded-[10px]"
          onClick={onClickButton}
        >
          {completed ? IS_COMPLETED_BUTTON_TEXT : IS_NOT_COMPLETED_BUTTON_TEXT}
        </Button>
      </div>
    </div>
  )
}

QuestionnaireCard.propTypes = {
  title: PropTypes.string,
  completed: PropTypes.bool,
  onClickButton: PropTypes.func,
}

export default QuestionnaireCard;