import React from "react";

//indicative mood
import FuturePerfectTense from '../../../../assets/images/grammar/verb-conjugations/indicative-mood/future-perfect-tense.png';
import FutureTense from '../../../../assets/images/grammar/verb-conjugations/indicative-mood/future-tense.png';
import PerfectTense from '../../../../assets/images/grammar/verb-conjugations/indicative-mood/perfect-tense.png';
import PluperfectTense from '../../../../assets/images/grammar/verb-conjugations/indicative-mood/pluperfect-tense.png';
import PresentTense from '../../../../assets/images/grammar/verb-conjugations/indicative-mood/present-tense.png';
import PreteriteImperfectTense from '../../../../assets/images/grammar/verb-conjugations/indicative-mood/preterite-imperfect-tense.png';

//conditional
import PerfectTenseConditional from '../../../../assets/images/grammar/verb-conjugations/conditional/perfect-tense.png';
import PresentTenseConditional from '../../../../assets/images/grammar/verb-conjugations/conditional/present-tense.png';

//subjunctive mood
import FuturePerfectTenseSubjunctive from '../../../../assets/images/grammar/verb-conjugations/subjunctive-mood/future-perfect-tense-subjunctive.png';
import FutureTenseSubjunctive from '../../../../assets/images/grammar/verb-conjugations/subjunctive-mood/future-tense-subjunctive.png';
import PerfectTenseSubjunctive from '../../../../assets/images/grammar/verb-conjugations/subjunctive-mood/perfect-tense-subjunctive.png';
import PluperfectTenseSubjunctive from '../../../../assets/images/grammar/verb-conjugations/subjunctive-mood/pluperfect-tense-subjunctive.png';
import PresentTenseSubjunctive from '../../../../assets/images/grammar/verb-conjugations/subjunctive-mood/present-tense-subjunctive.png';
import PreteriteImperfectTenseSubjunctive from '../../../../assets/images/grammar/verb-conjugations/subjunctive-mood/preterite-imperfect-tense-subjunctive.png';

//other verb forms
import Participles from '../../../../assets/images/grammar/verb-conjugations/other-verb-forms/participles.png';
import Imperative from '../../../../assets/images/grammar/verb-conjugations/other-verb-forms/imperative.png';

const SHEETS = [
  {
    title: "Indicative Mood",
    sheets: [
      PresentTense,
      PerfectTense,
      PreteriteImperfectTense,
      PluperfectTense,
      FutureTense,
      FuturePerfectTense,
    ]
  },
  {
    title: "Conditional",
    sheets: [
      PresentTenseConditional,
      PerfectTenseConditional
    ]
  },
  {
    title: "Subjunctive Mood",
    sheets: [
      PresentTenseSubjunctive,
      PerfectTenseSubjunctive,
      PreteriteImperfectTenseSubjunctive,
      PluperfectTenseSubjunctive,
      FutureTenseSubjunctive,
      FuturePerfectTenseSubjunctive,
    ]
  },
  {
    title: "Other Verb Forms",
    sheets: [
      Participles,
      Imperative,
    ]
  }
]

const VerbConjugations = () => {

  return (
    <div className=" flex flex-col" >
      {SHEETS.map((item, index) => {
        const { title, sheets } = item || {};

        return (
          <div key={index}>
            <h2 className=" text-sm md:text-md lg:text-lg underline font-bold text-[#48484a]">
              {title}
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5 mb-10">
              {sheets.map((item, index) => (
                <img
                  key={index}
                  src={item}
                  alt={item}
                />
              ))}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default VerbConjugations;