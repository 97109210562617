import React, { useState } from "react";
import catIcon from "../../assets/sad-cat.svg";
import catIconMob from "../../assets/sad-cat-mob.svg";

import EmailCard from "../EmailCard";

import AuthCard from "../AuthCard";
import PropTypes from "prop-types";

const ExpiredLink = ({ description, onResend, title }) => {


  const [isLinkSent] = useState(false);



  return (
    <>
      {!isLinkSent && (
        <AuthCard
          title={title}
          borderImg={catIcon}
          borderImgMob={catIconMob}
          borderImgClassName="h-[140px]  lg:absolute  lg:bottom-0 lg:h-auto lg:translate-x-0 lg:left-auto lg:-top-20 lg:-right-14"
        >
          <EmailCard
            description={description}
            onSubmit={onResend}
            buttonTitle="Resend link"
          />
        </AuthCard>
      )}
    </>
  );
};

ExpiredLink.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  onResend: PropTypes.func,
};

export default ExpiredLink;
