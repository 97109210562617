import React, { useCallback } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import PropTypes from "prop-types";

import Button from "../../../../base/components/Button";

import {
  BUTTON_COLORS,
  BUTTON_STYLES,
} from "../../../../base/components/Button/appearance";

export const GoogleLoginButton = ({
  isDisabled,
  onPending,
  onSuccess,
  onFail,
  children,
}) => {
  const onSuccessLogin = useCallback(
    (res) => {
      onSuccess(res.access_token);
      onPending(true);
    },
    [onPending, onSuccess]
  );

  const onFailure = useCallback(
    (error) => {
      onFail(error);
      onPending(true);
    },
    [onPending, onFail]
  );

  const signIn = useGoogleLogin({
    onSuccess: onSuccessLogin,
    onError: onFailure,
  });

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      onPending(false);
      return signIn(event);
    },
    [onPending, signIn]
  );

  return (
    <Button
      color={BUTTON_COLORS.primary}
      block={BUTTON_STYLES.block}
      wavesEffect={BUTTON_STYLES.wavesEffect}
      wavesLight={BUTTON_STYLES.wavesLight}
      disabled={isDisabled}
      onClick={onClick}
      className="bg-white  hover:shadow-base-lg text-font text-md font-extralight shadow-base h-[60px] rounded-2xl flex justify-center items-center w-full"
    >
      {children}
    </Button>
  );
};

GoogleLoginButton.propTypes = {
  isDisabled: PropTypes.bool,
  children: PropTypes.any,
  onPending: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
};
