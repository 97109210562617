import * as yup from "yup";
import { USER_PASSWORD } from "../../../../validation/user";
import {
  VALIDATION_ERROR_MESSAGE_PASSWORD_MATCH,
  VALIDATION_ERROR_MESSAGE_PASSWORD_REQUIRED,
  VALIDATION_ERROR_MESSAGE_REPEAT_PASSWORD_REQUIRED,
} from "../../../../base/constants/messages";

export const validationSchema = yup.object().shape({
  password: USER_PASSWORD.required(VALIDATION_ERROR_MESSAGE_PASSWORD_REQUIRED),
  confirmPassword: yup
    .string()
    .required(VALIDATION_ERROR_MESSAGE_REPEAT_PASSWORD_REQUIRED)
    .test(
      "passwords-match",
      VALIDATION_ERROR_MESSAGE_PASSWORD_MATCH,
      function (value) {
        return this.parent.password === value;
      }
    ),
});

export const initialValues = {
  password: "",
  confirmPassword: "",
};
