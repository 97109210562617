import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import Button from "../../../base/components/Button";

import reportIcon from "../../../base/assets/flag.svg";
import successIcon from "../../../base/assets/success.svg";
import failureIcon from "../../../base/assets/failure.svg";

import { MODAL_STATUSES } from "../../../base/constants/modal";
import { ANSWER_STATUSES } from "../../../base/constants/answers";

import { ReportModal } from "./ReportModal";
import { useService } from "../../../base/hooks/useService";
import WordsService from "../../../services/WordsService";
import { useLoading } from "../../../base/hooks/useLoading";
import { SuccessModal } from "./SuccessModal";

export const FooterTranslation = ({
  onSubmit,
  onNext,
  onSolve,
  onReset,
  isSubmitDisabled,
  answerStatus,
}) => {
  /**
   * @type {WordsService}
   */
  const wordsService = useService(WordsService);
  const [isLoading, { registerPromise }] = useLoading();

  const handleSubmitReport = useCallback(
    (option) => {
      //todo send report to API
      setReportModalStatus(MODAL_STATUSES.SUBMITTED);
    },
    [registerPromise, wordsService]
  );

  const [reportModalStatus, setReportModalStatus] = useState(
    MODAL_STATUSES.CLOSED
  );

  const Report = () => (
    <div
      className="absolute left-0 lg:static flex items-center cursor-pointer"
      onClick={() => setReportModalStatus(MODAL_STATUSES.OPENED)}
    >
      <img src={reportIcon} />
      <span className="hidden lg:inline ml-[5px] text-light-grey-2 text-base font-light">
        Report
      </span>
    </div>
  );

  return (
    <>
      <hr className="hidden lg:block bg-dark-gray w-full border z-0 border-dark-gray absolute bottom-[116px]" />
      <div className="w-full mt-auto lg:mt-0  flex flex-col lg:flex-row lg:pt-10">
        {answerStatus === ANSWER_STATUSES.DEFAULT && (
          <Button
            type="submit"
            className="btn-purple lg:ml-auto text-base h-10 w-full lg:w-[190px]"
            onClick={onSubmit}
            disabled={isSubmitDisabled}
          >
            Submit
          </Button>
        )}
        {(answerStatus === ANSWER_STATUSES.SUCCESS ||
          answerStatus === ANSWER_STATUSES.SOLVED) && (
          <div className=" w-full px-[2px] relative lg:px-0 flex flex-col lg:flex-row justify-between items-center">
            <div className="w-full flex  mb-[30px] lg:mb-0  grow justify-center lg:justify-between lg:flex-auto">
              <Report />
              {answerStatus === ANSWER_STATUSES.SUCCESS && (
                <div className="flex items-center">
                  <img src={successIcon} className="h-[26px] lg:h-[35px]" />
                  <p className="text-base lg:text-[21px] ml-1 lg:ml-2 lg:mr-5">
                    That&apos;s correct!
                  </p>
                </div>
              )}
            </div>

            <div className="w-full lg:w-auto flex flex-col jus lg:flex-row">
              <Button
                className="btn-purple text-base h-10 w-full lg:w-[190px] "
                onClick={onNext}
              >
                Next
              </Button>
            </div>
          </div>
        )}
        {answerStatus === ANSWER_STATUSES.FAILURE && (
          <div className=" w-full px-[2px] relative lg:px-0 flex flex-col lg:flex-row justify-between items-center">
            <div className="w-full flex  mb-[10px] lg:mb-0  grow justify-center lg:justify-between lg:flex-auto">
              <Report />

              <div className="flex items-center">
                <img src={failureIcon} className="h-[26px] lg:h-[35px]" />
                <p className="text-base lg:text-[21px] ml-1 lg:ml-2 lg:mr-5">
                  Try again
                </p>
              </div>
            </div>

            <div className="w-full lg:w-auto flex flex-col jus lg:flex-row">
              <Button
                className="btn-back text-base h-10 w-full order-2 lg:order-1 lg:w-[190px]  lg:mr-5"
                onClick={onSolve}
              >
                Solve
              </Button>
              <Button
                className="btn-purple text-base h-10 order-1 lg:order-2 w-full mb-[10px] lg:mb-0 lg:w-[190px] "
                onClick={onReset}
              >
                Reset
              </Button>
            </div>
          </div>
        )}
      </div>
      <ReportModal
        isOpen={reportModalStatus === MODAL_STATUSES.OPENED}
        onClose={() => setReportModalStatus(MODAL_STATUSES.CLOSED)}
        onSubmit={handleSubmitReport}
      />
      <SuccessModal
        isOpen={reportModalStatus === MODAL_STATUSES.SUBMITTED}
        onClose={() => setReportModalStatus(MODAL_STATUSES.CLOSED)}
      />
    </>
  );
};

FooterTranslation.propTypes = {
  onSubmit: PropTypes.func,
  onNext: PropTypes.func,
  onSolve: PropTypes.func,
  onReset: PropTypes.func,
  isSubmitDisabled: PropTypes.bool,
  answerStatus: PropTypes.string,
};
