import React from "react";

import Button from "../../../Button";
import { useNavigate, useParams } from "react-router-dom";
import { APP_GROUP_LINKS } from "../../../../../groups/app/config";
import { LEARN_GROUP_LINKS } from "../../../../../groups/learn/config";
import { getLessonsLinkByQuizId } from "../../../../../groups/learn/pages/CustomLearningMenu/helpers/getLessonsLinkByQuizId";
import { QUIZ_ID_1 } from "../../../../constants/customLessons";

const InitialQuizDescription = () => (
  <>
    <p> You are now able to proceed to your lessons.</p>
    <p className="pt-2">
      However, if you continue filling out the questionnaire we will be able to
      supply you with more customized content.
    </p>
    <p className="pt-2">
      You will be able to save your progress at any point and complete the rest
      of the questionnaire from your user settings at a later date.{" "}
    </p>
    <p className="pt-2">
      We do advise that you proceed with it now in order to utilize our product
      to its full extent.
    </p>
  </>
);

const CustomQuizDescription = () => (
  <p className="pt-2">
    We have created customized lessons relevant to your education and
    professional interests.
  </p>
);

const LifeTravelQuizDescription = () => (
  <p className="pt-2">
    More detailed questionnaires on profession, hobbies, and other topics are
    available in the Custom Learning section
  </p>
);

const INITIAL_QUIZ_TITLE = "You have completed the initial questionnaire!";
const CUSTOM_QUIZ_TITLE = "Thanks for completing this custom questionnaire!";
const LIFE_TRAVEL_QUIZ_TITLE =
  "You have completed the Life, Travel & Your location questionnaire!";

export default function CompletedCard({
  catIcon,
  isInitialQuiz = true,
  keepGoingRedirect = LEARN_GROUP_LINKS.LINK_CUSTOM_LEARNING,
}) {
  const navigate = useNavigate();
  const { id } = useParams();

  const isCustomQuiz = !isInitialQuiz && id !== QUIZ_ID_1;
  const isLifeTravelQuiz = !isInitialQuiz && id === QUIZ_ID_1;

  return (
    <div className="relative h-full flex-auto container flex flex-col items-center  py-5 lg:pb-0 lg:pt-[90px]">
      <h4 className="text-base text-center lg:text-xl">
        {isInitialQuiz && INITIAL_QUIZ_TITLE}
        {isCustomQuiz && CUSTOM_QUIZ_TITLE}
        {isLifeTravelQuiz && LIFE_TRAVEL_QUIZ_TITLE}
      </h4>
      <div className="text-font  pt-5 text-xs lg:text-base lg:pt-[50px] text-center">
        {isInitialQuiz && <InitialQuizDescription />}
        {isCustomQuiz && <CustomQuizDescription />}
        {isLifeTravelQuiz && <LifeTravelQuizDescription />}
      </div>
      <div className="relative w-full lg:flex-auto mt-auto gap-y-[10px] lg:gap-y-0 lg:mb-[-2.9rem] grid gap-x-[20px] lg:pt-20 lg:w-auto lg:grid-flow-col lg:grid-cols-[max-content] ">
        {isInitialQuiz ? (
          <>
            <Button
              type="button"
              className="btn-purple w-full lg:w-[190px] h-[40px] text-base z-10 relative mt-2 lg:mt-0"
              onClick={() => navigate(keepGoingRedirect)}
            >
              Let&apos;s keep going!
            </Button>
            <Button
              type="button"
              className="btn-back w-full lg:w-[190px] h-[40px]  text-base "
              onClick={() => navigate(APP_GROUP_LINKS.BASE)}
            >
              I&apos;ll do it later.
            </Button>
          </>
        ) : (
          <div className="w-full lg:mx-10 z-10 relative">
            <Button
              type="button"
              className="btn-purple w-full lg:w-[190px] h-[40px] text-base "
              onClick={() => navigate(getLessonsLinkByQuizId(id))}
            >
              Go to Lessons
            </Button>
          </div>
        )}
        <img
          alt={'cat'}
          src={catIcon}
          className="absolute bottom-[80%] max-h-[160px] lg:max-h-full lg:bottom-0  left-1/2 -translate-x-1/2"
        />
      </div>
    </div>
  );
}
