import React from 'react';
import PropTypes from 'prop-types'
import classNames from 'classnames';
import { useField } from 'formik';
import { FormGroup } from 'reactstrap';

const FormikTextarea = ({ label, ...props }) => {
  const [field, { error, touched }] = useField(props);

  return (
    <FormGroup>
      {label && (
        <label 
          htmlFor={props.name}
          className={classNames({'text-danger': (error && touched)})}          
        >
          {label}
        </label>
      )}        
      <textarea className="form-control mb-3" {...field} {...props} />
    </FormGroup>
  )
};

FormikTextarea.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.string,
  label: PropTypes.string
}

export default FormikTextarea;