export const ANSWERS = {
  YES: 1,
  NO: 2,
};

const SUCCESS = "success";
const FAILURE = "failure";
const SOLVED = "solved";
const DEFAULT = "default";

export const ANSWER_STATUSES = {
  SUCCESS,
  FAILURE,
  SOLVED,
  DEFAULT,
};
