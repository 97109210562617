const LINK_TO_LEARN_ROOT = `/app/learn`;
const LINK_CUSTOM_LEARNING = `${LINK_TO_LEARN_ROOT}/custom-learning`;
const LINK_ESSENTIAL_LEARNING = `${LINK_TO_LEARN_ROOT}/essential-learning`;

export const LEARN_GROUP_LINKS = {
  BASE: LINK_TO_LEARN_ROOT,
  LINK_CUSTOM_LEARNING,
  LINK_ESSENTIAL_LEARNING,
};

export const LEARN_GROUP_ROUTES = {
  BASE: "/learn/*",
  LINK_CUSTOM_LEARNING: "/custom-learning",
  LINK_ESSENTIAL_LEARNING: "/essential-learning",
  LINK_CUSTOM_THEME: "/custom-learning/:link",
};
