import {Route, Routes} from "react-router-dom";
import {PROFILE_QUESTIONNAIRE_ROUTES} from "./config";
import React from "react";
import Questionnaire from "./pages/Questionnaire";

export default function ProfileQuestionnaireRoutes() {
  return (
    <Routes>
      <Route path={PROFILE_QUESTIONNAIRE_ROUTES.BASE} element={<Questionnaire />} />
    </Routes>
  );
}