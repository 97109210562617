import React from "react";
import { useField } from "formik";
import CustomInput from "./Input";
import PropTypes from "prop-types";

const FormikInput = ({
  placeholder,
  disabled,
  name,
  type,
  maxLength,
  readOnly,
  label,
  containerClassName,
  autoFocus,
  min,
  max,
}) => {
  const [field, meta] = useField({ name, type, placeholder });
  const isError = meta.error && meta.touched;
  return (
    <section className={containerClassName}>
      <label className="label">{label}</label>
      <CustomInput
        {...field}
        type={type}
        className={`input transition transition-colors delay-500 ${isError && "input-error"}`}
        maxLength={maxLength}
        readOnly={readOnly}
        placeholder={placeholder}
        disabled={disabled}
        autoFocus={autoFocus}
      />
      <span className={`error transition-opacity transition opacity-0 duration-500 ${meta.error ? "opacity-100" : ""}`}>{meta.error}</span>
    </section>
  );
};

FormikInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default FormikInput;
