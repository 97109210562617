const LINK_TO_PRACTICE_ROOT = `/app/practice`;
const LINK_TO_FLASHCARDS = `${LINK_TO_PRACTICE_ROOT}/flashcards`;
const LINK_TO_WORDS_TRANSLATION = `${LINK_TO_PRACTICE_ROOT}/translation`;
const LINK_TO_SENTENCES_TRANSLATION = `${LINK_TO_PRACTICE_ROOT}/sentences`;

export const PRACTICE_GROUP_LINKS = {
  BASE: LINK_TO_PRACTICE_ROOT,
  LINK_TO_FLASHCARDS,
  LINK_TO_WORDS_TRANSLATION,
  LINK_TO_SENTENCES_TRANSLATION,
};

export const PRACTICE_GROUP_ROUTES = {
  BASE: "/practice/*",
  LINK_TO_FLASHCARDS: "/flashcards",
  LINK_TO_WORDS_TRANSLATION: "/translation",
  LINK_TO_SENTENCES_TRANSLATION: "/sentences",
};
